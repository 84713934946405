import React from 'react';
import { List, Button, Radio, Card, message } from 'antd';
import DynamicAntdTheme, { changeAntdTheme } from 'dynamic-antd-theme';

import Image from '../../../../components/Image';
import config from '../../../../config';

export default class LayoutSettings extends React.Component {
  /* ========================================================================== */
  /*                                  List Data                                 */
  /* ========================================================================== */
  componentWillUnmount() {
    const layoutColor = window.localStorage.getItem('layoutColor');
    if (layoutColor && layoutColor != this.props.profile.layout.color) {
      this.props.updateProfile({
        layout: {
          color: layoutColor,
        },
      });
    }
  }

  restoreDefaultColor = () => {
    const defaultColor = '#0c377d';
    changeAntdTheme(defaultColor);
    window.localStorage.setItem('layoutColor', defaultColor);
  };
  getData = () => [
    {
      title: 'Theme color',
      description: (
        <>
          {'Aktuelle Farbe'}：
          {
            <div style={{ margin: 10, display: 'inline-block' }}>
              <DynamicAntdTheme
                storageName="layoutColor"
                //themeChangeCallback={(color) => this.props.updateProfile({ layoutColor: color })}
              />
            </div>
          }
        </>
      ),
      actions: [
        <a key="Modify">
          <div style={{ marinButtom: 20, margin: 'auto' }}>
            <Button onClick={() => this.restoreDefaultColor()} type="link">
              Wiederherstellen
            </Button>
          </div>
        </a>,
      ],
    },
    {
      title: 'Theme mode',
      description: (
        <>
          {'Aktuelle Mode'}：
          {
            <Card className="projectList" style={{ marginBottom: 24 }} bordered={false} bodyStyle={{ padding: 0 }}>
              <Radio.Group onChange={this.onThemeModeChanged} value={this.props.profile.layout.mode === 'light' ? 1 : 2}>
                <Card.Grid className="projectGrid" key="id" style={{ padding: 0 }}>
                  <Card title="Light Mode" extra={<Radio value={1} />}>
                    {<Image src={`${config.azure.storageContainer}/images/layout_light.png`} preview={false} style={{ display: 'block' }} />}
                  </Card>
                </Card.Grid>
                <Card.Grid className="projectGrid" key="id" style={{ padding: 0 }}>
                  <Card title="Dark Mode" extra={<Radio value={2} />}>
                    {<Image src={`${config.azure.storageContainer}/images/layout_dark.png`} preview={false} />}
                  </Card>
                </Card.Grid>
              </Radio.Group>
            </Card>
          }
        </>
      ),
      actions: [
        <a key="Modify">
          <div style={{ marinButtom: 20, margin: 'auto' }}>
            <Button onClick={() => console.log('test')} type="link">
              Wiederherstellen
            </Button>
          </div>
        </a>,
      ],
    },
  ];

  onThemeModeChanged = (e) => {
    if (e.target.value === 1) {
      message.loading({ content: 'Theme wird geladen...' });
      this.props.updateProfile({
        layout: {
          mode: 'light',
        },
      });
      window.localStorage.setItem('layoutMode', 'light');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      message.loading({ content: 'Theme wird geladen...' });
      this.props.updateProfile({
        layout: {
          mode: 'dark',
        },
      });
      window.localStorage.setItem('layoutMode', 'dark');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  render() {
    const data = this.getData();
    return (
      <div className="layout-settings" style={{ minHeight: '400px' }}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item actions={item.actions}>
              <List.Item.Meta title={item.title} description={item.description} />
            </List.Item>
          )}
        />
      </div>
    );
  }
}
