import React from 'react';
import moment from 'moment';
import { Avatar, Dropdown, Menu, Button } from 'antd';
import { ScanOutlined, UserOutlined } from '@ant-design/icons';

import './index.less';

export default class ArticleListContent extends React.Component {
  render() {
    const { data } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key="1" icon={<UserOutlined />}>
          1st menu item
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          2nd menu item
        </Menu.Item>
        <Menu.Item key="3" icon={<UserOutlined />}>
          3rd menu item
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="listContent">
        <div className="description">{data.desc}</div>
        <div className="extra">
          <Avatar src={`data:image/png;base64,${data.avatar}`} size="small" />
          <a href={data.href}>{data.owner}</a> Erstellt am:
          <em>{moment(data.createdAt).format('YYYY-MM-DD HH:mm')}</em>
        </div>
        {/* <div className="dropdown">
          <Dropdown.Button overlay={menu} icon={<ScanOutlined />}>
            Auswahl
          </Dropdown.Button>
        </div> */}
      </div>
    );
  }
}
