import React from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Result, Skeleton, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import ViewerConfigurator from '../../../viewer.components/Forge/Viewer.Configurator';

import './PublicForgeViewer.less';
const { Paragraph, Text } = Typography;

export default class PublicForgeViewer extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultExtensions: ['Autodesk.Viewing.Collaboration'],
      error: undefined,
    };
  }

  onError = (error) => {
    this.setState({
      error,
    });
    if (this.loader) {
      this.loader.show(false);
    }
  };
  onViewerCreated = (viewer, loader) => {
    this.viewer = viewer;
    this.loader = loader;

    viewer.addEventListener(window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, this.onModelRootLoaded);
  };
  onModelRootLoaded = (e) => {
    this.viewer.removeEventListener(window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, this.onModelRootLoaded);

    this.loader.show(false);
  };

  render() {
    const { error, defaultExtensions } = this.state;
    this.modelId = this.props.match.params.modelId;
    return (
      <div className="public-viewer-view">
        <PageContainer
          onBack={() => window.history.back()}
          title="BIMforge Viewer"
          subTitle={this.props.model ? `> ${this.props.model.name}` : <Skeleton style={{ height: 48 }} />}
        >
          {error ? (
            <Result status="500" title="Fehler" subTitle="Es ist ein Fehler aufgetreten, versuchen Sie es erneut...">
              <div className="desc">
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Fehlermeldung:
                  </Text>
                </Paragraph>
                <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" /> {error}.
                </Paragraph>
              </div>
            </Result>
          ) : (
            <ViewerConfigurator
              onViewerCreated={this.onViewerCreated}
              onError={this.onError}
              modelId={this.modelId}
              location={this.props.location}
              showLoader={false}
              defaultExtensions={defaultExtensions}
            />
          )}
        </PageContainer>
      </div>
    );
  }
}
