import { createSelector } from 'reselect';

export const getOrganisationsState = (state) => state.organisations;

export const getProcessing = createSelector(getOrganisationsState, (organisations) => organisations.processing);
export const getProcessed = createSelector(getOrganisationsState, (organisations) => organisations.processed);
export const getError = createSelector(getOrganisationsState, (organisations) => organisations.error);
export const getCurrentOrganisations = createSelector(getOrganisationsState, (organisations) => organisations.organisations);
export const getCurrentOrganisation = createSelector(getOrganisationsState, (organisations) => organisations.organisation);
export const getCurrentOrganisationUsers = createSelector(getOrganisationsState, (organisations) => organisations.users);
export const getCurrentOrganisationProjects = createSelector(getOrganisationsState, (organisations) => organisations.projectsCount);
export const getCurrentOrganisationModels = createSelector(getOrganisationsState, (organisations) => organisations.modelsCount);
export const getCurrentOrganisationScans = createSelector(getOrganisationsState, (organisations) => organisations.scansCount);
