import React from 'react';

import { Form, Alert, Descriptions, Divider, Progress, Result } from 'antd';

import './index.less';

export default class Step3 extends React.Component {
  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */
  get formLayout() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 19,
      },
    };
  }
  render() {
    return (
      <Form {...this.formLayout} layout="horizontal" className="stepForm">
        <Result
          status="success"
          title="Upload erfolgreich"
          subTitle="Das Modell wird nun verabeitet, was einige Zeit in Anspruch nehmen kann."
          className="result"
        >
          <Alert
            closable={false}
            showIcon
            message="Sie können diese Seite verlassen, sie werden per Email benachrichtigt sobald Ihr Modell verarbeitet wurde."
            style={{ marginBottom: 24 }}
          />
          <Divider style={{ margin: '24px 0' }} />
          <Descriptions column={1}>
            <Descriptions.Item label="Job Id">
              {this.props.translationProgress ? `${this.props.translationProgress.jobId}` : 'nicht festgelegt'}
            </Descriptions.Item>
            <Descriptions.Item label="Model Id">
              {this.props.translationProgress ? `${this.props.translationProgress.modelId}` : 'nicht festgelegt'}
            </Descriptions.Item>
            <Descriptions.Item label="Fortschritt">
              <Progress status="active" percent={this.props.translationProgress ? this.props.translationProgress.progress : 0} />
            </Descriptions.Item>
          </Descriptions>
        </Result>
      </Form>
    );
  }
}
