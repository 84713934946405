import * as actionTypes from './types';
import { fetchNotices } from './notices.actions';

export const getProjects = (query = '') => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_PROJECTS });
  return bimServerApi.get(`/api/projects/${query}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_PROJECTS_SUCCESS, payload: response.data.projects });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_PROJECTS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const getProject = (projectId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_PROJECT });
  return bimServerApi.get(`/api/projects/${projectId}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_PROJECT_SUCCESS, payload: response.data.project });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_PROJECT_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const registerProject = (formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.REGISTER_PROJECT });
  return bimServerApi.post(`/api/projects/`, formValues).then(
    (response) => {
      dispatch(fetchNotices());
      dispatch({ type: actionTypes.REGISTER_PROJECT_SUCCESS, payload: response.data.project });
    },
    (err) => {
      dispatch({
        type: actionTypes.REGISTER_PROJECT_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateProject = (projectId, formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_PROJECT });
  return bimServerApi.put(`/api/projects/${projectId}`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_PROJECT_SUCCESS, payload: response.data.project });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_PROJECT_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const deleteProject = (projectId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.DELETE_PROJECT });
  return bimServerApi.delete(`/api/projects/${projectId}`).then(
    (response) => {
      dispatch(fetchNotices());
      dispatch({ type: actionTypes.DELETE_PROJECT_SUCCESS });
    },
    (err) => {
      dispatch({
        type: actionTypes.DELETE_PROJECT_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateCover = (projectId, file) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_COVER });
  let formData = new FormData();
  formData.append('cover', file);
  return bimServerApi.post(`/api/projects/${projectId}`, formData, {}).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_COVER_SUCCESS, payload: response.data.project });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_COVER_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const setActiveProject = (project) => {
  return {
    type: actionTypes.SET_ACTIVE_PROJECT,
    payload: project,
  };
};

export const setActiveTab = (tabIndex) => {
  return {
    type: actionTypes.SET_ACTIVE_TAB,
    payload: tabIndex,
  };
};

export const unloadProjectPage = () => {
  return {
    type: actionTypes.UNLOAD_PROJECT_PAGE,
  };
};
