import React from 'react';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { unloadAuthPage, requestPasswordReset } from '../../store/actions/auth.actions';
import { getError, getRedirectToPath } from '../../store/selectors/auth.selectors';

import './RequestPasswordReset.less';

class RequestPasswordReset extends React.Component {
  componentWillUnmount() {
    this.props.unloadAuthPage();
  }

  onFinish = (formValues) => {
    this.props.requestPasswordReset({ ...formValues, tokenPurpose: 'reset-password' });
  };

  render() {
    if (this.props.redirectToPath != null) {
      this.props.history.push(this.props.redirectToPath, { message: 'Eine Bestätigungs-E-Mail wurde an Ihre E-Mail gesendet.' });
    }
    return (
      <div className="request-password-reset">
        <Form onFinish={this.onFinish} name="passwordReset" className="request-password-form">
          <h3 style={{ color: 'white' }}>Passwort zurücksetzten für:</h3>

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Dies ist keine gülte Email-Adresse!',
              },
              {
                required: true,
                message: 'Bitte geben Sie Ihre Email ein!',
              },
            ]}
          >
            <Input size="large" prefix={<LockOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="request-password-form-button">
              Passwort zurücksetzten
            </Button>
            <Link className="request-password-form-login" to="/user/signin">
              Anmelden!
            </Link>
            <Link className="request-password-form-register" to="/user/signup">
              Keinen Konto? Jetzt registrieren!
            </Link>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
    redirectToPath: getRedirectToPath(state),
  };
};

export default connect(mapStateToProps, { unloadAuthPage, requestPasswordReset })(RequestPasswordReset);
