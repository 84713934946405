import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  user: localStorage.getItem('authInfo') ? JSON.parse(localStorage.getItem('authInfo')).user : {},
  isSignedIn: !!localStorage.getItem('authInfo') || false,
  expiresAt: null,
  processing: false,
  processed: false,
  error: null,
  message: null,
  attemptedPath: null, // Used to redirect users to the page they visited before logging in
  redirectToPath: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SIGN_IN:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.TRY_LOCAL_SIGN_IN:
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.SIGN_UP:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.VERIFY_EMAIL:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        processing: false,
        redirectToPath: '/user/signin',
      };
    case actionTypes.REQUEST_VERIFICATION_EMAIL:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.RESET_PASSWORD:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.SIGN_IN_SUCCESS:
    case actionTypes.TRY_LOCAL_SIGN_IN_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        isSignedIn: true,
        expiresAt: action.payload.expiresAt,
        signedInWith: action.payload.signedInWith,
        user: { ...action.payload.user },
      };

    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        processing: false,
        redirectToPath: '/user/signin',
      };
    case actionTypes.REQUEST_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        processing: false,
        redirectToPath: '/user/signin',
      };
    case actionTypes.REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        redirectToPath: '/user/signin',
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        redirectToPath: '/user/signin',
      };
    case actionTypes.SIGN_IN_FAIL:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case actionTypes.SIGN_UP_FAIL:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case actionTypes.VERIFY_EMAIL_FAIL:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case actionTypes.REQUEST_VERIFICATION_EMAIL_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
      };
    case actionTypes.REQUEST_PASSWORD_RESET_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
      };
    case actionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
      };
    case actionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        isSignedIn: false,
        user: {},
        expiresAt: null,
      };
    case actionTypes.TRY_LOCAL_SIGN_IN_FAIL:
      return {
        ...state,
        isSignedIn: false,
        user: {},
        expiresAt: null,
        processing: false,
        processed: true,
      };
    case actionTypes.SET_ATTEMPTED_PATH:
      return {
        ...state,
        attemptedPath: action.payload,
      };
    case actionTypes.UNLOAD_AUTH_PAGE:
      return {
        ...state,
        // processing: false,
        error: null,
        redirectToPath: null,
      };
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
