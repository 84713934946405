import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  models: null,
  model: null,
  processing: false,
  processed: false,
  error: null,
};

const modelsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_MODELS:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_MODELS_SUCCESS:
      return {
        ...state,
        processing: false,
        models: action.payload,
      };
    case actionTypes.GET_MODELS_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_MODEL:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_MODEL_SUCCESS:
      return {
        ...state,
        processing: false,
        model: action.payload,
      };
    case actionTypes.GET_MODEL_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_MODEL:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        processing: false,
        model: action.payload,
      };
    case actionTypes.UPDATE_MODEL_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.REGISTER_MODEL:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.REGISTER_MODEL_SUCCESS:
      return {
        ...state,
        processing: false,
        models: [...state.models, { ...action.payload }],
      };
    case actionTypes.REGISTER_MODEL_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.DELETE_MODEL:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.DELETE_MODEL_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case actionTypes.DELETE_MODEL_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.SET_ACTIVE_MODEL:
      return {
        ...state,
        model: action.payload,
      };
    case actionTypes.UNLOAD_MODEL_PAGE:
      return {
        ...state,
        error: null,
        processing: false,
        processed: false,
      };
    case actionTypes.UNLOAD_VIEWER_PAGE:
      return {
        ...state,
        model: null,
        error: null,
        processing: false,
        processed: false,
      };
    default:
      return state;
  }
};

export default modelsReducer;
