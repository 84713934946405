import React from 'react';
import { Modal, Form, Input } from 'antd';

export default class AddUserModal extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      match: false,
      user: null,
      isProcessing: false,
      error: null,
    };
  }

  resetState() {
    this.setState({
      match: false,
      user: null,
      isProcessing: false,
      error: null,
    });
  }
  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    if (this.props.onSubmit) {
      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
      this.resetState();
    }
  };
  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */
  getModalContent() {
    return (
      <Form {...this.formLayout} ref={this.formRef} style={{ padding: 10 }} onFinish={this.handleFinish}>
        <Form.Item label="Email">
          <Form.Item
            name="email"
            noStyle
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie eine gültige Emailadresse ein.',
                type: 'email',
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Email" />
          </Form.Item>
          {/* <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="email"
                noStyle
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie eine gültige Emailadresse ein.',
                    type: 'email',
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    type="primary"
                    onClick={() => this.validateEmail(this.formRef.current.getFieldValue('email'))}
                    disabled={this.formRef.current ? !this.formRef.current.isFieldsTouched(true) || !!this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length : true}
                  >
                    Suchen
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row> */}
        </Form.Item>
        {/* {isProcessing && (
          <Spin tip="Loading...">
            <Alert message="Suche nach Nutzer." description="test" type="info" />
          </Spin>
        )}
        {error && <Alert message="Fehler bei Ihrer Anfrage." description={error} type="error" />} */}
        {/* {user && (
          <>
            <Form.Item label="Nutzer">
              <li className="user">
                <Avatar icon={<UserOutlined />} />
                {user.firstName} {user.lastName}
              </li>
            </Form.Item>
            <Form.Item label="Rechte" valuePropName="checked">
              <Checkbox checked={true} disabled={true}>
                Lesen
              </Checkbox>
              <Checkbox checked={true} disabled={true}>
                Bearbeiten
              </Checkbox>
            </Form.Item>
          </>
        )} */}
      </Form>
    );
  }

  render() {
    const { visible } = this.props;

    const modalFooter = {
      okText: 'Hinzufügen',
      onOk: this.handleSubmit,
      onCancel: this.handleCancel,
    };
    return (
      <Modal
        title="Nutzer hinzufügen"
        className="standardListForm"
        width={640}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={visible}
        {...modalFooter}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}
