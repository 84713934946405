import React from 'react';
import { Menu } from 'antd';
import { PageContainer, GridContent } from '@ant-design/pro-layout';

import BasicSettings from './Tabs/BasicSettings';
import SecurityView from './Tabs/SecuritySettings';
import Settings from './Tabs/Settings';
import NotificationView from './Tabs/AccountNotification';
import LayoutSettingsView from './Tabs/LayoutSettings';

import './SettingsView.less';

const { Item } = Menu;

export default class SettingsView extends React.Component {
  main = undefined;

  constructor(settingsProps) {
    super(settingsProps);

    const menuMap = {
      base: 'Allgemein',
      layout: 'Layout',
      security: 'Sicherheit',
      notification: 'Notifications',
    };
    this.state = {
      mode: 'inline',
      menuMap,
      selectKey: 'base',
    };
  }

  /* ========================================================================== */
  /*                              Lifecycle Methods                             */
  /* ========================================================================== */

  componentDidMount() {
    this.props.getProfile();
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  /* ========================================================================== */
  /*                                   Resize                                   */
  /* ========================================================================== */
  resize = () => {
    if (!this.main) {
      return;
    }
    requestAnimationFrame(() => {
      if (!this.main) {
        return;
      }
      let mode = 'inline';
      const { offsetWidth } = this.main;
      if (this.main.offsetWidth < 641 && offsetWidth > 400) {
        mode = 'horizontal';
      }
      if (window.innerWidth < 768 && offsetWidth > 400) {
        mode = 'horizontal';
      }
      this.setState({
        mode,
      });
    });
  };

  /* ========================================================================== */
  /*                                 Select Key                                 */
  /* ========================================================================== */
  selectKey = (key) => {
    this.setState({
      selectKey: key,
    });
  };

  /* ========================================================================== */
  /*                                  Get Menu                                  */
  /* ========================================================================== */
  getMenu = () => {
    const { menuMap } = this.state;
    return Object.keys(menuMap).map((item) => <Item key={item}>{menuMap[item]}</Item>);
  };

  /* ========================================================================== */
  /*                                  Get Title                                 */
  /* ========================================================================== */
  getRightTitle = () => {
    const { selectKey, menuMap } = this.state;
    return menuMap[selectKey];
  };

  /* ========================================================================== */
  /*                               Render Children                              */
  /* ========================================================================== */

  renderChildren = () => {
    const { selectKey } = this.state;
    switch (selectKey) {
      case 'base':
        return <BasicSettings {...this.props} />;
      case 'security':
        return <SecurityView {...this.props} />;
      case 'notification':
        return <NotificationView />;
      case 'layout':
        return <LayoutSettingsView {...this.props} />;
      default:
        break;
    }

    return null;
  };
  getHeader = () => {
    const { selectKey, menuMap } = this.state;
    const value = menuMap[selectKey];
    return {
      breadcrumbRender: (props, originBreadcrumb) => {
        if ((originBreadcrumb.props.routes.length = 4)) {
          originBreadcrumb.props.routes.pop();
        }
        originBreadcrumb.props.routes.push({ breadcrumbName: value });
        return originBreadcrumb;
      },
    };
  };

  render() {
    const { mode, selectKey } = this.state;

    return (
      <PageContainer header={this.getHeader()}>
        <GridContent>
          <div
            className="main"
            ref={(ref) => {
              if (ref) {
                this.main = ref;
              }
            }}
          >
            <div className="leftMenu">
              <Menu mode={mode} selectedKeys={[selectKey]} onClick={({ key }) => this.selectKey(key)}>
                {this.getMenu()}
              </Menu>
            </div>
            <div className="account-right">
              <div className="title">{this.getRightTitle()}</div>
              {this.renderChildren()}
            </div>
          </div>
        </GridContent>
      </PageContainer>
    );
  }
}
