import BaseSvc from './BaseSvc';

export default class EventSvc extends BaseSvc {
  /// //////////////////////////////////////////////////////////////
  //
  //
  /// //////////////////////////////////////////////////////////////
  constructor(config) {
    super(config);
  }

  /// //////////////////////////////////////////////////////////////
  //
  //
  /// //////////////////////////////////////////////////////////////
  name() {
    return 'EventSvc';
  }
}
