import React from 'react';
import _ from 'lodash';

import { Modal, Form, Input, Checkbox, Select } from 'antd';
import { bimServerApi, errorHandler } from '../../../store/apis/bim.server.api';
import Paragraph from 'antd/lib/skeleton/Paragraph';

const { Option } = Select;

export default class NoticeRootModal extends React.Component {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      data: [],
      value: undefined,
    };
  }

  handleSearch = (value) => {
    if (value) {
      this.fetchUser(value, (data) => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };
  handleSearchChange = (value) => {
    this.setState({ value });
  };
  fetchUser = (value, callback) => {
    bimServerApi.get(`/api/users/?firstName=${value}`).then(
      (response) => {
        callback(response.data.users);
      },
      (err) => {
        errorHandler(err);
      },
    );
  };

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }

  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (fieldsValue) => {
    if (this.props.onSubmit) {
      delete fieldsValue['objectId'];
      Object.keys(fieldsValue).map((key) => {
        if (fieldsValue[key] === undefined) {
          delete fieldsValue[key];
        }
      });
      this.props.onSubmit(fieldsValue);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */

  getModalContent() {
    const { current, deletion } = this.props;
    const { data } = this.state;
    let options;
    if (!_.isEmpty(data)) {
      options = data.map((d) => (
        <Option key={d.id}>
          {d.firstName}
          {d.lastName}
        </Option>
      ));
    } else {
      options = [];
    }

    let disabled;
    if (deletion) {
      disabled = true;
    }

    var initialValues = {};
    if (current) {
      initialValues = {
        ['title']: current.title,
        ['desc']: current.desc,
        ['read']: current.read,
        ['extra']: current.extra,
        ['type']: current.type,
        ['user']: current.user ? `${current.user.firstName} ${current.user.lastName}` : undefined,
        ['objectId']: current.id,
      };
    }
    return (
      <Form {...this.formLayout} onFinish={this.handleFinish} ref={this.formRef} initialValues={initialValues} style={{ padding: 10 }}>
        <Form.Item name="title" label="Title">
          <Input placeholder="Title" disabled={disabled} />
        </Form.Item>
        <Form.Item name="desc" label="Bescheibung">
          <Input.TextArea rows={2} placeholder="Projektbeschreibung" maxLength={300} disabled={disabled} />
        </Form.Item>
        <Form.Item name="read" label="Read" valuePropName="checked">
          <Checkbox defaultChecked={initialValues['read']}>Gelesen</Checkbox>
        </Form.Item>
        <Form.Item label="Etra" name="extra">
          <Select defaultValue={initialValues['extra']} disabled={disabled}>
            <Option value="System">System</Option>
            <Option value="User">User</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Type" name="type">
          <Select disabled={disabled}>
            <Option value="message">Message</Option>
            <Option value="event">Event</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="user"
          label="User ID"
          hasFeedback
          rules={[
            {
              required: false,
              message: 'Bitte geben Sie eine UserID an!',
            },
          ]}
        >
          <Select
            showSearch
            value={this.state.value}
            placeholder="Project"
            //style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            notFoundContent="keine passender Eintrag gefunden."
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item name="objectId" label="Object ID">
          <Input placeholder="Object Id " disabled={true} />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { deletion, visible, current, onDelete } = this.props;
    var modalFooter;
    if (!deletion) {
      modalFooter = current
        ? {
            okText: 'Update',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          }
        : {
            okText: 'Speichern',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          };
    } else {
      modalFooter = {
        okText: 'Löschen',
        okButtonProps: { danger: true },
        onOk: onDelete,
        onCancel: this.handleCancel,
      };
    }

    return (
      <Modal
        title={`Notice ${current ? (deletion ? 'löschen' : 'bearbeiten') : 'hinzufügen'}`}
        className="standardListForm"
        width={640}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={visible}
        {...modalFooter}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}
