import React from 'react';
import { Button } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';

import config from '../../config';

export default class MongoView extends React.Component {
  render() {
    return (
      <PageContainer title="Mongo DB Admin Panel" content={`Öffnet eine Web-bsiertes MongoDB Admin Interface`}>
        <Button onClick={() => window.open(`${config.server.publicUrl}/api/mongo/admin`, '_blank')}>Öffnen</Button>
      </PageContainer>
    );
  }
}
