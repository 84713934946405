import React from 'react';

import { DefaultFooter, PageLoading } from '@ant-design/pro-layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, Route, Redirect } from 'react-router-dom';

import logo from '../../logo.svg';

import config from '../../config';
import './AuthenticationLayout.less';

class AuthenticationLayout extends React.Component {
  componentDidMount() {
    // const cacheLayoutMode = window.localStorage.getItem('layoutMode');
    // // In settings only profile is updated and not the logged in user object (because of auth token.. ) // TODO: FIX
    // if (cacheLayoutMode === 'light') {
    //   return;
    // }
    // if (cacheLayoutMode === 'dark') {
    //   return import('../../themes/theme.dark.less');
    // }
  }

  render() {
    const { children } = this.props;
    return (
      <HelmetProvider>
        <Helmet>
          <title>{config.app.title}</title>
          <meta name="description" content={config.app.desc} />
        </Helmet>

        <div className="container">
          <div className="content">
            <div className="top">
              <div className="header">
                <Link to="/">
                  <img alt="logo" className="logo" src={logo} />
                  <span className="title">{config.app.title}</span>
                </Link>
              </div>
              <div className="desc">{config.app.desc}</div>
            </div>
            {children}
          </div>
          <DefaultFooter
            copyright={`${new Date().getFullYear()} ${config.app.copyright}`}
            links={[
              {
                key: config.app.title,
                title: config.app.title,
                href: config.server.publicUrl,
                blankTarget: true,
              },
            ]}
          />
        </div>
      </HelmetProvider>
    );
  }
}

export default class AuthenticationLayoutRoute extends React.Component {
  render() {
    const { component: Component, isSignedIn, isProcessing, ...rest } = this.props;
    if (isProcessing) {
      return <PageLoading />;
    }
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          isSignedIn ? (
            <Redirect to="/home" />
          ) : (
            <AuthenticationLayout>
              <Component {...matchProps} />
            </AuthenticationLayout>
          )
        }
      />
    );
  }
}
