import {
  DashboardOutlined,
  CrownOutlined,
  HistoryOutlined,
  ProjectOutlined,
  ControlOutlined,
  UserOutlined,
  SettingOutlined,
  TeamOutlined,
  CodeSandboxOutlined,
} from '@ant-design/icons';

export const routesConfig = {
  route: {
    path: '/',
    routes: [
      {
        path: '/home',
        name: 'Home',
        breadcrumbName: 'Home',
        authority: ['user', 'admin', 'root'],
        icon: <DashboardOutlined />,
      },
      {
        path: '/projects',
        name: 'Projekte',
        icon: <ProjectOutlined />,
        authority: ['user', 'admin', 'root'],
        breadcrumbName: 'Projekte',
        routes: [
          {
            path: '/projects/active',
            name: 'Aktive Projekte',
            icon: <ControlOutlined />,
            breadcrumbName: 'Aktiv',
          },
          {
            path: '/projects/archived',
            name: 'Archiviert Projekte',
            icon: <HistoryOutlined />,
            breadcrumbName: 'Archiviert',
          },
        ],
      },
      {
        path: '/settings',
        name: 'Einstellungen',
        icon: <SettingOutlined />,
        authority: ['user', 'admin', 'root'],
        routes: [
          {
            path: '/settings/account',
            name: 'Account',
            icon: <UserOutlined />,
          },
          {
            path: '/settings/organisation',
            name: 'Organisation',
            icon: <TeamOutlined />,
            authority: ['admin', 'root', 'user'],
          },
        ],
      },
      {
        path: '/showroom',
        name: 'Showroom',
        icon: <CodeSandboxOutlined />,
        breadcrumbName: 'Showroom',
      },
      {
        path: '/root',
        name: 'Root',
        icon: <CrownOutlined />,
        authority: 'root',
        routes: [
          {
            path: '/root/users',
            name: 'Users',
          },
          {
            path: '/root/organisations',
            name: 'Organisations',
          },
          {
            path: '/root/projects',
            name: 'Projekte',
          },
          {
            path: '/root/models',
            name: 'Modelle',
          },
          {
            path: '/root/scans',
            name: 'Scans',
          },
          {
            path: '/root/notices',
            name: 'Notices',
          },
          {
            path: '/root/tokens',
            name: 'Tokens',
          },
          {
            path: '/root/anchor/location',
            name: 'Location',
          },
          {
            path: '/root/anchor/data',
            name: 'Anchor Data',
          },
          {
            path: '/root/db',
            name: 'Database',
          },
        ],
      },
    ],
  },
  location: {
    pathname: '/',
  },
};
