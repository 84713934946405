import React from 'react';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';

import { Avatar, Button, Space, message, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import { bimServerApi, errorHandler } from '../../store/apis/bim.server.api';

import DropOption from '../../components/DropOption';
import ProjectsModal from './Modal/ProjectsModal';

const { Option } = Select;
export default class ProjectsView extends React.Component {
  tableRef = React.createRef();
  constructor() {
    super();

    moment.locale('de', momentDe);

    this.state = {
      visible: false,
      deletion: false,
      current: undefined,
      archive: false,
      selectedRows: [],
    };
  }
  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = () => {
    this.setState({
      visible: true,
      current: undefined,
    });
  };
  showEditModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };
  showArchiveModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      archive: true,
    });
  };
  showDeleteModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      deletion: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      deletion: false,
    });
  };

  handleSubmit = (formValues) => {
    const { current } = this.state;
    if (current) {
      this.updateProject(formValues);
    } else {
      this.registerProject(formValues);
    }
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleArchive = () => {
    const { current } = this.state;
    this.archiveProject(current);
    this.setState({
      visible: false,
      deletion: false,
      archive: false,
    });
  };
  handleDelete = () => {
    this.deleteProject(this.state.current);
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDeleteItems = (users) => {
    users.map((user) => {
      this.deleteProject(user);
    });
    this.tableRef.current?.reloadAndRest?.();
  };

  deleteProject = (current) => {
    bimServerApi.delete(`/api/projects/${current.id}`).then(
      (response) => {
        message.success(`Löschen erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  registerProject = (formValues) => {
    bimServerApi.post('/api/projects/', formValues).then(
      (response) => {
        message.success(`Registration erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  updateProject = (formValues) => {
    const { current } = this.state;
    bimServerApi.put(`/api/projects/${current.id}`, formValues).then(
      () => {
        message.success(`Update erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  archiveProject = (item) => {
    const value = {
      archived: true,
    };
    this.updateProject(item.id, value);
  };
  /* ========================================================================== */
  /*                           DropOption Handle Click                          */
  /* ========================================================================== */

  handleMenuClick = (record, e) => {
    switch (e.key) {
      case '1':
        //Edit
        this.showEditModal(record);
        break;
      case '2':
        this.showArchiveModal(record);
        break;
      case '3':
        //Delete
        this.showDeleteModal(record);
        break;
    }
  };
  get columns() {
    return [
      {
        title: 'Cover',
        dataIndex: 'cover',
        key: 'cover',
        width: 100,

        search: false,
        render: (data) => <Avatar src={`data:image/jpeg;base64,${data}`} />,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        copyable: true,
      },
      {
        title: 'Desc',
        dataIndex: 'desc',
        key: 'desc',
        copyable: true,
        search: false,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        copyable: true,
        search: false,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        copyable: true,
        search: false,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return record.google ? (
            <a href={record.google} target="_blank">
              {record.address}
            </a>
          ) : null;
        },
      },
      {
        title: 'Website',
        dataIndex: 'website',
        key: 'website',
        copyable: true,
        search: false,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return _ ? (
            <a href={record.website} target="_blank">
              {record.website}
            </a>
          ) : (
            '-'
          );
        },
      },
      {
        title: 'Organisation',
        dataIndex: 'organisation',
        key: 'organisation',
        copyable: true,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          let data = [];
          record.organisation.map((d) => data.push(d.name));
          return record.organisation ? `${data}` : `-`;
        },
        // renderFormItem: (_, { defaultRender }) => {
        //   return defaultRender(_);
        // },
        // render: (_, record) => {
        //   const options = record.organisation.map((d) => <Option key={d.id}>{d.name}</Option>);
        //   return (
        //     <Select style={{ width: '100%' }} mode="multiple" defaultValue={options[0]}>
        //       {options}
        //     </Select>
        //   );
        // },
      },
      // {
      //   title: 'Organisation Id',
      //   dataIndex: 'organisation',
      //   key: 'organisation',
      //   renderFormItem: (_, { defaultRender }) => {
      //     return defaultRender(_);
      //   },
      //   render: (_, record) => {
      //     return (
      //       <Paragraph
      //         copyable={{
      //           text: record.organisation ? `${record.organisation._id}` : `-`,
      //           tooltips: ['kopieren.', 'kopiert!'],
      //         }}
      //       >
      //         <Text code>{record.organisation ? `${record.organisation._id}` : `-`}</Text>
      //       </Paragraph>
      //     );
      //   },
      // },
      {
        title: 'Default',
        dataIndex: 'default',
        filters: true,
        onFilter: true,
        valueType: 'select',
        valueEnum: {
          true: {
            text: 'True',
            status: 'Success',
          },
          false: {
            text: 'False',
            status: 'Error',
          },
        },
      },
      {
        title: 'Archiviert',
        dataIndex: 'archived',
        filters: true,
        onFilter: true,
        valueType: 'select',
        valueEnum: {
          true: {
            text: 'True',
            status: 'Success',
          },
          false: {
            text: 'False',
            status: 'Error',
          },
        },
      },
      {
        title: 'Erstellt',
        key: 'createdAt',
        dataIndex: 'createdAt',
        valueType: 'date',
        search: false,
        sorter: (a, b) => {
          const aa = moment(a.createdAt);
          const bb = moment(b.createdAt);
          return aa - bb;
        },
      },
      {
        title: 'Object Id',
        dataIndex: 'id',
        key: 'id',
        copyable: true,
        search: false,
      },
      {
        title: 'Action',
        valueType: 'option',
        fixed: 'right',
        render: (text, record, _, action) => [
          <DropOption
            onMenuClick={(e) => this.handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: `Bearbeiten`, icon: <EditOutlined /> },
              {
                key: '2',
                name: `Archivieren`,
                icon: <DeleteOutlined />,
              },
              {
                key: '3',
                name: `Löschen`,
                icon: <DeleteOutlined />,
                danger: true,
              },
            ]}
          />,
        ],
      },
    ];
  }
  render() {
    const { selectedRows, visible, current, deletion, archive } = this.state;
    return (
      <PageContainer>
        <ProTable
          actionRef={this.tableRef}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          columns={this.columns}
          headerTitle={
            <Space>
              <span>Projects Table</span>
            </Space>
          }
          rowSelection={{
            onChange: (_, selectedRows) => this.setState({ selectedRows: selectedRows }),
          }}
          toolBarRender={() => [
            <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => this.showModal()}>
              Add
            </Button>,
          ]}
          request={(params, sort, filter) => {
            delete params['current'];
            delete params['pageSize'];
            const queryString = Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&');
            return new Promise(async (resolve, reject) => {
              bimServerApi.get(`/api/projects/?${queryString}`).then(
                (response) => {
                  resolve({ data: response.data.projects, success: true });
                },
                (err) => {
                  errorHandler(err);
                  reject({ data: null, success: false });
                },
              );
            });
          }}
        />
        {selectedRows?.length > 0 && (
          <FooterToolbar
            extra={
              <div>
                Ausgewählt: <a style={{ fontWeight: 600 }}>{selectedRows.length}</a> Projekte
              </div>
            }
          >
            <Button
              type="primary"
              onClick={async () => {
                await this.handleDeleteItems(selectedRows);
                this.setState({ selectedRows: [] });
              }}
            >
              Löschen
            </Button>
          </FooterToolbar>
        )}
        <ProjectsModal
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
          onArchive={this.handleArchive}
          visible={visible}
          deletion={deletion}
          archive={archive}
          current={current}
        />
      </PageContainer>
    );
  }
}
