import React from 'react';
import _ from 'lodash';

import { Modal, Button, Form, Input, Switch, Upload, Avatar, Select, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';

import { bimServerApi, errorHandler } from '../../../store/apis/bim.server.api';

const { Option } = Select;

export default class UserModal extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      data: [],
      value: undefined,
    };
  }

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }

  handleSearch = (value) => {
    if (value) {
      this.fetchOrganisations(value, (data) => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };
  handleSearchChange = (value) => {
    this.setState({ value });
  };
  fetchOrganisations = (value, callback) => {
    bimServerApi.get(`/api/organisations/all?name=${value}`).then(
      (response) => {
        callback(response.data.organisations);
      },
      (err) => {
        errorHandler(err);
      },
    );
  };

  handleFileUpload = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  /* ========================================================================== */
  /*                               Avatar uploader                             */
  /* ========================================================================== */

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      this.setState({
        error: true,
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      this.setState({
        error: true,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  customRequest = async (option) => {
    const { file, onSuccess, onError } = option;
    const { current } = this.props;
    let formData = new FormData();
    formData.append('avatar', file);
    return bimServerApi.post(`/api/users/${current.id}/avatar`, formData, {}).then(
      (response) => {
        onSuccess();
      },
      (err) => {
        onError();
        errorHandler(err);
      },
    );
  };

  handleChange = ({ fileList }) => {
    if (fileList == []) {
      console.log('remove');
    }
    this.setState({ fileList });
  };
  handlePreviewCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (fieldsValue) => {
    if (this.props.onSubmit) {
      delete fieldsValue['avatar'];
      delete fieldsValue['objectId'];
      delete fieldsValue['confirm'];
      Object.keys(fieldsValue).map((key) => {
        if (fieldsValue[key] === undefined) {
          delete fieldsValue[key];
        }
      });
      let layout = {
        color: fieldsValue['layoutColor'],
        mode: fieldsValue['layoutMode'],
      };
      Object.keys(layout).map((key) => {
        if (layout[key] === undefined) {
          delete layout[key];
        }
      });
      let formValues;
      if (!_.isEmpty(layout)) {
        formValues = {
          ...fieldsValue,
          layout,
        };
      } else {
        formValues = fieldsValue;
      }

      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  /* ========================================================================== */
  /*                               Validate                                     */
  /* ========================================================================== */

  validateBirthday = (rule, value, callback) => {
    if (value === undefined) return callback();
    const values = value.split('.');
    const cbText = 'Bitte achten Sie auf das richtige Format: DD.MM.YYYY';
    if (!values[0] || values[0].length < 2) {
      callback(cbText);
    }
    if (!values[1] || values[1].length < 2) {
      callback(cbText);
    }
    if (!values[2] || values[2].length < 4) {
      callback(cbText);
    }
    callback();
  };

  checkPassword = (rule, value, callback) => {
    if (value.length < 6) {
      callback('Das Passwort muss mindestens 6 Stellen lang sein!');
    }
    callback();
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */

  getModalContent() {
    const { current, deletion } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle, data } = this.state;

    let options;
    if (!_.isEmpty(data)) {
      options = data.map((d) => <Option key={d.id}>{d.name}</Option>);
    } else {
      options = [];
    }

    let disabled;
    if (deletion) {
      disabled = true;
    }
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    var hasCover = false;
    var initialValues = {};
    if (current) {
      if (current.avatar) {
        hasCover = true;
      }
      initialValues = {
        ['firstName']: current ? current.firstName : '',
        ['lastName']: current ? current.lastName : '',
        ['email']: current ? current.email : '',
        ['password']: current ? current.password : '',
        ['role']: current ? current.role : '',
        ['subscription']: current ? current.subscription : '',
        ['birthday']: current ? current.birthday : '',
        ['organisation']: current && current.organisation ? current.organisation._id : undefined,
        ['layoutColor']: current && current.layout ? current.layout.color : undefined,
        ['layoutMode']: current && current.layout ? current.layout.mode : undefined,
        ['objectId']: current ? current.id : '',
      };
    }
    return (
      <Form {...this.formLayout} onFinish={this.handleFinish} ref={this.formRef} initialValues={initialValues} style={{ padding: 10 }}>
        {current !== undefined && (
          <Form.Item name="avatar" label="Avatar" disabled={disabled}>
            <ImgCrop grid shape="round" cropperProps={{ cropShape: 'round' }}>
              <Upload
                name="avatar"
                className="cover-uploader"
                listType="picture-card"
                fileList={fileList}
                customRequest={this.customRequest}
                beforeUpload={this.beforeUpload}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
                style={{ with: 128, height: 128 }}
                disabled={disabled}
              >
                {fileList.length == 1 ? null : hasCover ? (
                  <img alt="example" style={{ width: '100%' }} src={`data:image/jpeg;base64,${current.avatar}`} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </Form.Item>
        )}
        <Form.Item
          name="firstName"
          label="Vorname"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie Ihren Vornamen ein!',
              whitespace: true,
            },
          ]}
        >
          <Input disabled={disabled} placeholder="Vorname" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Nachname"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie Ihren Nachname ein!',
              whitespace: true,
            },
          ]}
        >
          <Input disabled={disabled} placeholder="Nachname" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          hasFeedback
          rules={[
            {
              type: 'email',
              message: 'Dies ist keine gülte Email-Adresse!',
            },
            {
              required: true,
              message: 'Bitte geben Sie Ihre Email ein!',
            },
          ]}
        >
          <Input disabled={disabled} placeholder="Email" />
        </Form.Item>
        {!current && (
          <>
            <Form.Item
              name="password"
              label="Passwort"
              hasFeedback
              rules={[
                {
                  required: current ? false : true,
                  message: 'Bitte Ihr Passwort eingeben!',
                },
                {
                  validator: this.checkPassword,
                },
              ]}
              hasFeedback
            >
              <Input.Password disabled={disabled} placeholder="Passwort" />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Passwort Bestätigen"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: current ? false : true,
                  message: 'Bitte wiederholen Sie ihr Passwort!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Die Passwörter stimmen nicht überein!');
                  },
                }),
              ]}
            >
              <Input.Password disabled={disabled} placeholder="Passwort wiederholen" />
            </Form.Item>
          </>
        )}
        <Form.Item name="role" label="Rolle">
          <Select defaultValue="user" disabled={disabled}>
            <Option value="user">User</Option>
            <Option value="admin">Admin</Option>
            <Option value="root">Root</Option>
          </Select>
        </Form.Item>
        <Form.Item name="subscription" label="Subscription">
          <Select defaultValue="test" disabled={disabled}>
            <Option value="test">Test</Option>
            <Option value="basic">Basic</Option>
            <Option value="professional">Professional</Option>
            <Option value="premium">Premium</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="birthday"
          label="Geburtsdatum"
          rules={[
            {
              message: 'Bitte geben Sie Ihre Geburtsdatum an!',
              required: false,
            },
            {
              validator: this.validateBirthday,
            },
          ]}
          hasFeedback
        >
          <Input placeholder="DD.MM.YYYY" disabled={disabled} />
        </Form.Item>
        <Form.Item
          name="organisation"
          label="Organisation ID"
          hasFeedback
          rules={[
            {
              required: false,
              message: 'Bitte geben Sie Ihre Organisation an!',
            },
          ]}
        >
          <Select
            showSearch
            value={this.state.value}
            placeholder="Organisation"
            //style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            notFoundContent="keine passender Eintrag gefunden."
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item name="layoutColor" label="Layout Color">
          <Input placeholder="layoutColor in HEX" />
        </Form.Item>
        <Form.Item name="layoutMode" label="Layout Mode">
          <Select defaultValue="light" disabled={disabled}>
            <Option value="light">Light</Option>
            <Option value="dark">Dark</Option>
          </Select>
        </Form.Item>
        <Form.Item name="objectId" label="Object ID">
          <Input placeholder="Object Id " disabled={true} />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { deletion, visible, current, onDelete } = this.props;
    var modalFooter;
    if (!deletion) {
      modalFooter = current
        ? {
            okText: 'Update',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          }
        : {
            okText: 'Speichern',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          };
    } else {
      modalFooter = {
        okText: 'Löschen',
        okButtonProps: { danger: true },
        onOk: onDelete,
        onCancel: this.handleCancel,
      };
    }

    return (
      <Modal
        title={`User ${current ? (deletion ? 'löschen' : 'bearbeiten') : 'hinzufügen'}`}
        className="standardListForm"
        width={640}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={visible}
        {...modalFooter}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}
