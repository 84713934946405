import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  projects: null,
  project: null,
  processing: false,
  processed: false,
  error: null,
  activeTab: '0',
};

const projectsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PROJECTS:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        processing: false,
        projects: action.payload,
      };
    case actionTypes.GET_PROJECTS_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_PROJECT:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_PROJECT_SUCCESS:
      return {
        ...state,
        processing: false,
        project: action.payload,
      };
    case actionTypes.GET_PROJECT_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_PROJECT:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        processing: false,
        project: action.payload,
      };
    case actionTypes.UPDATE_PROJECT_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.REGISTER_PROJECT:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.REGISTER_PROJECT_SUCCESS:
      const updatedProjects = [...state.projects, { ...action.payload }];
      return {
        ...state,
        processing: false,
        projects: [...state.projects, { ...action.payload }],
      };
    case actionTypes.REGISTER_PROJECT_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.DELETE_PROJECT:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case actionTypes.DELETE_PROJECT_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_COVER:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_COVER_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_COVER_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        project: action.payload,
      };
    case actionTypes.SET_ACTIVE_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case actionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case actionTypes.UNLOAD_PROJECT_PAGE:
      return {
        ...state,
        error: null,
        processing: false,
        processed: false,
        project: null,
      };
    default:
      return state;
  }
};

export default projectsReducer;
