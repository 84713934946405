import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  organisations: null,
  organisation: null,
  users: null,
  projects: null,
  processing: false,
  processed: false,
  error: null,
  projectsCount: null,
  modelsCount: null,
  scansCount: null,
};

const organisationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ORGANISATIONS:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        processing: false,
        organisations: action.payload,
      };
    case actionTypes.GET_ORGANISATIONS_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_ORGANISATION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        processing: false,
        organisation: action.payload,
      };
    case actionTypes.GET_ORGANISATION_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_ORGANISATION_USERS:
    case actionTypes.GET_ORGANISATION_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.GET_ORGANISATION_USERS_FAIL:
    case actionTypes.GET_ORGANISATION_PROJECTS:
    case actionTypes.GET_ORGANISATION_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };
    case actionTypes.GET_ORGANISATION_PROJECTS_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
      };
    case actionTypes.INVITE_TO_ORGANISATION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.INVITE_TO_ORGANISATION_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        error: null,
      };
    case actionTypes.INVITE_TO_ORGANISATION_FAIL:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_ORGANISATION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        processing: false,
        organisation: action.payload,
      };
    case actionTypes.UPDATE_ORGANISATION_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.REGISTER_ORGANISATION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.REGISTER_ORGANISATION_SUCCESS:
      return {
        ...state,
        processing: false,
        organisations: [...state.projects, { ...action.payload }],
        organisation: action.payload,
      };
    case actionTypes.REGISTER_ORGANISATION_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.DELETE_ORGANISATION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.DELETE_ORGANISATION_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case actionTypes.DELETE_ORGANISATION_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_LOGO:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_LOGO_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        processing: false,
        organisation: action.payload,
      };
    case actionTypes.UPDATE_USER_ROLE:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_USER_ROLE_SUCCESS:
      const updatedUsers = state.users.map((user) => (user.id === action.payload.id ? action.payload : user));
      return {
        ...state,
        processing: false,
        users: updatedUsers,
      };
    case actionTypes.UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.REMOVE_FROM_ORGANISATION:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.REMOVE_FROM_ORGANISATION_SUCCESS:
      const users = state.users;
      _.remove(users, (user) => {
        return user.id === action.payload.id;
      });
      return {
        ...state,
        processing: false,
        users: users,
      };
    case actionTypes.REMOVE_FROM_ORGANISATION_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_ORGANISATION_STATS:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_ORGANISATION_STATS_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_ORGANISATION_STATS_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        projectsCount: action.payload.projectsCount,
        modelsCount: action.payload.modelsCount,
        scansCount: action.payload.scansCount,
      };
    case actionTypes.UNLOAD_ORGANISATION_PAGE:
      return {
        ...state,
        error: null,
        organisation: null,
        users: null,
        processing: false,
        processed: false,
      };
    default:
      return state;
  }
};

export default organisationReducer;
