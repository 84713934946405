import React from 'react';
import { Modal, Form, Input, Upload, message, Alert } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';

export default class OrganisationModal extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      error: false,
    };
  }

  /* ========================================================================== */
  /*                                 upload cover                                 */
  /* ========================================================================== */

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  customRequest = async (option) => {
    const { file, onSuccess, onError } = option;
    console.log(file);
    await this.props.updateLogo(this.props.organisation.id, file);
    if (!this.props.error) {
      onSuccess();
    } else {
      onError();
    }
  };

  handleChange = ({ fileList }) => {
    if (fileList == []) {
      console.log('remove');
    }
    this.setState({ fileList });
  };
  handlePreviewCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    if (this.props.onSubmit) {
      delete formValues['logo'];
      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.setState({
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      error: false,
    });
  };

  normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */
  getModalContent() {
    const { organisation } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    var hasCover = false;
    var initialValues = {};
    if (organisation) {
      if (organisation.logo) {
        hasCover = true;
      }
      initialValues = {
        ['name']: organisation.name,
        ['slogan']: organisation.slogan,
        ['address']: organisation.address,
        ['google']: organisation.google,
        ['website']: organisation.website,
      };
    }
    return (
      <>
        {this.props.error && <Alert showIcon message="Fehler" description={this.props.error} type="error" />}
        <Form {...this.formLayout} ref={this.formRef} style={{ padding: 10 }} onFinish={this.handleFinish} initialValues={initialValues}>
          {organisation && (
            <Form.Item name="logo" label="Logo">
              {/* <ImgCrop
                grid
                aspect={260 / 150}
                rotate={true}
                minZoom={0.5}
                cropperProps={{
                  crop: { x: 0, y: 0 },
                  cropSize: { width: 260, height: 150 },
                }}
              > */}
              <Upload
                name="logo"
                className="cover-uploader"
                listType="picture-card"
                fileList={fileList}
                customRequest={this.customRequest}
                beforeUpload={this.beforeUpload}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
                style={{ with: 128, height: 128 }}
              >
                {fileList.length == 1 ? null : hasCover ? (
                  <img alt="example" style={{ width: '100%' }} src={`data:image/jpeg;base64,${organisation.logo}`} />
                ) : (
                  uploadButton
                )}
              </Upload>
              {/* </ImgCrop> */}
            </Form.Item>
          )}
          <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handlePreviewCancel}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          <Form.Item
            name="name"
            label="Firmenname"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie einen Firmenname an.',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Organisation Name" />
          </Form.Item>
          <Form.Item name="slogan" label="Slogan">
            <Input.TextArea rows={2} placeholder="Firmenslogan" maxLength={50} />
          </Form.Item>
          <Form.Item name="address" label="Adresse">
            <Input placeholder="Straße Nr. ,PLZ Stadt" />
          </Form.Item>
          <Form.Item name="google" label="Google Maps Link">
            <Input placeholder="https://goo.gl/maps/suffix" />
          </Form.Item>
          <Form.Item name="website" label="Webseite">
            <Input placeholder="https://firmenseite.de" />
          </Form.Item>
        </Form>
      </>
    );
  }

  render() {
    const { visible, organisation } = this.props;
    var modalFooter;

    modalFooter = organisation
      ? {
          okText: 'Update',
          onOk: this.handleSubmit,
          onCancel: this.handleCancel,
        }
      : {
          okText: 'Erstellen',
          onOk: this.handleSubmit,
          onCancel: this.handleCancel,
        };
    return (
      <>
        <Modal
          title={`Organisation ${organisation ? 'bearbeiten' : 'erstellen'}`}
          className="standardListForm"
          width={640}
          bodyStyle={{ padding: '28px 0 0' }}
          destroyOnClose
          visible={visible}
          {...modalFooter}
        >
          {this.getModalContent()}
        </Modal>
      </>
    );
  }
}
