import * as actionTypes from './types';
import { message } from 'antd';

export const getOrganisations = (query = '') => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_ORGANISATIONS });
  return bimServerApi.get(`/api/organisations/all${query}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_ORGANISATIONS_SUCCESS, payload: response.data.organisations });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_ORGANISATIONS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const getOrganisation = () => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_ORGANISATION });
  return bimServerApi.get(`/api/organisations/`).then(
    (response) => {
      dispatch(getOrganisationUsers(response.data.organisation.id));
      dispatch(getOrganisationStats(response.data.organisation.id));
      dispatch({ type: actionTypes.GET_ORGANISATION_SUCCESS, payload: response.data.organisation });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_ORGANISATION_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

const getOrganisationUsers = (organisationId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_ORGANISATION_USERS });
  return bimServerApi.get(`/api/users/?organisation=${organisationId}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_ORGANISATION_USERS_SUCCESS, payload: response.data.users });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_ORGANISATION_USERS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

const getOrganisationProjects = () => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_ORGANISATION_PROJECTS });
  return bimServerApi.get(`/api/projects/?archived=false`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_ORGANISATION_PROJECTS_SUCCESS, payload: response.data.projects });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_ORGANISATION_PROJECTS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

var preFormValues = null;

export const inviteUser = (organisationId, formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.INVITE_TO_ORGANISATION });
  return bimServerApi.post(`/api/organisations/${organisationId}/invite`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.INVITE_TO_ORGANISATION_SUCCESS });
      message.success('Eine Einladung wurde an den Nutzer versendet.');
    },
    (err) => {
      preFormValues = formValues;
      dispatch({
        type: actionTypes.INVITE_TO_ORGANISATION_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const removeUser = (organisationId, userId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.REMOVE_FROM_ORGANISATION });
  return bimServerApi.post(`/api/organisations/${organisationId}/remove`, { userId: userId }).then(
    (response) => {
      dispatch({ type: actionTypes.REMOVE_FROM_ORGANISATION_SUCCESS, payload: response.data.user });
    },
    (err) => {
      dispatch({
        type: actionTypes.REMOVE_FROM_ORGANISATION_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const inviteUserToBimforge = () => (dispatch, getState, { bimServerApi }) => {
  return bimServerApi.post(`/api/organisations/invite`, preFormValues).then(
    (response) => {
      dispatch({ type: actionTypes.INVITE_TO_ORGANISATION_SUCCESS });
    },
    (err) => {
      dispatch({
        type: actionTypes.INVITE_TO_ORGANISATION_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const updateOrganisation = (organisationId, formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_ORGANISATION });
  return bimServerApi.put(`/api/organisations/${organisationId}`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_ORGANISATION_SUCCESS, payload: response.data.organisation });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_ORGANISATION_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateLogo = (organisationId, file) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_LOGO });
  let formData = new FormData();
  formData.append('logo', file);
  return bimServerApi.post(`/api/organisations/${organisationId}/logo`, formData, {}).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_LOGO_SUCCESS, payload: response.data.organisation });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_LOGO_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateUserRole = (userId, formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_USER_ROLE });
  return bimServerApi.put(`/api/users/${userId}`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_USER_ROLE_SUCCESS, payload: response.data.user });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_USER_ROLE_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const getOrganisationStats = (organisationId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_ORGANISATION_STATS });
  return bimServerApi.get(`/api/organisations/${organisationId}/stats`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_ORGANISATION_STATS_SUCCESS, payload: response.data });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_ORGANISATION_STATS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const unloadOrganisationPage = () => {
  return {
    type: actionTypes.UNLOAD_ORGANISATION_PAGE,
  };
};
