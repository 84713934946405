import React from 'react';
import _ from 'lodash';

import { Form, Button, Divider, Input, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import './index.less';
import { PageLoading } from '@ant-design/pro-layout';

const { Dragger } = Upload;

export default class Step1 extends React.Component {
  formRef = React.createRef();

  /* ========================================================================== */
  /*                                 constructor                                */
  /* ========================================================================== */
  constructor() {
    super();

    this.state = {
      defaultFileList: [],
      disabled: true,
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.supportedFormats)) {
      this.props.getSupportedFormats();
    }
  }

  supportedFormats = () => {
    const { supportedFormats } = this.props;
    if (supportedFormats === undefined) {
      return <PageLoading />;
    }
    var formats = [];
    Object.keys(supportedFormats).forEach((key) => {
      const values = supportedFormats[key];
      formats.push(<li key={key}>{key}</li>);
      if (Array.isArray(values)) {
        values.forEach((value) => {
          formats.push(
            <ul key={`ul-${key}-${value}`}>
              <li key={`${key}-${value}`}>{value}</li>
            </ul>,
          );
        });
      }
    });
    return formats;
  };
  acceptedFormats = () => {
    const { supportedFormats } = this.props;
    if (supportedFormats === undefined) {
      return [];
    }
    var formats = [];
    Object.keys(supportedFormats).forEach((key) => {
      const values = supportedFormats[key];
      if (Array.isArray(values)) {
        values.forEach((value) => {
          formats.push(`.${value}`);
        });
      }
    });
    return formats;
  };

  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */
  get formLayout() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 19,
      },
    };
  }

  /* ========================================================================== */
  /*                              Uploader Options                              */
  /* ========================================================================== */

  handleFileUpload = (e) => {
    if (this.props.handleFileUpload) {
      this.props.handleFileUpload(e);
    }
  };
  customRequest = (option) => {
    if (this.props.customRequest) {
      this.props.customRequest(option);
      this.setState({
        disabled: false,
      });
    }
  };
  onRemove = () => {
    if (this.props.onRemove) {
      this.props.onRemove();
      this.setState({
        disabled: true,
      });
    }
  };
  onContinue = () => {
    if (this.props.onContinue) {
      this.props.setCurrentStep(1);
      this.props.onContinue();
    }
  };
  onFinish = (formValues) => {
    delete formValues['uploader'];
    if (this.props.onContinue) {
      this.props.setCurrentStep(1);
      this.props.onContinue(formValues);
    }
  };

  render() {
    const { disabled } = this.state;
    return (
      <>
        <Form {...this.formLayout} ref={this.formRef} layout="horizontal" className="stepForm" hideRequiredMark onFinish={this.onFinish}>
          <Divider />
          <Form.Item name="fileName" label="Dateiname">
            <Input placeholder="Optional: Vergeben Sie einen Dateinamen" />
          </Form.Item>
          <Form.Item label="Uploader" name="uploader" getValueFromEvent={(e) => this.handleFileUpload(e)} valuePropName="fileList">
            <Dragger name={'file'} multiple={false} onRemove={this.onRemove} customRequest={this.customRequest} accept={this.acceptedFormats()}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Klicken oder Drag & Drop</p>
              <p className="ant-upload-hint">Unterstützte Dateiformate: siehe unten</p>
            </Dragger>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: {
                span: this.formLayout.wrapperCol.span,
                offset: this.formLayout.labelCol.span,
              },
            }}
          >
            <Button type="primary" htmlType="submit" disabled={disabled}>
              Weiter
            </Button>
          </Form.Item>
        </Form>
        <Divider style={{ margin: '40px 0 24px' }} />
        <div className="desc">
          <h3>Unterstützte Dateiformate:</h3>
          <h4>Die folgenden Dateiformate werden derzeit unterstützt:</h4>
          <ul>{this.supportedFormats()}</ul>
        </div>
      </>
    );
  }
}
