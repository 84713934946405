import React from 'react';
import { Button, Divider, Steps, Space } from 'antd';
import { CheckCircleOutlined, ScanOutlined, PlusCircleOutlined, DingtalkOutlined, DatabaseOutlined } from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import { GridContent } from '@ant-design/pro-layout';

import moment from 'moment';
import momentDe from 'moment/locale/de';

const { Step } = Steps;

export default class ProjectOverview extends React.Component {
  constructor() {
    super();

    moment.locale('de', momentDe);

    this.state = {
      currentStep: 1,
    };
  }

  getCurrentStepDescription(stepIndex) {
    switch (stepIndex) {
      case 1:
        return (
          <ProCard title="Task-Forge">
            <Space>
              <Divider style={{ margin: '40px 0 24px' }} />
              <div className="desc">
                <h4>Scan</h4>
                <p>Der Standard für die 3D-Erfassung von Objekten und für Kollaboration.</p>
                <h4>Visualisierung</h4>
                <p>
                  Betrachten Sie Ihr Objekt virtuell von überall in hochauflösender Qualität. Entnehmen Sie Maße direkt im Modell, erzeugen Sie Ihre
                  Grundrisse automatisiert
                </p>
                <p>
                  Beauftragen Sie die Erstellung eines immersiver digitaler 3D-Zwilling, der sich ideal für die Planung, Ausführung oder Dokumentation
                  von Umbauprojekten und mehr eignet.
                </p>
                <Button type="primary" onClick={() => this.props.setActiveTab(`${stepIndex}`)}>
                  Los
                </Button>
              </div>
            </Space>
          </ProCard>
        );
      case 2:
        return (
          <ProCard title="Twin-Forge">
            <Space>
              <Divider style={{ margin: '40px 0 24px' }} />
              <div className="desc">
                <h4>Optimierung</h4>
                <p>
                  Hier werden Ihre 2D- und 3D-Ansichten aus über 70 Dateiformaten in ein Web- und Mobiloptimiertes Format konvertiert, so dass sie im
                  Browser und auf Handys angeschaut werden können. Es werden Geometrie- und Objekteigenschaftsdaten extrahiert, die über verschiedene
                  Erweiterungen auf dieser Plattform nutzbar gemacht werden.
                </p>
                <h4>Konvertierung</h4>
                <p>
                  Übersetzen Sie Ihre Modelle oder Zeichnungen von einem CAD-Dateiformat in ein anderes, und laden Sie die übersetzte Datei herunter.
                </p>
                <Button type="primary" onClick={() => this.props.setActiveTab(`${stepIndex}`)}>
                  Los
                </Button>
              </div>
            </Space>
          </ProCard>
        );
      case 3:
        return (
          <ProCard title="Dash-Forge">
            <Space>
              <Divider style={{ margin: '40px 0 24px' }} />
              <div className="desc">
                <h4>IoT</h4>
                <p>Verknüpfen Sie Echtzeitdaten aus Gebäudeautomation oder aus Anlagen direkt in Ihrem digitalen Zwilling.</p>
                <h4>Dashboarding</h4>
                <p>Visualisieren Sie Ihre Daten in einer nie dagewesenen Art und Weise</p>
                <Button type="primary" onClick={() => this.props.setActiveTab(`${stepIndex}`)}>
                  Los
                </Button>
              </div>
            </Space>
          </ProCard>
        );
      case 3:
    }
  }

  /* ========================================================================== */
  /*                                Roadmap Steps                               */
  /* ========================================================================== */
  onStepChange = (current) => {
    this.setState({
      currentStep: current,
    });
  };

  checkedSubTitle = (stepIndex) => {
    switch (stepIndex) {
      case 1:
        return <CheckCircleOutlined style={{ color: 'green' }} />;
      case 2:
        if (
          this.props.currentUser.subscription === 'basic' ||
          this.props.currentUser.subscription === 'professional' ||
          this.props.currentUser.subscription === 'premium'
        ) {
          return <CheckCircleOutlined style={{ color: 'green' }} />;
        }
      case 3:
        if (this.props.currentUser.subscription === 'professional' || this.props.currentUser.subscription === 'premium') {
          return <CheckCircleOutlined style={{ color: 'green' }} />;
        }
      case 4:
        if (this.props.currentUser.subscription === 'premium') {
          return <CheckCircleOutlined style={{ color: 'green' }} />;
        }
      default:
        break;
    }
  };

  render() {
    const { currentStep } = this.state;
    const { project } = this.props;
    return (
      <GridContent>
        <ProCard title="Schnellstart" style={{ marginBottom: 24 }} split="vertical" bordered>
          <ProCard>
            <Steps direction="vertical" size="small" current={currentStep} onChange={this.onStepChange}>
              <Step
                title="Projekt erstellen"
                subTitle={this.checkedSubTitle(1)}
                description="Ergänzen Sie nach Bedarf die o.g. Projektinformationen"
                disabled={true}
                icon={<PlusCircleOutlined />}
              />
              <Step
                title="Task-Forge"
                subTitle={this.checkedSubTitle(2)}
                description="Virtueller Rundgang von Bestandsgebäuden"
                icon={<ScanOutlined />}
              />
              <Step title="Twin-Forge" subTitle={this.checkedSubTitle(3)} description="Digitaler Zwilling in der Cloud" icon={<DingtalkOutlined />} />
              <Step
                title="Dash-Forge"
                subTitle={this.checkedSubTitle(4)}
                description="Dashboarding von Echtzeitdaten im digitalen Zwilling"
                icon={<DatabaseOutlined />}
              />
            </Steps>
          </ProCard>
          {/* <ProCard >
              <Space>{this.getCurrentStepDescription(currentStep)}</Space>
            </ProCard> */}
          {this.getCurrentStepDescription(currentStep)}
        </ProCard>
      </GridContent>
    );
  }
}
