import React from 'react';
import { Avatar, Menu, message, Spin } from 'antd';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import { signOut } from '../../store/actions';
import { getCurrentUser } from '../../store/selectors';
import HeaderDropdown from '../HeaderDropdown';
import './index.less';

class AvatarDropdown extends React.Component {
  /* ========================================================================== */
  /*                                 Menu Click                                 */
  /* ========================================================================== */
  onMenuClick = (event) => {
    const { key } = event;

    if (key === 'logout') {
      this.props.signOut();
      return;
    }
    this.props.history.push(`/${key}`);
  };

  render() {
    const { currentUser } = this.props;
    const menuHeaderDropdown = (
      <Menu className="menu" selectedKeys={[]} onClick={this.onMenuClick}>
        <Menu.Item key="settings/account">
          <SettingOutlined /> Einstellungen
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="logout">
          <LogoutOutlined /> Abmelden
        </Menu.Item>
      </Menu>
    );

    return currentUser && currentUser.firstName ? (
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`action account`}>
          <Avatar size="small" className="avatar" src={`data:image/png;base64,${currentUser.avatar}`} alt="avatar" />
          <span className={`name anticon`}>
            {' '}
            {currentUser.firstName} {currentUser.lastName}
          </span>
        </span>
      </HeaderDropdown>
    ) : (
      <span className={`action account`}>
        <Spin
          size="small"
          style={{
            marginLeft: 8,
            marginRight: 8,
          }}
        />
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});
export default connect(mapStateToProps, { signOut })(AvatarDropdown);
