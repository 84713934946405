import React from 'react';
import ProLayout, { SettingDrawer, PageLoading } from '@ant-design/pro-layout';
import { Link, Route, Redirect } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import _ from 'lodash';
import { changeAntdTheme } from 'dynamic-antd-theme';
import { MailOutlined, GlobalOutlined } from '@ant-design/icons';

import logo from '../../logo.svg';
import nLightenLogo from '../../nLighten_logo_gold.svg';
import config from '../../config';
import RightContent from '../../components/GlobalHeader/RightContent';

import './CoreLayout.less';

class CoreLayout extends React.Component {
  //menuDataRef = React.createRef();
  constructor(props) {
    super(props);
    this.menuItemProps = [];
    this.restrictedRoutes = [];
    this.state = {
      collapsed: false,
      pathname: props.pathname,
      settings: config.layout.settings,
      theme: 'light',
      locale: 'de-DE',
    };
  }

  componentDidMount() {
    this.setLayoutMode().then(() => {
      this.setLayoutColor();
    });
  }

  setLayoutMode = async () => {
    const { currentUser } = this.props;
    const { layout } = currentUser;
    const cacheLayoutMode = window.localStorage.getItem('layoutMode');

    //console.log(cacheLayoutMode);
    if (cacheLayoutMode === 'light') {
      return import('../../themes/theme.light.less');
    }

    if (cacheLayoutMode === 'dark' || layout.mode === 'dark') {
      return import('../../themes/theme.dark.less');
    }
    // if (cacheLayoutMode === 'light' || layout.mode === 'light') {
    //   console.log('____________________________________');
    //   console.log('Light');
    //   import('../../themes/theme.light.less');
    // }
    // if (cacheLayoutMode === 'dark' || layout.mode === 'dark') {
    //   console.log('____________________________________');
    //   console.log('Dark');
    //   import('../../themes/theme.dark.less');
    // }
  };
  setLayoutColor = async () => {
    const { currentUser } = this.props;
    const { layout } = currentUser;
    const cacheLayoutColor = window.localStorage.getItem('layoutColor');

    if (cacheLayoutColor && cacheLayoutColor !== layout.color) {
      changeAntdTheme(cacheLayoutColor);
      window.localStorage.setItem('layoutColor', cacheLayoutColor);
    } else {
      changeAntdTheme(layout.color);
      window.localStorage.setItem('layoutColor', layout.color);
    }
  };

  setPathname = (path) => {
    this.setState({
      pathname: path || this.props.pathname,
    });
  };
  setSettings = (settings) => {
    this.setState({
      settings: settings,
    });
  };
  setRestrictedRoutes = (pathname) => {
    if (!this.restrictedRoutes.includes(pathname)) {
      this.restrictedRoutes.push(pathname);
    }
  };

  // setTheme = (theme) => {
  //   if (theme === 'light') {
  //     message.loading({ content: 'Theme wird geladen...' });
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //     return import('../../themes/theme.light.less');
  //   } else {
  //     message.loading({ content: 'Theme wird geladen...' });

  //     return import('../../themes/theme.dark.less');
  //   }
  // };

  isAuthorized = () => {
    const { pathname } = this.state;
    const { currentUser } = this.props;
    if (currentUser.role === 'root') {
      return true;
    }
    if (this.restrictedRoutes.length !== 0) {
      if (this.restrictedRoutes.includes(pathname)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  render() {
    const { settings, theme } = this.state;
    const { children, currentUser } = this.props;
    const { pathname } = this.props;

    // used to check authority
    // if currentUser.role =! route.authority return null (= dont show the route in the sidebar)
    // and save pathnames of resticted access to a variable to check permission if users trys
    // to access the route with url
    const menuDataRender = (menuList) =>
      menuList.map((item) => {
        const localItem = {
          ...item,
          children: item.children ? menuDataRender(item.children) : undefined,
        };
        if (Array.isArray(item.authority)) {
          if (item.authority.includes(currentUser.role)) {
            return localItem;
          } else {
            this.setRestrictedRoutes(localItem.path);
            return null;
          }
        }
        if (typeof item.authority === 'string') {
          if (item.authority === currentUser.role) {
            return localItem;
          } else {
            this.setRestrictedRoutes(localItem.path);
            return null;
          }
        }
        return localItem;
      });
    // const menuFooterRender = (props) => {
    //   var switchTheme;
    //   if (!props?.collapsed) {
    //     return (
    //       <>
    //         <Divider style={{ background: 'white' }} />

    //         <a
    //           style={{
    //             display: 'flex',
    //             height: 16,
    //             color: 'rgba(255, 255, 255, 0.85)',
    //             alignItems: 'center',
    //             paddingLeft: '15px',
    //             paddingTop: '10px',
    //           }}
    //           href="https://bimension.de"
    //           target="_blank"
    //           rel="noreferrer"
    //         >
    //           {!props?.collapsed && 'BIMension GmbH'}
    //         </a>
    //         <a
    //           style={{
    //             lineHeight: '48rpx',
    //             display: 'flex',
    //             height: 48,
    //             color: 'rgba(255, 255, 255, 0.65)',
    //             alignItems: 'center',
    //             paddingLeft: '15px',
    //           }}
    //           href="mailto:support@bimension.de?subject=Anfrage%20über%20BIM-Forge"
    //         >
    //           support@bimension.de
    //         </a>
    //       </>
    //     );
    //   }
    // };
    const menuItemRender = (menuItemProps, defaultDom) => {
      if (menuItemProps.isUrl || !menuItemProps.path) {
        return defaultDom;
      }

      return (
        <Link
          to={menuItemProps.path}
          // onClick={() => {
          //   this.setPathname(menuItemProps.path);
          // }}
        >
          {defaultDom}
        </Link>
      );
    };
    const itemRender = (route, params, routes, paths) => {
      const first = routes.indexOf(route) === 0;
      return first ? (
        <Link
          to={paths.join('/home')}
          // onClick={() => {
          //   this.setPathname('/home');
          // }}
        >
          {route.breadcrumbName}
        </Link>
      ) : (
        <span>{route.breadcrumbName}</span>
      );
      // const last = routes.indexOf(route) === routes.length - 1;
      // return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
    };
    // console.log(this.isAuthorized());
    return (
      <ConfigProvider locale={deDE}>
        <ProLayout
          {...config.routesConfig}
          logo={currentUser.organisation === '61b0792cbddf5b00316acbaa' ? nLightenLogo : logo}
          //logo={nLightenLogo}
          location={{ pathname }}
          onMenuHeaderClick={() => this.props.history.push('/')}
          //menuFooterRender={menuFooterRender}
          //Check items before render
          menuDataRender={menuDataRender}
          menuItemRender={menuItemRender}
          itemRender={itemRender}
          breadcrumbRender={(routers = []) => {
            if (routers[0] && routers[0].path === '/home') {
              return [...routers];
            } else {
              if (this.props.project && routers[routers.length - 1].breadcrumbName !== this.props.project.name) {
                routers.push({ breadcrumbName: this.props.project.name });
              }

              return [
                {
                  path: '/',
                  breadcrumbName: 'Home',
                },
                ...routers,
              ];
            }
          }}
          rightContentRender={() => <RightContent history={this.props.history} />}
          //Get all menuItems
          // postMenuData={(menuData) => {
          //   this.menuDataRef.current = menuData || [];
          //   return menuData || [];
          // }}
          {...settings}
          title={currentUser.organisation === '61b0792cbddf5b00316acbaa' ? '' : config.app.title}
          className={theme}
          fixSiderbar={true}
          links={[
            <a href="https://bimension.de" target="_blank" rel="noreferrer">
              <GlobalOutlined />
              <span>BIMension GmbH</span>
            </a>,
            <a href="mailto:support@bimension.de?subject=Anfrage%20über%20BIMforge">
              <MailOutlined />
              <span>support@bimension.de</span>
            </a>,
          ]}
          // footerRender={() => (
          //   <DefaultFooter
          //     links={[
          //       { key: 'test', title: 'layout', href: 'www.alipay.com' },
          //       { key: 'test2', title: 'layout2', href: 'www.alipay.com' },
          //     ]}
          //     copyright="这是一条测试文案"
          //   />
          // )}
        >
          {this.isAuthorized() !== undefined && (this.isAuthorized() ? children : <Redirect to="/403" />)}
          {currentUser.role === 'root' && (
            <SettingDrawer
              pathname={pathname}
              getContainer={() => document.getElementById('root')}
              settings={settings}
              onSettingChange={(changeSetting) => this.setSettings(changeSetting)}
            />
          )}
        </ProLayout>
      </ConfigProvider>
    );
  }
}

// Check if User is Logged In or if login is in process
export default class CoreLayoutRoute extends React.Component {
  render() {
    const { component: Component, isProcessing, isProcessed, isSignedIn, ...rest } = this.props;
    if (isProcessing || !isProcessed) {
      return <PageLoading />;
    }

    /*
        return(
      <HelmetProvider>
      <Helmet>
        <title>{config.app.title}</title>
        <meta name="description" content={config.app.desc} />
      </Helmet>
      <CoreLayout {...rest} history={matchProps.history}>
                <Component {...matchProps} />
              </CoreLayout>
    </HelmetProvider>
    )
    */

    return (
      <Route
        {...rest}
        render={(matchProps) =>
          isSignedIn ? (
            <CoreLayout {...rest} history={matchProps.history}>
              <Component {...matchProps} />
            </CoreLayout>
          ) : (
            <Redirect to="/user/signin" />
          )
        }
      />
    );
  }
}
