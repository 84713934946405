import React from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Button, Tooltip } from 'antd';
import merge from 'lodash/merge';
import find from 'lodash/find';
import easing from 'easing-js';

import { ReactLoader, Loader } from '../../../components/Loader';
import Panel from '../../../components/Panel';
import Stopwatch from '../../../components/Stopwatch';
import BaseComponent from '../../../components/BaseComponent';
import Viewer from '../Viewer';

import ServiceManager from '../../../services/SvcManager';
import config from '../../../config';

import './Viewer.Configurator.less';

export default class ViewerConfigurator extends BaseComponent {
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  constructor(props) {
    super(props);

    this.eventSvc = ServiceManager.getService('EventSvc');
    this.state = {
      dataExtension: null,
      viewerPanels: [],
      viewerFlex: 1.0,
      resizing: false,
      controls: [],
      sync: false,
    };

    this.appContainerRef = React.createRef(this);
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  async componentDidMount() {
    //TODO
    // See Forge VSCode Tools
    // Extract Manifest and ask for everything before init
    if (this.props.showLoader) {
      this.loader = new Loader(this.loaderContainer);
      this.loader.show(true);
    }
    await this.loadViewerStyle();
    await this.loadViewerScript();
    await this.props.getModel(this.props.modelId);

    window.addEventListener('resize', this.onStopResize);
    window.addEventListener('resize', this.onResize);
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  componentWillUnmount() {
    this.props.unloadViewerPage();
    window.removeEventListener('resize', this.onStopResize);
    window.removeEventListener('resize', this.onResize);
  }

  loadViewerStyle() {
    return new Promise((resolve, reject) => {
      if (!window.Autodesk) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = config.viewer.css;
        link.onload = () => resolve();
        document.head.append(link);
      } else {
        resolve();
      }
    });
  }
  loadViewerScript() {
    return new Promise((resolve, reject) => {
      if (!window.Autodesk) {
        const script = document.createElement('script');
        script.src = config.viewer.js;
        script.onload = () => resolve();
        document.head.append(script);
      } else {
        resolve();
      }
    });
  }

  loadDefaultExtensions() {
    return new Promise(async (resolve, reject) => {
      console.log(this.props.defaultExtensions);
      if (this.props.defaultExtensions) {
        this.props.defaultExtensions.map((ext) => {
          return this.viewer.loadExtension(ext);
        });
      }
      this.viewer.loadExtension('Autodesk.Vault.Markups');
      this.viewer.loadExtension('Autodesk.Vault.Print');
      this.viewer.loadExtension('CameraRotation');
      // this.viewer.loadExtension('Autodesk.Viewing.MemoryLimitedDebug');
      // this.viewer.loadExtension('Autodesk.Viewing.MarkupsCore');
      // this.viewer.loadExtension('Autodesk.Viewing.MarkupsGui');
      // this.viewer.loadExtension('Autodesk.Viewing.ZoomWindow');
      // var config = {
      //   availableDiffModes: ['overlay', 'sidebyside'],
      //   diffModels: [],
      //   primaryModels: [],
      //   mimeType: 'application/vnd.autodesk.revit',
      //   diffadp: false,
      //   diffMode: 'overlay',
      //   versionA: 'A',
      //   versionB: 'B',
      // };

      // this.viewer.loadExtension('Autodesk.DiffTool', config);

      resolve();
    });
  }
  /* ========================================================================== */
  /*                          Load a document from URN                          */
  /* ========================================================================== */

  loadDocument(urn) {
    return new Promise((resolve, reject) => {
      const paramUrn = !urn.startsWith('urn:') ? 'urn:' + urn : urn;

      window.Autodesk.Viewing.Document.load(
        paramUrn,
        (doc) => {
          //get root document
          this.bubbleRoot = doc.getRoot();
          //search for viewable
          const viewables = this.bubbleRoot.search({
            type: 'geometry',
          });
          // if (viewables.length > 1) {
          //   this.viewer.loadExtension('Autodesk.DocumentBrowser').then((ext) => {});
          // }
          //onSuccess
          doc.downloadAecModelData((data) => {
            if (data !== undefined) {
              this.viewer.loadExtension('Autodesk.AEC.LevelsExtension');
              this.viewer.loadExtension('Autodesk.AEC.Minimap3DExtension');
              this.viewer.loadExtension('Autodesk.AEC.HyperlinkExtension');
            }
          });
          //Availabe via Viewer Settings
          // this.viewer.loadExtension('Autodesk.NPR');
          // this.viewer.loadExtension('Autodesk.ProfileUi');
          // this.viewer.loadExtension('Autodesk.MemoryLimited');
          // this.viewer.loadExtension('Autodesk.DOF');
          // this.viewer.loadExtension('Autodesk.VisualClusters');
          //this.viewer.loadExtension('Autodesk.Viewing.MemoryLimitedDebug');

          resolve(doc);
        },
        (error) => {
          //onError
          reject(error);
        },
      );
    });
  }

  /////////////////////////////////////////////////////////
  // Return viewable path: first 3d or 2d item by default
  //
  /////////////////////////////////////////////////////////

  // getViewablePath(
  //   doc,
  //   pathIdx = 0,
  //   query = [
  //     { type: "geometry", role: "3d" },
  //     { type: "geometry", role: "2d" },
  //   ]
  // ) {
  //   const toArray = (obj) => {
  //     return obj ? (Array.isArray(obj) ? obj : [obj]) : [];
  //   };

  //   //V6
  //   // const rootItem = doc.getRootItem();
  //   const rootItem = doc.getRoot();
  //   this._rootItem = rootItem;

  //   let items = [];
  //   toArray(query).forEach((queryItem) => {
  //     items = [...items, ...rootItem.search(queryItem)];
  //   });

  //   if (!items.length || pathIdx > items.length - 1) {
  //     return null;
  //   }

  //   return doc.getViewablePath(items[pathIdx]);
  // }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  loadDynamicExtension(viewer, extension, options) {
    return new Promise((resolve, reject) => {
      var ext = viewer.getExtension(extension.id);

      if (ext) {
        if (ext.reload) {
          ext.reload(options);
        }

        return resolve(ext);
      }
      import('../Viewer.Extensions.Dynamic/' + extension.id + '/index').then(
        (ext) => {
          const extState = {
            [extension.id]: {},
          };

          this.assignState(extState).then(() => {
            viewer.loadExtension(extension.id, options).then(
              (extInstance) => {
                this.eventSvc.emit('extension.loaded', {
                  extension: extInstance,
                });

                return resolve(extInstance);
              },
              (err) => {
                reject('Failed to load extension: ' + extension.id);
              },
            );
          });
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  pushRenderExtension = (extension) => {
    return new Promise(async (resolve) => {
      const layout = this.props.dbModel.layout;

      this.viewerFlex = layout ? 1.0 - (layout.flex || 0.3) : 1.0;

      // find loaded extension to make the "sync view" button visible
      const matterportExt = find(extension.options.extensions, {
        id: 'Viewing.Extension.Matterport',
      });
      await this.assignState({
        paneExtStyle: { display: 'block' },
        buttonExtStyle: { display: 'none' },
      });

      await this.runAnimation(1.0, this.viewerFlex, 1.0);
      setTimeout(() => {
        this.assignState({
          renderExtension: extension,
        }).then(() => {
          resolve();
        });
      }, 250);
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  popRenderExtension = () => {
    return new Promise((resolve) => {
      this.assignState({
        renderExtension: null,
      }).then(() => {
        resolve();
      });

      setTimeout(async () => {
        await this.runAnimation(this.viewerFlex, 1.0, 1.0);

        await this.assignState({
          paneExtStyle: { display: 'none' },
          buttonExtStyle: { display: 'none' },
        });

        resolve();
      }, 250);
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  pushViewerPanel = (viewer) => {
    return (renderable, opts = {}) => {
      const nbPanels = this.state.viewerPanels.length;

      const panelId = renderable.id;

      const props = Object.assign(
        {
          left: 10 + 50 * nbPanels,
          top: 10 + 55 * nbPanels,
        },
        opts,
        {
          container: viewer.container,
          id: panelId,
          renderable,
          react: {
            setState: (state) => {
              return new Promise((resolve) => {
                const panelState = this.state[panelId] || {};

                const newPanelState = {
                  [panelId]: Object.assign({}, panelState, state),
                };

                this.assignState(newPanelState).then(() => {
                  resolve(newPanelState);
                });
              });
            },
            getState: () => {
              return this.state[panelId] || {};
            },
          },
        },
      );

      return new Promise((resolve) => {
        const panel = new Panel(props);

        this.assignState({
          viewerPanels: [...this.state.viewerPanels, panel],
        }).then(() => {
          resolve(panel);
        });
      });
    };
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  popViewerPanel = (panelId) => {
    return new Promise((resolve) => {
      const targetPanel = find(this.state.viewerPanels, {
        id: panelId,
      });

      targetPanel
        ? targetPanel.destroy().then(() => {
            const viewerPanels = this.state.viewerPanels.filter((panel) => {
              return panel.id !== panelId;
            });

            this.assignState({
              viewerPanels,
            });
            resolve();
          })
        : resolve();
    });
  };

  setReflexSplitterButton = (style, props) => {
    return new Promise(async (resolve) => {
      await this.assignState({
        buttonExtStyle: style,
        buttonProps: props,
      });
      resolve();
    });
  };
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  setupDynamicExtensions(viewer) {
    const defaultOptions = {
      appContainer: this.appContainerRef,
      loadDocument: this.loadDocument,
      model: this.props.dbModel.model,
      dbModel: this.props.dbModel,
      parentControl: this.ctrlGroup,
      loader: this.loader,
      apiUrl: '/api',
    };

    const createDefaultOptions = (id) => {
      const fullDefaultOptions = Object.assign({}, defaultOptions, {
        react: {
          // formatMessage: this.context.intl.formatMessage,

          pushRenderExtension: this.pushRenderExtension,

          pushViewerPanel: this.pushViewerPanel(viewer),

          popRenderExtension: this.popRenderExtension,

          popViewerPanel: this.popViewerPanel,

          forceUpdate: () => {
            return new Promise((resolve) => {
              this.forceUpdate(() => {
                resolve();
              });
            });
          },
          getComponent: () => {
            return this;
          },
          setReflexSplitterButton: this.setReflexSplitterButton,
          getState: () => {
            return this.state[id] || {};
          },
          setState: (state, doMerge) => {
            return new Promise((resolve) => {
              const extState = this.state[id] || {};

              const newExtState = {
                [id]: doMerge ? merge({}, extState, state) : Object.assign({}, extState, state),
              };

              this.assignState(newExtState).then(() => {
                resolve(newExtState);
              });
            });
          },
          props: this.props,
        },
      });

      return fullDefaultOptions;
    };

    viewer.loadDynamicExtension = (id, options = {}) => {
      const fullOptions = merge(
        {},
        createDefaultOptions(id),
        {
          viewerDocument: this.viewerDocument,
          eventSink: this.eventSvc,
        },
        options,
      );

      return this.loadDynamicExtension(viewer, { id }, fullOptions);
    };

    const dynamicExtensions = this.props.dbModel.dynamicExtensions || [];
    const extensionTasks = dynamicExtensions.map((extension) => {
      return viewer.loadDynamicExtension(extension.id, extension.options);
    });

    return Promise.all(extensionTasks);
  }
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  runAnimation(start, end, animPeriod) {
    const easingFn = (t) => {
      //b: begging value, c: change in value, d: duration
      return easing.easeInOutExpo(t, 0, 1.0, animPeriod * 0.9);
    };

    const update = (eased) => {
      const viewerFlex = (1.0 - eased) * start + eased * end;

      return new Promise((resolve) => {
        this.assignState({
          viewerFlex,
        }).then(() => resolve());
      });
    };

    return this.animate(animPeriod, easingFn, update);
  }
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  animate(period, easing, update) {
    return new Promise((resolve) => {
      const stopwatch = new Stopwatch();

      let elapsed = 0;

      const stepFn = () => {
        const dt = stopwatch.getElapsedMs() * 0.001;

        elapsed += dt;

        if (elapsed < period) {
          const eased = easing(elapsed / period);

          update(eased).then(() => {
            window.requestAnimationFrame(stepFn);
          });
        } else {
          update(1.0);

          resolve();
        }
      };

      stepFn();
    });
  }

  /* ========================================================================== */
  /*                                create toolbar                              */
  /* ========================================================================== */

  createToolbar(viewer) {
    let toolbarContainer = document.createElement('div');

    toolbarContainer.className = 'configurator-toolbar';

    viewer.container.appendChild(toolbarContainer);

    const toolbar = new window.Autodesk.Viewing.UI.ToolBar(true);

    const ctrlGroup = new window.Autodesk.Viewing.UI.ControlGroup('configurator');

    toolbar.addControl(ctrlGroup);

    toolbarContainer.appendChild(toolbar.container);

    return ctrlGroup;
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  buildTransform(transform = {}) {
    const matrix = new window.THREE.Matrix4();

    const position = new window.THREE.Vector3();

    position.fromArray(transform.position || [0, 0, 0]);

    const euler = new window.THREE.Euler(0, 0, 0, 'XYZ');

    euler.fromArray(transform.euler || [0, 0, 0]);

    const quaternion = new window.THREE.Quaternion();

    quaternion.setFromEuler(euler);

    const scale = new window.THREE.Vector3();

    scale.fromArray(transform.scale || [1, 1, 1]);

    matrix.compose(position, quaternion, scale);

    return matrix;
  }

  /* ========================================================================== */
  /*                          Callback Toolbar                                  */
  /* ========================================================================== */

  onToolbarCreated = (event) => {
    if (event.target.toolbar.getControl('modelTools') != null && !this.modelsTools) {
      this.modelTools = event.target.toolbar.getControl('modelTools');
      this.modelTools.addEventListener(window.Autodesk.Viewing.UI.ControlGroup.Event.CONTROL_ADDED, this.onModelToolsCtrlAdded);
    }
    // if (event.target.toolbar.getControl('print-toolbar') && !this.printTools) {
    //   this.printTools = event.target.toolbar.getControl('print-toolbar');
    //   this.printTools.addEventListener(window.Autodesk.Viewing.UI.ControlGroup.Event.CONTROL_ADDED, this.onPrintToolsCtrlAdded);
    //   // console.log(this.printTools);
    //   // this.ctrlGroup.addControl(this.printTools._controls[0]);
    // } else {
    //   setTimeout(() => {
    //     this.onToolbarCreated(event);
    //   }, 400);
    // }
    // if (event.target.toolbar.getControl('ExtensionsPanelToolbar') && !this.extensionTools) {
    //   this.extensionTools = event.target.toolbar.getControl('ExtensionsPanelToolbar');
    // } else {
    //   setTimeout(() => {
    //     this.onToolbarCreated(event);
    //   }, 400);
    // }
  };

  onPrintToolsCtrlAdded = (event) => {
    console.log(event.control._id);
  };

  onModelToolsCtrlAdded = (event) => {
    return;
    switch (event.control._id) {
      case 'toolbar-clusterTool':
        this.assignState({
          controls: [...this.state.controls, event.control],
        });
        break;
      case 'toolbar-documentModels':
        this.assignState({
          controls: [...this.state.controls, event.control],
        });
        break;
      case 'toolbar-levelsTool':
        this.assignState({
          controls: [...this.state.controls, event.control],
        });
        break;
      // This is the last tool which is added to the toolbar
      // Perfom the action here, to avoid buggs
      case 'toolbar-measurementSubmenuTool':
        this.state.controls.map((control) => {
          this.modelTools.removeControl(control);
          this.ctrlGroup.addControl(control);
        });
        break;
      case 'toolbar-collaborateTool':
        this.modelTools.removeControl(event.control);
        this.ctrlGroup.addControl(event.control);
        break;
      case 'toolbar-markup-tool':
        this.modelTools.removeControl(event.control);
        this.ctrlGroup.addControl(event.control);
      case 'toolbar-printTool':
        this.assignState({
          controls: [...this.state.controls, event.control],
        });
        break;
      default:
        break;
    }
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  onModelRootLoaded = (event) => {
    const viewer = event.target;
    // viewer.removeEventListener(
    //   window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
    //   this.onModelRootLoaded
    // );
    const nav = viewer.navigation;
    nav.toPerspective();
    this.loadDefaultExtensions();
    viewer.autocam.setHomeViewFrom(nav.getCamera());
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  onGeometryLoaded = (event) => {
    const viewer = event.target;
    // viewer.removeEventListener(
    //   window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
    //   this.onGeometryLoaded
    // );
    // console.log('ON GEOMETRIE LOADED');
    // this.loadDefaultExtensions();

    setTimeout(() => {
      if (viewer.viewCubeUi) {
        viewer.showViewCubeTriad(true);
      }
    }, 2000);
  };

  onLayerLoaded = (event) => {
    // const viewables = this.bubbleRoot.search({
    //   type: "geometry",
    //   role: "3d",
    // });
    // console.log("_____________________________________");
    // console.log(viewables);
  };
  async getRegisteredExtensions(viewer) {
    setTimeout(() => {
      let registeredExtns = window.Autodesk.Viewing.theExtensionManager.getRegisteredExtensions();
    }, 5000);
  }
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  async onViewerCreated(viewer, modelInfo) {
    try {
      const { viewerState, showLoader } = this.props;

      this.loader = new Loader(viewer.container);
      this.loader.show(showLoader);

      if (this.props.onViewerCreated) {
        this.props.onViewerCreated(viewer, this.loader);
      }
      this.viewer = viewer;
      viewer.start();
      // smooth navigation...
      // viewer.autocam.shotParams.destinationPercent = 3;
      // viewer.autocam.shotParams.duration = 3;

      // VIewer Init Events
      this.ctrlGroup = this.createToolbar(viewer);

      viewer.addEventListener(window.Autodesk.Viewing.TOOLBAR_CREATED_EVENT, this.onToolbarCreated);
      viewer.addEventListener(window.window.Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, this.onModelRootLoaded);
      // viewer.addEventListener(
      //   window.window.Autodesk.Viewing.EXTENSION_LOADED_EVENT,
      //   this.onExtensionLoaded
      // );
      viewer.addEventListener(window.Autodesk.Viewing.MODEL_LAYERS_LOADED_EVENT, this.onLayerLoaded);
      viewer.addEventListener(window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.onGeometryLoaded);
      //Set User VIewers Profile
      // And auto load defined extensions
      // if (viewerState.profile) {
      //   viewer.setProfile(viewerState.profile);
      // } else {
      //   const profile = new window.Autodesk.Viewing.Profile(window.Autodesk.Viewing.ProfileSettings.AEC);
      //   viewer.setProfile(profile);
      // }
      viewer.prefs.tag('ignore-producer');

      await this.setupDynamicExtensions(viewer);
      // await this.loadDefaultExtensions();

      this.getRegisteredExtensions(viewer);

      if (modelInfo) {
        // if (!this.props.dbModel.svf2) {
        // }
        const lmvProxy = modelInfo.proxy || 'lmv-proxy-2legged';

        //TODO: api endpoint: 'streamingV2', // or 'streamingV2_EU' if in EMEA region
        window.Autodesk.Viewing.endpoint.setEndpointAndApi(`${window.location.origin}/api/forge/${lmvProxy}`, 'derivativeV2');

        switch (this.props.dbModel.env) {
          case 'Local':
            const localOptions = {
              placementTransform: this.buildTransform(modelInfo.transform),
            };

            viewer.loadModel(modelInfo.path, localOptions, (model) => {
              model.name = modelInfo.displayName || modelInfo.name;
              model.dbModelId = this.props.dbModel._id;
              model.urn = modelInfo.urn;
              model.guid = this.guid();

              viewer.activeModel = model;

              viewer.unloadExtension('Autodesk.DocumentBrowser');
              viewer.unloadExtension('Autodesk.AEC.LevelsExtension');
              viewer.unloadExtension('Autodesk.AEC.Minimap3DExtension');

              this.eventSvc.emit('model.loaded', {
                model,
              });
            });

            // ToDo this way i need i download the manifest during downloading the svf and save it
            // then i can access aec data

            // window.Autodesk.Viewing.endpoint.getItemApi=(endpoint, derivativeUrn, api)=>{
            //   return modelInfo.path +decodeURIComponent( derivativeUrn.split('Resource')[1])
            //   }
            // this.viewerDocument = new window.Autodesk.Viewing.Document(manifestJsonObject);
            // this.viewerDocument.downloadAecModelData()
            // const defaultModel = this.viewerDocument
            // .getRoot()
            // .getDefaultGeometry();
            // await viewer.loadDocumentNode(this.viewerDocument, defaultModel)
            // this.eventSvc.emit("model.loaded", {
            //   defaultModel,
            // });

            break;

          case 'AutodeskProduction':
            const urn = modelInfo.urn;
            //load document
            this.viewerDocument = await this.loadDocument(urn);
            //load default Model
            const defaultModel = this.bubbleRoot.getDefaultGeometry();
            await viewer.loadDocumentNode(this.viewerDocument, defaultModel);

            this.eventSvc.emit('model.loaded', {
              defaultModel,
            });
            // const query = modelInfo.query || [
            //   { type: "geometry", role: "3d" },
            //   { type: "geometry", role: "2d" },
            // ];

            // const path = this.getViewablePath(
            //   this.viewerDocument,
            //   modelInfo.pathIndex || 0,
            //   query
            // );

            // viewer.loadModel(path, (model) => {
            //   model.name = modelInfo.displayName || modelInfo.name;
            //   model.dbModelId = this.props.dbModel._id;
            //   model.urn = modelInfo.urn;
            //   model.guid = this.guid();

            //   viewer.activeModel = model;

            //   this.eventSvc.emit("model.loaded", {
            //     model,
            //   });
            // });

            // //V7
            // const loadOptions = {
            //   sharedPropertyDbPath: this.viewerDocument.getFullPath(
            //     this._rootItem.findPropertyDbPath()
            //   ),
            //   placementTransform: this.buildTransform(modelInfo.transform),
            // };
            // viewer.loadModel(path, loadOptions, (model) => {
            //   model.name = modelInfo.displayName || modelInfo.name;
            //   model.dbModelId = this.props.dbModel._id;
            //   model.urn = modelInfo.urn;
            //   model.guid = this.guid();

            //   viewer.activeModel = model;

            //   this.eventSvc.emit("model.loaded", {
            //     model,
            //   });
            // });
            break;
          default:
            break;
        }
      }
    } catch (ex) {
      console.log('Viewer Initialization Error: ');
      console.log(ex);
      if (this.props.onError) {
        this.props.onError('Viewer Initialization Error: ' + this.getError(ex));
      }
    }
  }
  getError(ex) {
    var errorCodes = {
      1: 'An unknown failure has occurred.',
      2: 'Bad data (corrupted or malformed) was encountered.',
      3: 'A network failure was encountered.',
      4: 'Access was denied to a network resource (HTTP 403)',
      5: 'A network resource could not be found (HTTP 404)',
      6: 'A server error was returned when accessing a network resource (HTTP 5xx)',
      7: 'An unhandled response code was returned when accessing a network resource (HTTP "everything else")',
      8: 'Browser error: webGL is not supported by the current browser',
      9: 'There is nothing viewable in the fetched document',
      10: 'Browser error: webGL is supported, but not enabled',
      11: 'There is no geomtry in loaded model',
      12: 'Collaboration server error',
    };
    const values = Object.values(errorCodes);
    return values[ex];
  }

  ////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  onViewingApplicationCreated(viewingApp) {}

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  guid(format = 'xxxxxxxxxxxx') {
    var d = new Date().getTime();

    var guid = format.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });

    return guid;
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  renderLoader() {
    return <div className="configurator-loader" ref={(div) => (this.loaderContainer = div)} />;
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  renderExtension() {
    const { renderExtension } = this.state;

    const renderOptions = {
      showTitle: true,
      docked: true,
    };

    const content = renderExtension ? this.state.renderExtension.render(renderOptions) : <div />;

    return (
      <div className="data-pane">
        <ReactLoader show={!renderExtension} />
        {content}
      </div>
    );
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  renderModel(dbModel) {
    const { resizing } = this.state;

    const viewerStyle = {
      pointerEvents: resizing ? 'none' : 'all',
    };
    const modelInfo = dbModel.model;
    return (
      <Viewer
        dbModel={dbModel}
        onViewerCreated={(viewer) => {
          this.onViewerCreated(viewer, modelInfo);
        }}
        onError={this.props.onError}
        panels={this.state.viewerPanels}
        viewerEnv={this.props.viewerEnv}
        setViewerEnv={this.props.setViewerEnv}
        style={viewerStyle}
      />
    );
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  onViewerStartResize = (e) => {
    this.assignState({
      resizing: true,
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  onViewerStopResize = (e) => {
    this.viewerFlex = e.component.props.flex;

    if (this.state.renderExtension) {
      if (this.state.renderExtension.onStopResize) {
        this.state.renderExtension.onStopResize();
      }
    }

    this.assignState({
      resizing: false,
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  onStopResize = (e) => {
    if (this.state.renderExtension) {
      if (this.state.renderExtension.onStopResize) {
        this.state.renderExtension.onStopResize();
      }
    }
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  onResize = (event) => {
    if (this.state.renderExtension) {
      if (this.state.renderExtension.onResize) {
        this.state.renderExtension.onResize();
      }
    }
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  render() {
    const { viewerFlex, paneExtStyle, buttonExtStyle, buttonProps } = this.state;
    const { dbModel } = this.props;
    // Error Hanldler for download dbModel
    if (this.props.error) {
      if (this.props.onError) {
        this.props.onError(this.props.error);
      }
    }
    if (!dbModel) {
      return this.renderLoader();
    }
    // console.log(this.props.location);
    if (this.props.location.pathname === `/viewer/${dbModel.id}` && !dbModel.public) {
      if (this.props.onError) {
        this.props.onError('Das Modell ist nicht öffenlich zugänglich');
      }
    }
    const layout = dbModel.layout;

    switch (layout ? layout.type : 'none') {
      case 'flexLayoutLeft':
        return (
          <ReflexContainer className="configurator" key="configurator" orientation="vertical">
            <ReflexElement style={paneExtStyle}>{this.renderExtension()}</ReflexElement>
            <ReflexSplitter onStopResize={() => this.forceUpdate()} style={paneExtStyle}>
              Test
            </ReflexSplitter>
            <ReflexElement
              onStartResize={this.onViewerStartResize}
              onStopResize={this.onViewerStopResize}
              propagateDimensions={true}
              onResize={this.onResize}
              flex={viewerFlex}
            >
              {this.renderModel(dbModel)}
            </ReflexElement>
          </ReflexContainer>
        );

      case 'flexLayoutRight':
        return (
          <ReflexContainer className="configurator" key="configurator" orientation="vertical">
            <ReflexElement
              onStartResize={this.onViewerStartResize}
              onStopResize={this.onViewerStopResize}
              propagateDimensions={true}
              onResize={this.onResize}
              flex={viewerFlex}
            >
              {this.renderModel(dbModel)}
            </ReflexElement>
            <ReflexSplitter onStopResize={() => this.forceUpdate()} style={paneExtStyle}>
              <Tooltip title="Kameras synchronisieren">
                <Button
                  className="camera-sync-btn"
                  type={buttonProps ? buttonProps.type : 'default'}
                  shape={buttonProps ? buttonProps.shape : 'circle'}
                  size={buttonProps ? buttonProps.size : 'large'}
                  icon={buttonProps ? buttonProps.icon : null}
                  style={buttonExtStyle}
                  onClick={() => (buttonProps ? buttonProps.onClick() : null)}
                />
              </Tooltip>
            </ReflexSplitter>
            <ReflexElement style={paneExtStyle}>{this.renderExtension()}</ReflexElement>
          </ReflexContainer>
        );

      case 'none':
      default:
        return this.renderModel(dbModel);
    }
  }
}
