import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  notices: [],
  processing: false,
  processed: false,
  error: null,
};

const noticesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTICES:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.FETCH_NOTICES_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
        error: null,
        notices: action.payload,
      };
    case actionTypes.FETCH_NOTICES_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
        notices: [],
      };
    case actionTypes.UPDATE_NOTICE:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_NOTICE_SUCCESS:
      const updatedNotices = state.notices.map((notice) => (notice.id === action.payload.id ? action.payload : notice));
      return {
        ...state,
        notices: updatedNotices,
        processing: false,
        processed: true,
      };
    case actionTypes.UPDATE_NOTICE_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
      };
    case actionTypes.DELETE_NOTICES:
    case actionTypes.DELETE_NOTICES_SUCCESS:
      return {
        ...state,
        notices: [],
      };
    case actionTypes.DELETE_NOTICES_FAIL:
      return {
        ...state,
        processing: false,
        processed: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default noticesReducer;
