import React from 'react';

import { PageContainer } from '@ant-design/pro-layout';
import { Skeleton } from 'antd';

import MatterportConfigurator from '../../../viewer.components/Matterport/Matterport.Configurator';

import './Matterport.less';

export default class MatterportView extends React.Component {
  onViewerCreated = (sdk, loader) => {
    this.sdk = sdk;
    this.loader = loader;
    this.loader.show(false);
  };

  componentDidMount() {
    if (this.props.project == null) {
      this.props.getProject(this.props.match.params.projectId);
    } else {
      this.props.setActiveProject(this.props.project);
    }
  }

  render() {
    this.scanId = this.props.match.params.scanId;

    return (
      <div
        className="matterport-view"
        //style={{ height: 'calc(100vh - 127px)' }}
      >
        <PageContainer
          onBack={() => window.history.back()}
          title="BIMforge Viewer"
          subTitle={this.props.scan ? `> ${this.props.scan.name}` : <Skeleton />}
        >
          <MatterportConfigurator onViewerCreated={this.onViewerCreated} scanId={this.scanId} showLoader={true} />
        </PageContainer>
      </div>
    );
  }
}
