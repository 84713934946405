import React from 'react';
import { Button, Input, Typography, Tag, Card, List, Radio, Modal, Dropdown, Menu, Space, Alert, Divider, Result } from 'antd';
import { DownOutlined, PlusOutlined, ExclamationCircleOutlined, LockOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import Image from '../../../../../components/Image';
import ServiceManager from '../../../../../services/SvcManager';
import ModelModal from '../../Modal/ModelModal';

import { connect } from 'react-redux';
import { getModels, updateModel, unloadModelPage, deleteModel } from '../../../../../store/actions/models.actions';
import { getCurrentModels, getError, getProcessing, getProcessed } from '../../../../../store/selectors/models.selectors';
import config from '../../../../../config';

import './ProjectModels.less';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const { Search } = Input;
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

class ProjectModels extends React.Component {
  addBtn = React.createRef();

  constructor() {
    super();
    this.modelSvc = ServiceManager.getService('ModelSvc');
    moment.locale('de', momentDe);

    this.state = {
      visible: false,
      current: undefined,
    };
  }

  /* ========================================================================== */
  /*                              Lifecyle Methods                              */
  /* ========================================================================== */
  componentDidMount() {
    this.props.getModels(`?project=${this.props.projectId}&sort=-createdAt`);
  }
  componentWillUnmount() {
    this.props.unloadModelPage();
  }

  /* ========================================================================== */
  /*                               Get Thumbanils                               */
  /* ========================================================================== */
  getThumbnailUrl(item) {
    const thumbnailUrl = this.modelSvc.getThumbnailUrl(item.model.urn, 400);
    return thumbnailUrl;
  }

  showShareModal = (item) => {
    let title;
    let content;
    if (item.public) {
      title = 'Öffentlichen Link entfernen?';
      content = <p>Das Modell ist nicht mehr über den öffentlichen Link verfügbar.</p>;
    } else {
      title = 'Öffentlichen Link generieren?';
      content = (
        <>
          <p>Jeder mit diesem Link kann das Modell anschauen.</p>
          <Space />
          <Paragraph
            copyable={{
              text: `${config.server.publicUrl}/viewer/${item.id}`,
              tooltips: ['Link kopieren.', 'Link kopiert!'],
            }}
          >
            <Text code>
              {config.server.publicUrl}/viewer/{item.id}
            </Text>
          </Paragraph>
        </>
      );
    }
    const onOk = async () => {
      await this.props.updateModel(item.id, { public: !item.public });
      await this.props.getModels(`?project=${this.props.projectId}`);
    };

    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: content,
      okText: 'Bestätigen',
      cancelText: 'Abbrechen',
      onOk() {
        onOk();
      },
      onCancel() {},
    });
  };
  showDeleteModal = (item) => {
    const onOk = async () => {
      await this.props.deleteModel(item.id);
      await this.props.getModels(`?project=${this.props.projectId}`);
    };
    confirm({
      title: 'Modell löschen?',
      icon: <ExclamationCircleOutlined />,
      content: <p>Sind Sie sicher das Modell endgültig zu löschen?</p>,
      okText: 'Löschen',
      cancelText: 'Abbrechen',
      okButtonProps: { danger: true },
      onOk() {
        onOk();
      },
      onCancel() {},
    });
  };

  /* ========================================================================== */
  /*                               Paginator Props                              */
  /* ========================================================================== */
  get paginationProps() {
    const { models } = this.props;
    return {
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: 10,
      total: Array.isArray(models) ? models.length : 0,
    };
  }

  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  handleSubmit = async (formValues) => {
    const { current } = this.state;
    this.setState({
      visible: false,
    });
    await this.props.updateModel(current.id, formValues);
    await this.props.getModels(`?project=${this.props.projectId}`);
  };

  /* ========================================================================== */
  /*                                Extra Content                               */
  /* ========================================================================== */
  get extraContent() {
    return (
      <div className="extraContent">
        <RadioGroup defaultValue="all">
          <RadioButton value="all">All</RadioButton>
          <RadioButton value="progress">Progress</RadioButton>
          <RadioButton value="waiting">Waiting</RadioButton>
        </RadioGroup>
        <Search className="extraContentSearch" placeholder="Bitte eingeben..." onSearch={() => ({})} />
      </div>
    );
  }

  /* ========================================================================== */
  /*                              Upload new model                              */
  /* ========================================================================== */
  uploadNewModelClicked = () => {
    this.props.history.push(`/projects/active/${this.props.projectId}/modelUploader`);
  };

  render() {
    const { error, isProcessing, currentUser, models, project } = this.props;
    const { visible, current } = this.state;
    if (!project.default && (currentUser.subscription === 'test' || currentUser.subscription === 'basic')) {
      return (
        <Result
          icon={<LockOutlined />}
          title="Produkt nicht verfügbar"
          subTitle="Führen Sie ein Upgrad Ihrer Subscription durch um dieses Produkt nutzen zu können."
          extra={
            <Link to="">
              <Button type="primary">Support</Button>
            </Link>
          }
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Hinweis:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" /> Für dieses Produkt benötigen Sie die Subscription <a>Premium</a> oder
              höher.
            </Paragraph>
          </div>
        </Result>
      );
    }

    const MoreBtn = ({ key, disabled, item }) => (
      <Dropdown
        disabled={this.props.disabledActions}
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => this.showModal(item)}>
              Bearbeiten
            </Menu.Item>
            <Menu.Item key="share" onClick={() => this.showShareModal(item)}>
              Teilen
            </Menu.Item>
            <Menu.Item disabled key="download">
              Herunterladen
            </Menu.Item>
            <Menu.Item disabled={this.props.disabledActions} onClick={() => this.showDeleteModal(item)} key="delete" danger={true}>
              Löschen
            </Menu.Item>
          </Menu>
        }
      >
        <a>
          Mehr <DownOutlined />
        </a>
      </Dropdown>
    );
    return (
      <div className="standardList">
        <Card
          className="listCard"
          bordered={false}
          title="Modelle"
          style={{ marginTop: 24 }}
          //extra={this.extraContent}
        >
          <Button
            className="addNewModelButton"
            type="dashed"
            style={{ width: '100%', marginBottom: 8 }}
            onClick={this.uploadNewModelClicked}
            // ref={this.addBtn}
            disabled={this.props.disabledActions}
          >
            <PlusOutlined />
            Neues Modell hochladen...
          </Button>
          {error && (
            <>
              <Alert showIcon message="Fehler" description={this.props.error} type="error" />
              <Divider />
            </>
          )}
          <List
            size="large"
            rowKey="id"
            loading={isProcessing}
            pagination={this.paginationProps}
            dataSource={models !== null ? models : []}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Link to={item.public ? `/viewer/${item.id}` : `/projects/active/${this.props.projectId}/viewer/${item.id}`}>Anschauen</Link>,
                  <MoreBtn item={item} disabled={this.props.disabledActions} />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Image className="test" src={this.getThumbnailUrl(item)} width={100} shape="square" size="large" />}
                  title={
                    <>
                      <Link to={item.public ? `/viewer/${item.id}` : `/projects/active/${this.props.projectId}/viewer/${item.id}`}>{item.name}</Link>
                      <Divider type="vertical" />
                      {item.public && (
                        <>
                          <Tag color="blue">Öffentlich</Tag>
                          <Divider type="vertical" />
                          <Paragraph
                            copyable={{
                              text: `${config.server.publicUrl}/viewer/${item.id}`,
                              tooltips: ['Öffentlichen Link kopieren.', 'Link kopiert!'],
                            }}
                            style={{ display: 'inline-block' }}
                          />
                        </>
                      )}
                    </>
                  }
                  description={`Erstellt: ${moment(item.createdAt).format('DD.MM.YYYY HH:mm')}`}
                />
              </List.Item>
            )}
          />
        </Card>
        <ModelModal visible={visible} model={current} onCancel={this.handleCancel} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getModels,
  updateModel,
  unloadModelPage,
  deleteModel,
};
const mapStateToProps = (state) => {
  return {
    models: getCurrentModels(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectModels);
