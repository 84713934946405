import React from 'react';

import { Form, Alert, Button, Descriptions, Divider, Progress } from 'antd';

import './index.less';

export default class Step2 extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();
  }

  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */
  get formLayout() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 19,
      },
    };
  }

  render() {
    return (
      <Form {...this.formLayout} ref={this.formRef} layout="horizontal" className="stepForm">
        <Alert
          closable={false}
          showIcon
          message="Schließen Sie diese Seite nicht, solanger der Upload nicht beendet wurde."
          style={{ marginBottom: 24 }}
        />
        <Descriptions column={1}>
          <Descriptions.Item label="Modellname" name="fileName">
            {this.props.uploadData ? `${this.props.uploadData.options.data.name}` : 'nicht festgelegt'}
          </Descriptions.Item>
          <Divider style={{ margin: '24px 0' }} />
          <Descriptions.Item label="Dateiname">{this.props.uploadData ? `${this.props.uploadData.file.name}` : 'nicht festgelegt'}</Descriptions.Item>
          <Descriptions.Item label="Objekt Key">
            {this.props.uploadProgress ? `${this.props.uploadProgress.objectKey}` : 'nicht festgelegt'}
          </Descriptions.Item>
          <Descriptions.Item label="Upload Id">
            {this.props.uploadProgress ? `${this.props.uploadProgress.uploadId}` : 'nicht festgelegt'}
          </Descriptions.Item>
          <Descriptions.Item label="Größe">
            {this.props.uploadData ? `${(this.props.uploadData.file.size / 1000000).toFixed(2)} mb` : 'nicht festgelegt'}
          </Descriptions.Item>
          <Descriptions.Item label="Zuletzt bearbeitet">
            {this.props.uploadData ? `${this.props.uploadData.file.lastModifiedDate}` : 'nicht festgelegt'}
          </Descriptions.Item>
          <Descriptions.Item label="Fortschritt">
            <Progress status="active" percent={this.props.uploadProgress ? this.props.uploadProgress.progress : 0} />
          </Descriptions.Item>
        </Descriptions>
        <Divider style={{ margin: '24px 0' }} />

        <Form.Item
          style={{ marginBottom: 8 }}
          wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: {
              span: this.formLayout.wrapperCol.span,
              offset: this.formLayout.labelCol.span,
            },
          }}
        >
          <Button type="primary" disabled={true} loading={true}>
            Weiter
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
