import React, { Fragment } from 'react';
import { Button, Descriptions, Skeleton, Divider, Dropdown, Menu, Alert, Tag, message } from 'antd';
import { DownOutlined, EllipsisOutlined, DatabaseOutlined, DingtalkOutlined, ScanOutlined, RobotOutlined } from '@ant-design/icons';
import { PageContainer, RouteContext, PageLoading } from '@ant-design/pro-layout';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import './ProjectView.less';

import ProjectModal from '../Modal/ProjectModal';

import ProjectOverview from './Tabs/ProjectOverview';
import ProjectModels from './Tabs/ProjectModels';
import ProjectScans from './Tabs/ProjectScans';
import Dashforge from './Tabs/Dashforge';

export default class ProjectView extends React.Component {
  constructor() {
    super();

    moment.locale('de', momentDe);

    this.state = {
      err: false,
      visible: false,
      current: undefined,
      deletion: false,
      archive: false,
    };
  }

  /* ========================================================================== */
  /*                             Component Did Mount                            */
  /* ========================================================================== */

  componentDidMount() {
    if (this.props.project == null) {
      this.props.getProject(this.props.projectId);
    }
  }

  componentWillUnmount() {
    this.props.unloadProjectPage();
  }

  /* ========================================================================== */
  /*                                button handlers                             */
  /* ========================================================================== */

  showEditModal = (item) => {
    this.setState({
      current: item,
      visible: true,
    });
  };

  showDeleteModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      deletion: true,
    });
  };

  showArchiveModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      archive: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      deletion: false,
      archive: false,
    });
  };

  handleSubmit = (formValues) => {
    const { current } = this.state;
    if (current) {
      this.updateProject(current, formValues);
    }
    this.setState({
      visible: false,
      deletion: false,
      archive: false,
    });
  };

  handleDelete = () => {
    const { current } = this.state;
    this.deleteProject(current);
    this.setState({
      visible: false,
      deletion: false,
      archive: false,
    });
  };

  handleArchive = () => {
    const { current } = this.state;
    this.archiveProject(current);
    this.setState({
      visible: false,
      deletion: false,
      archive: false,
    });
  };

  archiveProject = async (item) => {
    const value = {
      archived: true,
    };
    await this.props.updateProject(item.id, value);
    if (!this.props.error) {
      message.success('Projekt wird archiviert...');
      setTimeout(() => {
        this.props.history.push('/projects/active');
      }, 1000);
    }
  };

  deleteProject = async (item) => {
    await this.props.deleteProject(item.id);
    if (!this.props.error) {
      message.success('Projekt wird gelöscht...');
      setTimeout(() => {
        this.props.history.push('/projects/active');
      }, 1000);
    }
  };

  updateProject = async (item, formValues) => {
    await this.props.updateProject(item.id, formValues);
  };

  /* ========================================================================== */
  /*                            Page Header Settings                            */
  /* ========================================================================== */

  get extra() {
    const { project } = this.props;

    return (
      <div className="extraImg">
        <img alt="img" className="cardAvatar" src={`data:image/png;base64,${project.cover}`} />
      </div>
    );
    // const { auth } = this.props;
    // const { activeProject, user } = auth;
    // return (
    //   <div className="moreInfo">
    //     <Statistic
    //       title="Aktive Modelle"
    //       loading={activeProject ? (activeProject ? false : true) : true}
    //       value={
    //         activeProject
    //           ? Array.isArray(activeProject.models)
    //             ? activeProject.models.length
    //             : 0
    //           : 0
    //       }
    //     />
    //     <Statistic
    //       title="Verfügbare Uploads"
    //       loading={user ? (user.allowedUploads ? false : true) : true}
    //       value={user ? (user.allowedUploads ? user.allowedUploads : 0) : 0}
    //     />
    //   </div>
    // );
  }

  getActions = (project) => {
    const { currentUser } = this.props;
    var disabled = false;
    if (project.default) {
      if (currentUser.role !== 'root') {
        disabled = true;
      }
    }
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => this.props.setActiveTab('1')}>
          Task-Forge
        </Menu.Item>
        <Menu.Item key="2" onClick={() => this.props.setActiveTab('2')}>
          Twin-Forge
        </Menu.Item>
        <Menu.Item key="3" onClick={() => this.props.setActiveTab('3')}>
          Dash-Forge
        </Menu.Item>
        <Menu.Item key="4" onClick={() => this.showArchiveModal(project)}>
          Archivieren
        </Menu.Item>
        <Menu.Item key="5" danger={true} type="primary" onClick={() => this.showDeleteModal(project)}>
          Löschen
        </Menu.Item>
      </Menu>
    );
    const mobileMenu = (
      <Menu>
        <Menu.Item key="1">Task-Forge</Menu.Item>
        <Menu.Item key="2">Twin-Forge</Menu.Item>
        <Menu.Item key="3">Dash-Forge</Menu.Item>
        <Menu.Item key="3">Archivieren</Menu.Item>
        <Menu.Item key="5" danger={true} type="primary">
          Löschen
        </Menu.Item>
      </Menu>
    );

    return (
      <Fragment>
        <Button.Group>
          <Button type="primary" onClick={() => this.showEditModal(project)} disabled={disabled}>
            Bearbeiten
          </Button>
          <Dropdown overlay={menu} placement="bottomRight" disabled={disabled}>
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </Button.Group>
      </Fragment>
    );

    return (
      <RouteContext.Consumer>
        {({ isMobile }) => {
          if (isMobile) {
            return (
              <Dropdown.Button
                type="primary"
                icon={<DownOutlined />}
                overlay={mobileMenu}
                placement="bottomRight"
                onClick={() => this.showEditModal(project)}
                disabled={disabled}
              >
                Bearbeiten
              </Dropdown.Button>
            );
          }
          return (
            <Fragment>
              <Button.Group>
                <Button type="primary" onClick={() => this.showEditModal(project)} disabled={disabled}>
                  Bearbeiten
                </Button>
                <Dropdown overlay={menu} placement="bottomRight" disabled={disabled}>
                  <Button>
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              </Button.Group>
            </Fragment>
          );
        }}
      </RouteContext.Consumer>
    );
  };

  getDescription = (project) => {
    if (!project) {
      return <Skeleton avatar paragraph={{ rows: 1 }} active />;
    }
    return (
      <RouteContext.Consumer>
        {({ isMobile }) => {
          return (
            <Descriptions className="headerList" size="small" column={isMobile ? 1 : 2}>
              {project.company && <Descriptions.Item label="Firma">{project.company}</Descriptions.Item>}
              <Descriptions.Item label="Projekt erstellt">{moment(project.createdAt).fromNow()}</Descriptions.Item>
              <Descriptions.Item label="Adresse">
                {project.google ? (
                  <a href={project.google} target="_blank">
                    {project.address ? project.address : 'Nicht festgelegt'}
                  </a>
                ) : project.address ? (
                  project.address
                ) : (
                  'Nicht festgelegt'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Zuletzt bearbeitet">{project.updatedAt ? moment(project.updatedAt).fromNow() : 'Nie'}</Descriptions.Item>
              {project.website && (
                <Descriptions.Item label="Webseite">
                  <a href={project.website} target="_blank">
                    {project.website}
                  </a>
                </Descriptions.Item>
              )}
            </Descriptions>
          );
        }}
      </RouteContext.Consumer>
    );
  };

  /* ========================================================================== */
  /*                           Page Container Tab List                          */
  /* ========================================================================== */

  get tabList() {
    return [
      {
        key: '0',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Übersicht</p>
            <Divider type="vertical" />
            <RobotOutlined />
          </div>
        ),
      },
      {
        key: '1',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Task-Forge</p>
            <Divider type="vertical" />
            <ScanOutlined />
          </div>
        ),
      },
      {
        key: '2',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Twin-Forge</p>
            <Divider type="vertical" />
            <DingtalkOutlined />
          </div>
        ),
      },
      {
        key: '3',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Dash-Forge</p>
            <Divider type="vertical" />
            <DatabaseOutlined />
          </div>
        ),
      },
      // {
      //   key: "3",
      //   tab: "Augmented Reality",
      // },
      // {
      //   key: '3',
      //   tab: 'Team',
      // },
    ];
  }

  /* ========================================================================== */
  /*                            get selected tab view                           */
  /* ========================================================================== */

  getCurrentView(currentTab, project) {
    const { currentUser } = this.props;
    let disabled;
    if (project.default) {
      if (currentUser.role === 'root') {
        disabled = false;
      } else {
        disabled = true;
      }
    } else {
      if (currentUser.role === 'admin' || currentUser.role === 'root') {
        disabled = false;
      } else {
        disabled = true;
      }
    }
    switch (currentTab) {
      case '0':
        return <ProjectOverview {...this.props} disabledActions={disabled} />;
      case '1':
        return <ProjectScans {...this.props} disabledActions={disabled} />;
      case '2':
        return <ProjectModels {...this.props} disabledActions={disabled} />;
      case '3':
        return <Dashforge {...this.props} />;
    }
  }

  getTags = (project) => {
    var tag = [];
    if (project.default) {
      tag.push(
        <Tag key="default" color="red">
          Beispielprojekt
        </Tag>,
      );
    } else {
      tag.push(
        <Tag key="active" color="blue">
          Aktiv
        </Tag>,
      );
    }
    return {
      tags: tag,
      // breadcrumbRender: (props, originBreadcrumb) => {
      //   // console.log(props);
      //   //console.log(originBreadcrumb);

      // if (originBreadcrumb && originBreadcrumb.props.routes[originBreadcrumb.props.routes.length - 1].breadcrumbName !== this.props.project.name) {
      //   originBreadcrumb.props.routes.push({ breadcrumbName: this.props.project.name });
      // }
      //   return originBreadcrumb;
      // },
    };
  };

  render() {
    const { project, error, isProcessing, activeTab } = this.props;
    const { visible, deletion, current, archive } = this.state;
    // const { tabIndex } = appState;
    if (project == null || isProcessing) {
      return <PageLoading />;
    }
    if (error) {
      return (
        <PageContainer
          onBack={() => {
            window.history.back();
          }}
        >
          <>
            <Alert showIcon message="Fehler" description={this.props.error} type="error" />
            <Divider />
          </>
        </PageContainer>
      );
    } else {
      if (project == null) return null;
      return (
        <PageContainer
          className="pageHeader"
          ghost={false}
          content={this.getDescription(project)}
          header={this.getTags(project)}
          extra={this.getActions(project)}
          tabList={this.tabList}
          extraContent={this.extra}
          title={project.name}
          subTitle={project.desc}
          tabActiveKey={activeTab}
          onTabChange={(key) => this.props.setActiveTab(key)}
          onBack={() => {
            window.history.back();
          }}
        >
          {this.getCurrentView(activeTab, project)}
          <ProjectModal
            visible={visible}
            deletion={deletion}
            archive={archive}
            current={current}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
            onArchive={this.handleArchive}
            {...this.props}
          />
        </PageContainer>
      );
    }
  }
}
