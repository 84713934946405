import React from 'react';
import { Modal, Form, Input, Upload, message, Select } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';

import { bimServerApi, errorHandler } from '../../../store/apis/bim.server.api';

const { Option } = Select;

export default class RootOrganisationModal extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      error: false,
    };
  }

  /* ========================================================================== */
  /*                                 upload cover                                 */
  /* ========================================================================== */

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  customRequest = async (option) => {
    const { file, onSuccess, onError } = option;
    const { current } = this.props;
    let formData = new FormData();
    formData.append('logo', file);
    return bimServerApi.post(`/api/organisations/${current.id}/logo`, formData, {}).then(
      (response) => {
        onSuccess();
      },
      (err) => {
        onError();
        errorHandler(err);
      },
    );
  };

  handleChange = ({ fileList }) => {
    if (fileList == []) {
      console.log('remove');
    }
    this.setState({ fileList });
  };
  handlePreviewCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    if (this.props.onSubmit) {
      delete formValues['logo'];
      Object.keys(formValues).map((key) => {
        if (formValues[key] === undefined) {
          delete formValues[key];
        }
      });
      console.log(formValues);
      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.setState({
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      error: false,
    });
  };

  normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */
  getModalContent() {
    const { current, deletion } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    let disabled;
    if (deletion) {
      disabled = true;
    }
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    var hasCover = false;
    var initialValues = {};
    if (current) {
      if (current.logo) {
        hasCover = true;
      }
      initialValues = {
        ['name']: current.name,
        ['slogan']: current.slogan,
        ['address']: current.address,
        ['google']: current.google,
        ['website']: current.website,
        ['allowedProjects']: current.allowedProjects,
        ['allowedUsers']: current.allowedUsers,
      };
    }
    return (
      <Form {...this.formLayout} ref={this.formRef} style={{ padding: 10 }} onFinish={this.handleFinish} initialValues={initialValues}>
        {current !== undefined && (
          <Form.Item name="logo" label="Logo">
            <Upload
              name="logo"
              className="cover-uploader"
              listType="picture-card"
              fileList={fileList}
              customRequest={this.customRequest}
              beforeUpload={this.beforeUpload}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              style={{ with: 128, height: 128 }}
              disabled={disabled}
            >
              {fileList.length == 1 ? null : hasCover ? (
                <img alt="example" style={{ width: '100%' }} src={`data:image/jpeg;base64,${current.logo}`} />
              ) : (
                uploadButton
              )}
            </Upload>
            {/* </ImgCrop> */}
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label="Firmenname"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie einen Firmenname an.',
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Organisation Name" disabled={disabled} />
        </Form.Item>
        <Form.Item name="slogan" label="Slogan">
          <Input.TextArea rows={2} placeholder="Firmenslogan" maxLength={50} disabled={disabled} />
        </Form.Item>
        <Form.Item name="address" label="Adresse">
          <Input placeholder="Straße Nr. ,PLZ Stadt" disabled={disabled} />
        </Form.Item>
        <Form.Item name="google" label="Google Maps Link">
          <Input placeholder="https://goo.gl/maps/suffix" disabled={disabled} />
        </Form.Item>
        <Form.Item name="website" label="Webseite">
          <Input placeholder="https://firmenseite.de" disabled={disabled} />
        </Form.Item>
        <Form.Item name="allowedUsers" label="Active Users">
          <Select disabled={disabled} defaultValue={initialValues['allowedUsers']}>
            <Option value="5">5</Option>
            <Option value="10">10</Option>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
          </Select>
        </Form.Item>
        <Form.Item name="allowedProjects" label="Active Projects">
          <Select disabled={disabled} defaultValue={initialValues['allowedProjects']}>
            <Option value="10">10</Option>
            <Option value="25">25</Option>
            <Option value="50">50</Option>
            <Option value="250">250</Option>
          </Select>
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { deletion, visible, current, onDelete } = this.props;
    var modalFooter;

    if (!deletion) {
      modalFooter = current
        ? {
            okText: 'Update',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          }
        : {
            okText: 'Speichern',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          };
    } else {
      modalFooter = {
        okText: 'Löschen',
        okButtonProps: { danger: true },
        onOk: onDelete,
        onCancel: this.handleCancel,
      };
    }
    return (
      <>
        <Modal
          title={`Organisation ${current ? (deletion ? 'löschen' : 'bearbeiten') : 'hinzufügen'}`}
          className="standardListForm"
          width={640}
          bodyStyle={{ padding: '28px 0 0' }}
          destroyOnClose
          visible={visible}
          {...modalFooter}
        >
          {this.getModalContent()}
        </Modal>
      </>
    );
  }
}
