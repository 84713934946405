import React from 'react';
import { Button, Card, List, message, Alert, Avatar, Statistic, Tag, Divider } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import ProjectModal from '../Modal/ProjectModal';
import './Projects.less';

export default class ProjectsView extends React.Component {
  constructor() {
    super();
    moment.locale('de', momentDe);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    this.props.getProjects('?archived=false&sort=-createdAt');
  }
  // componentWillUnmount() {
  //   this.props.unloadProjectPage();
  // }

  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  handleSubmit = (formValues) => {
    this.props.registerProject(formValues);
    this.setState({
      visible: false,
    });
    if (!this.props.error) {
      message.success(`Projekt wurde erfolgreich erstellt`);
    }
  };

  renderAvatarList = (item) => {
    if (item.members) {
      return item.members.map((member, i) => <Avatar key={`${member}-${i}`} src={this.props.auth.defaultAvatar.src} tips={member} />);
    } else {
      return <Avatar key={item.id} src={this.props.auth.defaultAvatar.src} />;
    }
  };

  onProjectClicked = (item) => {
    this.props.setActiveProject(item);
    this.props.setActiveTab('0');
  };

  /* ========================================================================== */
  /*                            render project items                            */
  /* ========================================================================== */
  renderProjectItems = (item) => {
    if (item && item.id) {
      return (
        <List.Item key={item.id}>
          <Link
            to={`/projects/active/${item.id}`}
            onClick={() => {
              this.props.setActiveProject(item);
              this.props.setActiveTab('0');
            }}
          >
            <Card className="card" hoverable cover={<img alt="img" className="cardAvatar" src={`data:image/png;base64,${item.cover}`} />}>
              <Card.Meta title={item.name} description={<a>{item.desc}</a>} />
              <div className="cardItemContent">
                <span>{moment(item.createdAt).fromNow()}</span>
                {!item.default ? (
                  <div className="avatarList">{/* <Avatar.Group size="small">{this.renderAvatarList(item)}</Avatar.Group> */}</div>
                ) : (
                  <div className="cardTag">
                    <Tag color="red" key="tag">
                      Beispielprojekt
                    </Tag>
                  </div>
                )}
              </div>
            </Card>
          </Link>
        </List.Item>
      );
    }
    return (
      <List.Item>
        <Button type="dashed" className="newButton" onClick={() => this.showModal()}>
          <PlusOutlined /> Neues Projekt anlegen...
        </Button>
      </List.Item>
    );
  };

  extraContent = (isProcessing, activeProjects, defaultProjects) => {
    return (
      <div className="extraContent">
        <div className="statItem">
          <Statistic title="Aktive Projekte" loading={isProcessing} value={activeProjects && activeProjects.length} />
        </div>
        <div className="statItem">
          <Statistic title="Beispiel Projekte" loading={isProcessing} value={defaultProjects && defaultProjects.length} />
        </div>
      </div>
    );
  };

  render() {
    const { visible } = this.state;
    const { projects, error, isProcessing } = this.props;
    if (projects) {
      var activeProjects = [];
      var defaultProjects = [];
      var invisibleProjects = [];
      projects.map((project) => {
        if (project && project.default) {
          return defaultProjects.push(project);
        }
        if (project && !project.visible) {
          return invisibleProjects.push(project);
        }
        return activeProjects.push(project);
      });
    }
    return (
      <PageContainer extra={this.extraContent(isProcessing, activeProjects, defaultProjects)}>
        <div className="project-cardList">
          {error && (
            <>
              <Alert showIcon message="Fehler" description={this.props.error} type="error" />
              <Divider />
            </>
          )}
          <List
            rowKey="id"
            loading={isProcessing}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 5,
            }}
            dataSource={projects !== null ? [{}, ...activeProjects, ...defaultProjects] : [{}]}
            renderItem={(item) => {
              return this.renderProjectItems(item);
            }}
            style={{ minHeight: '700px' }}
          />
        </div>
        <ProjectModal
          visible={visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          // onDelete={this.handleDelete}
        />
      </PageContainer>
    );
  }
}
