import React from 'react';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { Button, Space, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import DropOption from '../../components/DropOption';
import { bimServerApi, errorHandler } from '../../store/apis/bim.server.api';

import AnchorDataModal from './Modal/AnchorDataModal';

export default class AnchorDataView extends React.Component {
  tableRef = React.createRef();
  constructor() {
    super();

    this.state = {
      visible: false,
      deletion: false,
      current: undefined,
      selectedRows: [],
    };
  }

  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = () => {
    this.setState({
      visible: true,
      current: undefined,
    });
  };
  showEditModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };
  showDeleteModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      deletion: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      deletion: false,
    });
  };

  handleSubmit = (formValues) => {
    const { current } = this.state;
    if (current) {
      this.updateAnchor(formValues);
    } else {
      this.registerAnchor(formValues);
    }
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDelete = () => {
    this.deleteAnchor(this.state.current);
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDeleteItems = (users) => {
    users.map((user) => {
      this.deleteAnchor(user);
    });
    this.tableRef.current?.reloadAndRest?.();
  };

  deleteAnchor = (current) => {
    bimServerApi.delete(`/api/azure/asa/anchor/${current.id}`).then(
      (response) => {
        message.success(`Löschen erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  registerAnchor = (formValues) => {
    bimServerApi.post('/api/azure/asa/anchor/', formValues).then(
      (response) => {
        message.success(`Registration erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  updateAnchor = (formValues) => {
    const { current } = this.state;
    bimServerApi.put(`/api/azure/asa/anchor/${current.id}`, formValues).then(
      () => {
        message.success(`Update erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };

  /* ========================================================================== */
  /*                           DropOption Handle Click                          */
  /* ========================================================================== */

  handleMenuClick = (record, e) => {
    switch (e.key) {
      case '1':
        //Edit
        this.showEditModal(record);
        break;
      case '2':
        //Delete
        this.showDeleteModal(record);
        break;
    }
  };

  get columns() {
    return [
      {
        title: 'Location Name',
        dataIndex: 'location',
        key: 'location',
        copyable: true,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return record.location ? `${record.location.name}` : `-`;
        },
      },
      {
        title: 'Anchor Id',
        dataIndex: 'anchorId',
        key: 'anchorId',
        copyable: true,
      },
      {
        title: 'Anchor Nr.',
        dataIndex: 'anchorNr',
        key: 'anchorNr',
        copyable: true,
      },
      {
        title: 'Erstellt',
        key: 'createdAt',
        dataIndex: 'createdAt',
        valueType: 'date',
        search: false,
        sorter: (a, b) => {
          const aa = moment(a.createdAt);
          const bb = moment(b.createdAt);
          return aa - bb;
        },
      },
      {
        title: 'Updated',
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        valueType: 'date',
        search: false,
        sorter: (a, b) => {
          const aa = moment(a.updatedAt);
          const bb = moment(b.updatedAt);
          return aa - bb;
        },
      },
      {
        title: 'Object Id',
        dataIndex: 'id',
        key: 'id',
        copyable: true,
        search: false,
      },
      {
        title: 'Action',
        valueType: 'option',
        fixed: 'right',
        render: (text, record, _, action) => [
          <DropOption
            onMenuClick={(e) => this.handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: `Bearbeiten`, icon: <EditOutlined /> },
              {
                key: '2',
                name: `Löschen`,
                icon: <DeleteOutlined />,
                danger: true,
              },
            ]}
          />,
        ],
      },
    ];
  }

  render() {
    const { selectedRows, visible, current, deletion } = this.state;
    return (
      <PageContainer>
        <ProTable
          actionRef={this.tableRef}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          columns={this.columns}
          headerTitle={
            <Space>
              <span>Anchor Location Table</span>
            </Space>
          }
          rowSelection={{
            onChange: (_, selectedRows) => this.setState({ selectedRows: selectedRows }),
          }}
          toolBarRender={() => [
            <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => this.showModal()}>
              Add
            </Button>,
          ]}
          request={(params, sort, filter) => {
            delete params['current'];
            delete params['pageSize'];
            const queryString = Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&');
            return new Promise(async (resolve, reject) => {
              bimServerApi.get(`/api/azure/asa/anchor/?${queryString}`).then(
                (response) => {
                  resolve({ data: response.data.anchors, success: true });
                },
                (err) => {
                  reject({ data: null, success: false });
                },
              );
            });
          }}
        />
        {selectedRows?.length > 0 && (
          <FooterToolbar
            extra={
              <div>
                Ausgewählt: <a style={{ fontWeight: 600 }}>{selectedRows.length}</a> User
              </div>
            }
          >
            <Button
              type="primary"
              onClick={async () => {
                await this.handleDeleteItems(selectedRows);
                this.setState({ selectedRows: [] });
              }}
            >
              Löschen
            </Button>
          </FooterToolbar>
        )}
        <AnchorDataModal
          deletion={deletion}
          current={current}
          visible={visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </PageContainer>
    );
  }
}
