import React from 'react';
import { List, Button, message } from 'antd';

import PasswordModal from './components/PasswordModal';

const passwordStrength = {
  strong: <span className="strong">Stark</span>,
  medium: <span className="medium">Mittel</span>,
  weak: <span className="weak">Schwach</span>,
};

export default class SecuritySettings extends React.Component {
  constructor() {
    super();
    this.state = {
      passwordModalVisible: false,
    };
  }
  componentWillUnmount() {
    this.props.unloadProfilePage();
  }
  /* ========================================================================== */
  /*                               Password Modal                               */
  /* ========================================================================== */

  handleCancel = () => {
    this.setState({
      passwordModalVisible: false,
    });
  };

  /* ========================================================================== */
  /*                                List Actions                                */
  /* ========================================================================== */

  onPasswordChange() {
    this.setState({
      passwordModalVisible: true,
    });
  }
  onPhoneChange() {}
  onSecurityQuestionChange() {}
  onBackupEmailChange() {}

  /* ========================================================================== */
  /*                                  List Data                                 */
  /* ========================================================================== */

  getData = () => [
    {
      title: 'Account Passwort',
      description: (
        <>
          {'Aktuelle Passwortstärk'}：{passwordStrength.strong}
        </>
      ),
      actions: [
        <a key="Modify">
          <Button disabled={this.props.isProcessed} onClick={() => this.onPasswordChange()} type="link">
            Ändern
          </Button>
        </a>,
      ],
    },
    {
      title: 'Telefonnummer',
      description: `Keine Telefonnummer hinterlegt`,
      actions: [
        <a key="Modify">
          <Button disabled={true} onClick={() => this.onPhoneChange()} type="link">
            Ändern
          </Button>
        </a>,
      ],
    },
    {
      title: 'Sicherheitssfrage',
      description: 'Keine Sicherheitsfrage festgelegt',
      actions: [
        <a key="Set">
          {' '}
          <Button disabled={true} onClick={() => this.onSecurityQuestionChange()} type="link">
            Ändern
          </Button>
        </a>,
      ],
    },
    {
      title: 'Backup Email',
      description: `Keine Backup Email festgelegt`,
      actions: [
        <a key="Modify">
          {' '}
          <Button disabled={true} onClick={() => this.onBackupEmailChange()} type="link">
            Ändern
          </Button>
        </a>,
      ],
    },
  ];

  render() {
    var { passwordModalVisible } = this.state;
    const data = this.getData();
    if (this.props.isProcessed) {
      passwordModalVisible = false;
      message.success('Passwort erfolgreich geändert!');
    }
    return (
      <>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item actions={item.actions}>
              <List.Item.Meta title={item.title} description={item.description} />
            </List.Item>
          )}
        />
        <PasswordModal visible={passwordModalVisible} onCancel={this.handleCancel} user={this.props.profile} {...this.props} />
      </>
    );
  }
}
