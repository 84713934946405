import React from 'react';
import _ from 'lodash';

import { Modal, Form, Input, Checkbox, Select } from 'antd';

const { Option } = Select;

export default class TokenModal extends React.Component {
  formRef = React.createRef();

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }

  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (fieldsValue) => {
    if (this.props.onSubmit) {
      delete fieldsValue['objectId'];
      Object.keys(fieldsValue).map((key) => {
        if (fieldsValue[key] === undefined) {
          delete fieldsValue[key];
        }
      });
      this.props.onSubmit(fieldsValue);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */

  getModalContent() {
    const { current, deletion } = this.props;

    let disabled;
    if (deletion) {
      disabled = true;
    }

    var initialValues = {};
    if (current) {
      initialValues = {
        ['email']: current.email,
        ['token']: current.token,
        ['tokenPurpose']: current.tokenPurpose,
        ['organisationId']: current.organisationId,
        ['dateCreated']: current.dateCreated,
        ['objectId']: current.id,
      };
    }
    return (
      <Form {...this.formLayout} onFinish={this.handleFinish} ref={this.formRef} initialValues={initialValues} style={{ padding: 10 }}>
        <Form.Item name="email" label="Email">
          <Input placeholder="Email" disabled={disabled} />
        </Form.Item>
        {current && (
          <Form.Item name="token" label="Token">
            <Input placeholder="token" disabled={disabled} />
          </Form.Item>
        )}
        <Form.Item label="Purpose" name="tokenPurpose">
          <Select defaultValue={initialValues['tokenPurpose']} disabled={disabled}>
            <Option value="verify-email">verify-email</Option>
            <Option value="reset-password">reset-password</Option>
          </Select>
        </Form.Item>
        <Form.Item name="organisationId" label="Organisation Id">
          <Input placeholder="Organisation Id" disabled={disabled} />
        </Form.Item>
        <Form.Item name="objectId" label="Object ID">
          <Input placeholder="Object Id " disabled={true} />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { deletion, visible, current, onDelete } = this.props;
    var modalFooter;
    if (!deletion) {
      modalFooter = current
        ? {
            okText: 'Update',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          }
        : {
            okText: 'Speichern',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          };
    } else {
      modalFooter = {
        okText: 'Löschen',
        okButtonProps: { danger: true },
        onOk: onDelete,
        onCancel: this.handleCancel,
      };
    }

    return (
      <Modal
        title={`Token ${current ? (deletion ? 'löschen' : 'bearbeiten') : 'hinzufügen'}`}
        className="standardListForm"
        width={640}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={visible}
        {...modalFooter}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}
