import React from 'react';
import { Frame } from '../components/Frame';
import config from '../../../config';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './Matterport.less';

export default class MatterportViewer extends React.Component {
  constructor() {
    super();
    this.state = {
      matterportId: '',
      name: 'KW auswählen',
    };
  }

  async onIFrameLoad() {
    console.log('ON IFRAME LOAD');
    console.log(this.props.id);
    if (this.props.dbModel.src) {
      await this.loadEmbededViewerScript();
      const { sdk, iframe } = await this.getSDKforEmbeds(this.props.id || 'sdk-iframe');
      // console.log(sdk);
      // console.log(iframe);
      if (this.props.onViewerCreated) {
        this.props.onViewerCreated(sdk, iframe);
      }
    } else {
      const { sdk, iframe } = await this.getSDKBundle(this.props.id || 'sdk-iframe');
      if (this.props.onViewerCreated) {
        this.props.onViewerCreated(sdk, iframe);
      }
    }
    // this.setState({
    //   matterportId: this.props.dbModel.matterportId,
    //   name: this.props.dbModel.name,
    // });
  }

  // async componentDidMount() {
  //   if (this.props.dbModel.src) {
  //     await this.loadEmbededViewerScript();
  //     const { sdk, iframe } = await this.getSDKforEmbeds(this.props.id);
  //     if (this.props.onViewerCreated) {
  //       this.props.onViewerCreated(sdk, iframe);
  //     }
  //   } else {
  //     const { sdk, iframe } = await this.getSDKBundle();
  //     if (this.props.onViewerCreated) {
  //       this.props.onViewerCreated(sdk, iframe);
  //     }
  //   }
  //   this.setState({
  //     matterportId: this.props.dbModel.matterportId,
  //     name: this.props.dbModel.name,
  //   });
  // }

  async loadEmbededViewerScript() {
    return new Promise((resolve, reject) => {
      if (!window.MP_SDK) {
        const script = document.createElement('script');
        script.src = config.matterport.script;
        script.onload = () => resolve();
        document.head.append(script);
      } else {
        resolve();
      }
    });
  }

  async getSDKforEmbeds(elementId = config.matterport.iframe, applicationKey = config.matterport.sdkKey) {
    return new Promise((resolve, reject) => {
      const checkIframe = async function () {
        var iframe = null;
        if (elementId instanceof HTMLIFrameElement) {
          iframe = elementId;
        } else {
          iframe = document.getElementById(elementId);
        }

        if (iframe && window.MP_SDK) {
          clearInterval(intervalId);
          const sdk = await window.MP_SDK.connect(iframe, applicationKey, config.matterport.sdkVersion);

          resolve({ sdk, iframe });
        }
      };
      const intervalId = setInterval(checkIframe, 100);
    });
  }

  async getSDKBundle(elementId = config.matterport.iframe, applicationKey = config.matterport.sdkKey) {
    return new Promise((resolve, reject) => {
      console.log('GET SDK BUNDLE');
      const checkIframe = async function () {
        try {
          var iframe = null;
          if (elementId instanceof HTMLIFrameElement) {
            iframe = elementId;
          } else {
            iframe = document.getElementById(elementId);
          }
          if (iframe && iframe.contentWindow.MP_SDK) {
            clearInterval(intervalId);
            const sdk = await iframe.contentWindow.MP_SDK.connect(iframe, applicationKey, config.matterport.sdkVersion);
            resolve({ sdk, iframe });
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
      const intervalId = setInterval(checkIframe, 100);
    });
  }

  /*
  async onIframeLoad() {
    const iframe = document.getElementById('sdk-iframe');

    const loadingPoweredBy = iframe.contentWindow.document.getElementById('loading-powered-by');
    if (loadingPoweredBy) {
      loadingPoweredBy.style.display = 'none';
    }
    const passwordPoweredBy = await iframe.contentWindow.document.getElementsByClassName('powered-by');
    if (passwordPoweredBy) {
      console.log(passwordPoweredBy.length);
      setTimeout(() => {
        passwordPoweredBy[0].style.display = 'none';
      }, 1000);
    }
  }
  */

  renderTitle(showTitle, titleStyle) {
    if (!this.props.showTitle) {
      return <div />;
    }
    const items = this.props.scans || [];
    const menuItems = items.map((item, idx) => {
      return (
        <Menu.Item
          key={idx}
          eventKey={idx}
          onClick={() => {
            this.setState({
              matterportId: item.matterportId,
              name: item.name,
            });
          }}
        >
          {item.name}
        </Menu.Item>
      );
    });
    const menu = <Menu>{menuItems}</Menu>;

    return (
      <div className="title controls" style={titleStyle}>
        <label>Auswahl</label>
        <Dropdown.Button overlay={menu} icon={<DownOutlined />} disabled={this.props.id ? false : true}>
          {this.state.name}
        </Dropdown.Button>
      </div>
    );
  }

  render() {
    const panels = this.props.panels.map((panel) => {
      return panel.render();
    });
    const { showTitle, mouseOver } = this.props;

    const titleStyle = showTitle
      ? { height: '40px', width: '100%', position: 'relative', backgroundColor: '#f5f5f5', borderBottom: '1px solid #cdcdcd', alignItems: 'center' }
      : { height: '0px' };
    const iframeStyle = showTitle ? { height: 'calc(100% - 40px)' } : { height: '100%' };

    //const params = objectFromQuery();
    const params = this.props.params || {};
    params.m = params.m || this.state.matterportId || this.props.dbModel.matterportId;
    params.play = params.play || '1';
    params.qs = params.qs || '1';
    params.sr = params.sr || '-.15';
    //Sweep Position
    //params.ss = params.ss || '23';

    // ensure applicationKey is inserted into the bundle query string
    if (!this.props.dbModel.src) {
      params.applicationKey = config.matterport.sdkKey;
    }
    //params.applicationKey = config.matterport.sdkKey;
    // params.applicationKey = config.matterport.sdkKey;
    this.applicationKey = params.applicationKey;

    const queryString = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');
    var src;
    if (this.props.dbModel.src) {
      src = `${this.props.dbModel.src}?${queryString}`;
    } else {
      src = `${config.server.publicUrl}/bundle/showcase.html?${queryString}`;
    }
    return (
      <div
        className="matterport-viewer-app-container"
        onMouseEnter={() => (mouseOver ? mouseOver(true) : console.log('no mouseover desiged'))}
        onMouseLeave={() => (mouseOver ? mouseOver(false) : console.log('no mouseover desiged'))}
      >
        <div className="matterport-viewer-container" style={{ height: '100%' }}>
          {this.renderTitle(showTitle, titleStyle)}

          <Frame
            src={src}
            title={this.props.title}
            id={this.props.id}
            className={this.props.className}
            style={iframeStyle}
            //onLoad={() => console.log('ON IFRAME LOAD')}
            onLoad={() => this.onIFrameLoad()}
          />
        </div>
        <div className="matterport-viewer-panels-container">{panels}</div>
      </div>
    );
  }
}

export const objectFromQuery = (url) => {
  const regex = /[#&?]([^=]+)=([^#&?]+)/g;
  url = url || window.location.href;
  const object = {};
  let matches;
  // regex.exec returns new matches on each
  // call when we use /g like above
  while ((matches = regex.exec(url)) !== null) {
    object[matches[1]] = decodeURIComponent(matches[2]);
  }
  return object;
};
