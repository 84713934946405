import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';

import createStore, { history } from './store/createStore';
import reportWebVitals from './reportWebVitals';

import Routes from './routes';
import './themes/theme.light.less';

const store = createStore();

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

let render = (messages) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ConfigProvider>
            <Routes />
          </ConfigProvider>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    MOUNT_NODE,
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// ========================================================
// Go!
// ========================================================
render(); //render(translationMessages)
