import React from 'react';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';

import { Tag, Button, Space, message, Typography } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

import DropOption from '../../components/DropOption';
import NoticeRootModal from './Modal/NoticesModal';

import moment from 'moment';
import momentDe from 'moment/locale/de';

import { bimServerApi, errorHandler } from '../../store/apis/bim.server.api';

const { Paragraph, Text } = Typography;

export default class NoticesView extends React.Component {
  tableRef = React.createRef();
  constructor() {
    super();

    moment.locale('de', momentDe);

    this.state = {
      visible: false,
      deletion: false,
      current: undefined,
      selectedRows: [],
    };
  }

  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = () => {
    this.setState({
      visible: true,
      current: undefined,
    });
  };
  showEditModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };
  showDeleteModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      deletion: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      deletion: false,
    });
  };

  handleSubmit = (formValues) => {
    const { current } = this.state;
    if (current) {
      this.updateNotice(formValues);
    } else {
      this.registerNotice(formValues);
    }
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDelete = () => {
    this.deleteNotice(this.state.current);
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDeleteItems = (users) => {
    users.map((user) => {
      this.deleteNotice(user);
    });
    this.tableRef.current?.reloadAndRest?.();
  };

  deleteNotice = (current) => {
    bimServerApi.delete(`/api/notices/${current.id}`).then(
      (response) => {
        message.success(`Löschen erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  registerNotice = (formValues) => {
    bimServerApi.post('/api/notices/', formValues).then(
      (response) => {
        message.success(`Registration erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  updateNotice = (formValues) => {
    const { current } = this.state;
    bimServerApi.put(`/api/notices/${current.id}`, formValues).then(
      () => {
        message.success(`Update erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };

  /* ========================================================================== */
  /*                           DropOption Handle Click                          */
  /* ========================================================================== */

  handleMenuClick = (record, e) => {
    switch (e.key) {
      case '1':
        //Edit
        this.showEditModal(record);
        break;
      case '2':
        //Delete
        this.showDeleteModal(record);
        break;
    }
  };

  get columns() {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        copyable: true,
      },
      {
        title: 'Desc',
        dataIndex: 'desc',
        key: 'desc',
        search: false,
      },
      {
        title: 'Nutzer',
        dataIndex: 'user',
        key: 'user',
        copyable: true,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return record.user ? `${record.user.firstName} ${record.user.lastName}` : `-`;
        },
      },
      {
        title: 'Nutzer Id',
        dataIndex: 'user',
        key: '1',
        search: false,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return (
            <Paragraph
              copyable={{
                text: record.user ? `${record.user._id}` : `-`,
                tooltips: ['kopieren.', 'kopiert!'],
              }}
            >
              <Text code>{record.user ? `${record.user._id}` : `-`}</Text>
            </Paragraph>
          );
        },
      },
      {
        title: 'Gelesen',
        dataIndex: 'read',
        filters: true,
        onFilter: true,
        valueType: 'select',
        valueEnum: {
          true: {
            text: 'True',
            status: 'Success',
          },
          false: {
            text: 'False',
            status: 'Error',
          },
        },
      },
      {
        title: 'Extra',
        dataIndex: 'extra',
        filters: true,
        onFilter: true,
        valueType: 'select',
        valueEnum: {
          System: {
            text: 'System',
          },
          User: {
            text: 'User',
          },
        },
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => (
          <Tag color={record.extra === 'System' ? 'red' : 'yellow'} key={record.extra}>
            {record.extra}
          </Tag>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        filters: true,
        onFilter: true,
        valueType: 'select',
        valueEnum: {
          message: {
            text: 'Message',
          },
          event: {
            text: 'Event',
          },
        },
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => (
          <Tag color={record.type === 'System' ? 'blue' : 'green'} key={record.type}>
            {record.type}
          </Tag>
        ),
      },
      {
        title: 'Erstellt',
        key: 'createdAt',
        dataIndex: 'createdAt',
        valueType: 'date',
        search: false,
        sorter: (a, b) => {
          const aa = moment(a.createdAt);
          const bb = moment(b.createdAt);
          return aa - bb;
        },
      },
      {
        title: 'Object Id',
        dataIndex: 'id',
        key: 'id',
        copyable: true,
        search: false,
      },
      {
        title: 'Action',
        valueType: 'option',
        fixed: 'right',
        render: (text, record, _, action) => [
          <DropOption
            onMenuClick={(e) => this.handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: `Bearbeiten`, icon: <EditOutlined /> },
              {
                key: '2',
                name: `Löschen`,
                icon: <DeleteOutlined />,
                danger: true,
              },
            ]}
          />,
        ],
      },
    ];
  }

  render() {
    const { selectedRows, visible, current, deletion } = this.state;
    return (
      <PageContainer>
        <ProTable
          actionRef={this.tableRef}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          columns={this.columns}
          headerTitle={
            <Space>
              <span>Notices Table</span>
            </Space>
          }
          rowSelection={{
            onChange: (_, selectedRows) => this.setState({ selectedRows: selectedRows }),
          }}
          toolBarRender={() => [
            <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => this.showModal()}>
              Add
            </Button>,
          ]}
          request={(params, sort, filter) => {
            delete params['current'];
            delete params['pageSize'];
            const queryString = Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&');
            return new Promise(async (resolve, reject) => {
              bimServerApi.get(`/api/notices/all?${queryString}`).then(
                (response) => {
                  resolve({ data: response.data.notices, success: true });
                },
                (err) => {
                  reject({ data: null, success: false });
                },
              );
            });
          }}
        />
        {selectedRows?.length > 0 && (
          <FooterToolbar
            extra={
              <div>
                Ausgewählt: <a style={{ fontWeight: 600 }}>{selectedRows.length}</a> Notices
              </div>
            }
          >
            <Button
              type="primary"
              onClick={async () => {
                await this.handleDeleteItems(selectedRows);
                this.setState({ selectedRows: [] });
              }}
            >
              Löschen
            </Button>
          </FooterToolbar>
        )}
        <NoticeRootModal
          deletion={deletion}
          current={current}
          visible={visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </PageContainer>
    );
  }
}
