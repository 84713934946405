import React from 'react';
import { Modal, Form, Input, Select } from 'antd';

export default class EditUserModal extends React.Component {
  formRef = React.createRef();

  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    if (this.props.onSubmit) {
      delete formValues['firstName'];
      delete formValues['lastName'];
      delete formValues['email'];
      delete formValues['birthday'];
      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */
  getModalContent() {
    const { user } = this.props;
    if (!user) {
      return;
    }
    return (
      <Form
        {...this.formLayout}
        ref={this.formRef}
        style={{ padding: 10 }}
        onFinish={this.handleFinish}
        initialValues={{
          ['firstName']: user.firstName,
          ['lastName']: user.lastName,
          ['email']: user.email,
          ['birthday']: user.birthday,
          ['role']: user.role,
        }}
      >
        <Form.Item name="firstName" label="Vorname">
          <Input placeholder="Vorname" disabled />
        </Form.Item>
        <Form.Item name="lastName" label="Nachname">
          <Input placeholder="Nachname" disabled />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input disabled placeholder="Email" disabled />
        </Form.Item>
        <Form.Item name="birthday" label="Geburtsdatum">
          <Input placeholder="DD.MM.YYYY" disabled />
        </Form.Item>
        <Form.Item name="role" label="Rechte">
          <Select>
            <Select.Option value="user">User</Select.Option>
            <Select.Option value="admin">Admin</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { user, visible } = this.props;
    if (!user) {
      return <></>;
    }
    const modalFooter = {
      okText: 'Bearbeiten',
      onOk: this.handleSubmit,
      onCancel: this.handleCancel,
    };
    return (
      <Modal title={'Nutzerrechte bearbeiten'} className="standardListForm" width={640} bodyStyle={{ padding: '28px 0 0' }} destroyOnClose visible={visible} {...modalFooter}>
        {this.getModalContent()}
      </Modal>
    );
  }
}
