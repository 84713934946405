import ArchivedProjects from './components/ArchivedProjects';
import { connect } from 'react-redux';

import { getProjects, updateProject, deleteProject } from '../../store/actions/projects.actions';
import { getCurrentProjects, getCurrentProject, getError, getProcessing, getProcessed } from '../../store/selectors/projects.selectors';
import { getCurrentUser } from '../../store/selectors/auth.selectors';

const mapDispatchToProps = {
  getProjects,
  deleteProject,
  updateProject,
};
const mapStateToProps = (state) => {
  return {
    projects: getCurrentProjects(state),
    project: getCurrentProject(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    currentUser: getCurrentUser(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ArchivedProjects);
