import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  showrooms: null,
  showroom: null,
  processing: false,
  processed: false,
  error: null,
  activeTab: 'Task-Forge',
};

const showroomReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SHOWROOMS:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_SHOWROOMS_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_SHOWROOMS_SUCCESS:
      return {
        ...state,
        processing: false,
        showrooms: action.payload,
      };
    case actionTypes.SET_ACTIVE_SHOWROOM_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case actionTypes.UNLOAD_SHOWROOM_PAGE:
      return {
        ...state,
        error: null,
        processing: false,
        processed: false,
      };
    default:
      return state;
  }
};

export default showroomReducer;
