import React, { Component } from 'react';

export class Frame extends Component {
  render() {
    return (
      <iframe
        title={this.props.title || 'sdk-iframe'}
        id={this.props.id || 'sdk-iframe'}
        className={this.props.className || 'responsive-iframe'}
        height="100%"
        width="100%"
        src={this.props.src}
        allowFullScreen
        allow="xr-spatial-tracking"
        onLoad={this.props.onLoad}
        style={this.props.style}
      ></iframe>
    );
  }
}

/*
      <iframe
        title="sdk-iframe"
        id="sdk-iframe"
        className="responsive-iframe"
        height="100%"
        width="100%"
        src={this.props.src}
        allowFullScreen
        allow="xr-spatial-tracking"
        onLoad={this.props.onLoad}
      ></iframe>
*/
