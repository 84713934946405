import React from 'react';
import { Modal, Form, Input, Alert } from 'antd';

export default class PasswordModal extends React.Component {
  formRef = React.createRef();

  /* ========================================================================== */
  /*                          Modal Footer Interactions                         */
  /* ========================================================================== */

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleFinish = (formData) => {
    delete formData['confirm'];
    this.props.updatePassword(formData);
  };

  /* ========================================================================== */
  /*                               Validate Password                             */
  /* ========================================================================== */

  checkPassword = (rule, value, callback) => {
    if (value.length < 6) {
      callback('Das Passwort muss mindestens 6 Stellen lang sein!');
    }
    callback();
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */
  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  getModalContent() {
    return (
      <Form {...this.formLayout} onFinish={this.handleFinish} ref={this.formRef} style={{ padding: 10 }}>
        <Form.Item
          name="currentPassword"
          label="Aktuelles Passwort"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte Ihr aktuelles Passwort eingeben!',
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Aktuell Passwort" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Neues Passwort"
          dependencies={['currentPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte Ihr neues Passwort eingeben!',
            },
            {
              validator: this.checkPassword,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue('currentPassword') === value) {
                  return Promise.reject('Die Passwörter müssen unterschiedlich sein!');
                }
                return Promise.resolve();
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Neues Passwort" />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          label="Neues Passwort bestätigen"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte wiederholen Sie ihr Passwort!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('Die Passwörter stimmen nicht überein!');
              },
            }),
          ]}
        >
          <Input.Password placeholder="Passwort wiederholen" />
        </Form.Item>
        {this.props.error && <Alert showIcon message="Fehler" description={this.props.error} type="error" />}
      </Form>
    );
  }

  render() {
    const { visible } = this.props;
    const modalFooter = {
      okText: 'Ändern',
      onOk: this.handleSubmit,
      okButtonProps: { loading: this.props.isProcessing },
      onCancel: this.handleCancel,
    };
    return (
      <Modal
        className="standardListForm"
        title={'Passwort Ändern'}
        width={640}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={visible}
        {...modalFooter}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}
