import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import AvatarDropdown from './AvatarDropdown';
import HeaderSearch from '../HeaderSearch';
import NoticeIconView from './NoticeIconView';
import './index.less';

import config from '../../config';

// const ENVTagColor = {
//   dev: 'orange',
//   test: 'green',
//   pre: '#87d068',
// };

const GlobalHeaderRight = (props) => {
  const { theme, layout } = props;
  let className = 'right';

  if (theme === 'dark' && layout === 'top') {
    className = `${'right'}  ${'dark'}`;
  }

  return (
    <div className={className}>
      <HeaderSearch
        className={`${'action'} ${'search'}`}
        placeholder="Suche auf der Website"
        defaultValue={config.app.title}
        // options={[
        //   {
        //     label: <a href="https://umijs.org/zh/guide/umi-ui.html">umi ui</a>,
        //     value: 'umi ui',
        //   },
        //   {
        //     label: <a href="next.ant.design">Ant Design</a>,
        //     value: 'Ant Design',
        //   },
        //   {
        //     label: <a href="https://protable.ant.design/">Pro Table</a>,
        //     value: 'Pro Table',
        //   },
        //   {
        //     label: <a href="https://prolayout.ant.design/">Pro Layout</a>,
        //     value: 'Pro Layout',
        //   },
        // ]}
      />
      <Tooltip title="Powered by BIMension GmbH">
        <a
          style={{
            color: 'inherit',
          }}
          target="_blank"
          href="https://bimension.de"
          rel="noopener noreferrer"
          className={'action'}
        >
          <QuestionCircleOutlined />
        </a>
      </Tooltip>
      <NoticeIconView />
      <AvatarDropdown {...props} />
    </div>
  );
};

export default GlobalHeaderRight;
