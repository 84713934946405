import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  env: null,
  profile: null,
};

const viewerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_VIEWER_ENV:
      return {
        ...state,
        env: action.payload,
      };
    case actionTypes.SET_VIEWER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    default:
      return state;
  }
};

export default viewerReducer;
