import React from 'react';
import { DefaultFooter } from '@ant-design/pro-layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, Route } from 'react-router-dom';

import logo from '../../logo.svg';
import config from '../../config';

class BasicLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <HelmetProvider>
        <Helmet>
          <title>{config.app.title}</title>
          <meta name="description" content={config.app.desc} />
        </Helmet>

        <div className="container">
          <div className="content">
            <div className="top">
              <div className="header">
                <Link to="/">
                  <img alt="logo" className="logo" src={logo} />
                  <span className="title">{config.app.title}</span>
                </Link>
              </div>
              <div className="desc">{config.app.desc}</div>
            </div>
            {children}
          </div>
          <DefaultFooter
            copyright={`${new Date().getFullYear()} ${config.app.copyright}`}
            links={[
              {
                key: config.app.title,
                title: config.app.title,
                href: config.server.publicUrl,
                blankTarget: true,
              },
            ]}
          />
        </div>
      </HelmetProvider>
    );
  }
}

export default class BasicLayoutRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <BasicLayout>
            <Component {...matchProps} />
          </BasicLayout>
        )}
      />
    );
  }
}
