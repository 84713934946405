import OrganisationView from './components/OrganisationView';
import { connect } from 'react-redux';

import {
  getOrganisation,
  unloadOrganisationPage,
  inviteUser,
  inviteUserToBimforge,
  updateOrganisation,
  updateLogo,
  updateUserRole,
  removeUser,
} from '../../store/actions/organisations.actions';
import {
  getCurrentOrganisation,
  getCurrentOrganisationUsers,
  getError,
  getProcessing,
  getProcessed,
  getCurrentOrganisationProjects,
  getCurrentOrganisationModels,
  getCurrentOrganisationScans,
} from '../../store/selectors/organisations.selectors';
import { getCurrentUser } from '../../store/selectors/auth.selectors';

const mapDispatchToProps = {
  getOrganisation,
  unloadOrganisationPage,
  inviteUser,
  inviteUserToBimforge,
  updateOrganisation,
  updateLogo,
  updateUserRole,
  removeUser,
};

const mapStateToProps = (state) => {
  return {
    organisation: getCurrentOrganisation(state),
    activeUsers: getCurrentOrganisationUsers(state),
    activeProjects: getCurrentOrganisationProjects(state),
    activeModels: getCurrentOrganisationModels(state),
    activeScans: getCurrentOrganisationScans(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    currentUser: getCurrentUser(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationView);
