import * as actionTypes from './types';

export const setViewerEnv = (env) => {
  return {
    type: actionTypes.SET_VIEWER_ENV,
    payload: env,
  };
};

export const setViewerProfile = () => {
  const profile = new window.Autodesk.Viewing.Profile(window.Autodesk.Viewing.ProfileSettings.AEC);
  profile.extensions.load = ['Autodesk.VisualClusters', 'Autodesk.NPR', 'Autodesk.ProfileUi'];
  return {
    type: actionTypes.SET_VIEWER_PROFILE,
    payload: profile,
  };
};
