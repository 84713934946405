import React from 'react';
import { Button, Avatar, Tag, Card, List, Dropdown, Menu, Divider, Modal } from 'antd';
import { DownOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import InviteUserModal from '../Modal/InviteUserModal';
import EditUserModal from '../Modal/EditUserModal';

import './Team.less';
const { confirm } = Modal;

export default class Team extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      userDataSource: [],
      inviteUserModalVisible: false,
      editUserModalVisible: false,
      current: null,
    };
  }

  /* ========================================================================== */
  /*                                Remove Modal                                */
  /* ========================================================================== */
  removeUserModal = (user) => {
    const onOk = async () => {
      await this.props.removeUser(this.props.currentUser.organisation, user.id);
    };

    confirm({
      title: 'Nutzer entfernen?',
      icon: <ExclamationCircleOutlined />,
      content: `Sind sie sicher den Nutzer '${user.firstName} ${user.lastName}' aus der Organisation zu entfernen?`,
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nein',
      onOk() {
        onOk();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  /* ========================================================================== */
  /*                                  Invite  Modal                             */
  /* ========================================================================== */

  showModal() {
    this.setState({
      inviteUserModalVisible: true,
    });
  }

  handleCancel = () => {
    this.setState({
      inviteUserModalVisible: false,
    });
  };

  handleSubmit = async (formValues) => {
    this.setState({
      inviteUserModalVisible: false,
    });
    await this.props.inviteUser(this.props.currentUser.organisation, formValues);
  };
  /* ========================================================================== */
  /*                                  Edit  Modal                               */
  /* ========================================================================== */

  showEditModal(item) {
    this.setState({
      editUserModalVisible: true,
      current: item,
    });
  }

  handleEditCancel = () => {
    this.setState({
      editUserModalVisible: false,
    });
  };

  handleEditSubmit = async (formValues) => {
    this.setState({
      editUserModalVisible: false,
    });
    await this.props.updateUserRole(this.state.current.id, formValues);
  };

  /* ========================================================================== */
  /*                               Paginator Props                              */
  /* ========================================================================== */
  get paginationProps() {
    const { activeUsers } = this.props;
    return {
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: 5,
      total: Array.isArray(activeUsers) ? activeUsers.length : 1,
    };
  }

  render() {
    const { activeUsers, currentUser, organisation } = this.props;
    const { loading, inviteUserModalVisible, editUserModalVisible, current } = this.state;

    const MoreBtn = ({ key, disabled, item }) => (
      <Dropdown
        disabled={disabled}
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => this.showEditModal(item)}>
              Bearbeiten
            </Menu.Item>
            <Menu.Item key="delete" danger={true} onClick={() => this.removeUserModal(item)}>
              Entfernen
            </Menu.Item>
          </Menu>
        }
      >
        <a>
          Mehr <DownOutlined />
        </a>
      </Dropdown>
    );

    return (
      <div className="standardList">
        <Card className="listCard" bordered={false} style={{ marginTop: 24 }}>
          <Button
            className="addNewModelButton"
            type="dashed"
            style={{ width: '100%', marginBottom: 8 }}
            onClick={() => this.showModal()}
            // ref={this.addBtn}
            disabled={currentUser.role === 'user' ? true : activeUsers ? (activeUsers.length < organisation.allowedUsers ? false : true) : true}
          >
            <PlusOutlined />
            Neues Mitglied einladen...
          </Button>

          <List
            size="large"
            rowKey="id"
            loading={loading}
            pagination={this.paginationProps}
            dataSource={activeUsers}
            renderItem={(item) => (
              <List.Item actions={[<MoreBtn item={item} disabled={currentUser.role === 'user' ? true : false} />]}>
                <List.Item.Meta
                  avatar={<Avatar src={`data:image/jpeg;base64,${item.avatar}`} shape="round" size="large" />}
                  title={
                    <>
                      {item.firstName} {item.lastName}
                      <Divider type="vertical" />
                      {item.role === 'user' ? (
                        <Tag key="read" color={'blue'}>
                          User
                        </Tag>
                      ) : item.role === 'admin' ? (
                        <Tag key="write" color={'purple'}>
                          Admin
                        </Tag>
                      ) : (
                        <Tag key="write" color={'red'}>
                          Root
                        </Tag>
                      )}
                    </>
                  }
                  description={item.email}
                />
              </List.Item>
            )}
          />
        </Card>
        <InviteUserModal
          userDataSource={activeUsers}
          visible={inviteUserModalVisible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          organisationId={currentUser.organisation}
        />
        <EditUserModal
          visible={editUserModalVisible}
          onCancel={this.handleEditCancel}
          onSubmit={this.handleEditSubmit}
          user={current}
          {...this.props}
        />
      </div>
    );
  }
}
