import * as actionTypes from './types';

export const getShowrooms = (query = '') => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_SHOWROOMS });
  return bimServerApi.get(`/api/showroom/${query}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_SHOWROOMS_SUCCESS, payload: response.data.showrooms });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_SHOWROOMS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const setActiveTab = (tabIndex) => {
  return {
    type: actionTypes.SET_ACTIVE_SHOWROOM_TAB,
    payload: tabIndex,
  };
};

export const unloadShowroomPage = () => {
  return {
    type: actionTypes.UNLOAD_SHOWROOM_PAGE,
  };
};
