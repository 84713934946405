import React from 'react';
import { Form, Input, Button } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { resetPassword, unloadAuthPage } from '../../store/actions/auth.actions';
import { getError, getRedirectToPath } from '../../store/selectors/auth.selectors';
import './ResetPassword.less';

class ResetPassword extends React.Component {
  componentWillUnmount() {
    this.props.unloadAuthPage();
  }
  componentDidMount() {
    this.resetToken = this.props.match.params.token;
  }

  onFinish = (formValues) => {
    this.props.resetPassword(formValues, this.resetToken);
  };

  render() {
    if (this.props.redirectToPath != null) {
      this.props.history.push(this.props.redirectToPath, { message: 'Passwort erfolgreich zurückgesetzt.' });
    }
    return (
      <div className="reset-password">
        <Form onFinish={this.onFinish} name="resetPassword" className="reset-password-form">
          <h3 style={{ color: 'white' }}>Passwort zurücksetzten:</h3>

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Dies ist keine gülte Email-Adresse!',
              },
              {
                required: true,
                message: 'Bitte geben Sie Ihre Email ein!',
              },
            ]}
          >
            <Input size="large" prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihr Passwort ein!',
              },
            ]}
          >
            <Input size="large" prefix={<LockOutlined />} type="password" placeholder="Neues Password" />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="reset-password-form-button">
              Passwort zurücksetzten
            </Button>
            <Link className="reset-password-form-login" to="/user/signin">
              Anmelden!
            </Link>
            <Link className="reset-password-form-register" to="/user/signup">
              Keinen Konto? Jetzt registrieren!
            </Link>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
    redirectToPath: getRedirectToPath(state),
  };
};
export default connect(mapStateToProps, { resetPassword, unloadAuthPage })(ResetPassword);
