import { Component } from 'react';
import { connect } from 'react-redux';
import { Tag, message } from 'antd';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import { fetchNotices, changeNoticeReadState, deleteNotices } from '../../store/actions';
import { getNotices, getError, getProcessing } from '../../store/selectors';
import NoticeIcon from '../NoticeIcon';
import './index.less';

class GlobalHeaderRight extends Component {
  constructor() {
    super();
    this.state = {
      popupVisible: false,
    };
    moment.locale('de', momentDe);
  }

  componentDidMount() {
    this.props.fetchNotices();
  }

  changeReadState = async (clickedItem) => {
    const { id } = clickedItem;
    const state = {
      read: !clickedItem.read,
    };
    await this.props.changeNoticeReadState(state, id);
    this.setState({
      popupVisible: false,
    });
  };

  handleNoticeDelete = async (title, key) => {
    await this.props.deleteNotices();
    message.success('Alle Nachrichten wurden erfolgreich gelöscht.');
    this.setState({
      popupVisible: false,
    });
  };

  handleClose = () => {
    this.setState({
      popupVisible: false,
    });
  };

  onPopupVisibleChange = () => {
    this.setState({
      popupVisible: !this.state.popupVisible,
    });
  };

  getNoticeData = () => {
    const { notices = [] } = this.props;
    if (!notices || notices.length === 0 || !Array.isArray(notices)) {
      return {};
    }

    const newNotices = notices.map((notice) => {
      const newNotice = { ...notice };

      if (newNotice.updatedAt) {
        newNotice.datetime = moment(notice.updatedAt).fromNow();
      }

      if (newNotice.id) {
        newNotice.key = newNotice.id;
      }

      if (newNotice.extra) {
        const color = 'red';
        newNotice.extra = (
          <Tag
            color={color}
            style={{
              marginRight: 0,
            }}
          >
            {newNotice.extra}
          </Tag>
        );
      }
      newNotice.avatar = 'https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png';
      return newNotice;
    });
    return groupBy(newNotices, 'type');
  };
  sortNoticeData = (noticeData) => {
    var unreadMessages = [];
    var readMessages = [];
    Object.keys(noticeData).forEach((key) => {
      const value = noticeData[key];

      // if (!unreadMsg[key]) {
      //   unreadMsg[key] = 0;
      // }

      if (Array.isArray(value)) {
        value.forEach((notice) => {
          if (notice.read) {
            readMessages.push(notice);
          } else {
            unreadMessages.push(notice);
          }
        });
      }
    });
    return { unreadMessages, readMessages };
  };

  render() {
    const { isProcessing } = this.props;
    const { popupVisible } = this.state;
    const noticeData = this.getNoticeData();
    const { unreadMessages, readMessages } = this.sortNoticeData(noticeData);
    return (
      <NoticeIcon
        className="action"
        count={unreadMessages.length}
        onItemClick={(item) => {
          this.changeReadState(item);
        }}
        loading={isProcessing}
        clearText="Löschen"
        viewMoreText="Schließen"
        onClear={this.handleNoticeDelete}
        onViewMore={this.handleClose}
        onPopupVisibleChange={this.onPopupVisibleChange}
        popupVisible={popupVisible}
        clearClose
      >
        {/* <NoticeIcon.Tab
            tabKey="notification"
            count={unreadMsg.notification}
            list={noticeData.notification}
            title="Benachrichtigung"
            emptyText="Sie haben alle Benachrichtigungen angesehen."
            showViewMore
          /> */}
        <NoticeIcon.Tab
          tabKey="unread"
          count={unreadMessages.length}
          list={unreadMessages}
          title="Ungelesen"
          emptyText="Sie haben keine neuen Mitteilungen."
          showViewMore
        />
        <NoticeIcon.Tab
          tabKey="read"
          count={readMessages.length}
          list={readMessages}
          title="Gelesen"
          emptyText="Sie haben alle Mitteilungen gelesen."
          showViewMore
        />
      </NoticeIcon>
    );
  }
}

const mapDispatchToProps = { fetchNotices, changeNoticeReadState, deleteNotices };
const mapStateToProps = (state) => ({
  notices: getNotices(state),
  error: getError(state),
  isProcessing: getProcessing(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeaderRight);
