import { Avatar, List } from 'antd';
import React from 'react';
import classNames from 'classnames';
import './NoticeList.less';

const NoticeList = ({ data = [], onClick, onClear, title, onViewMore, emptyText, showClear = true, clearText, viewMoreText, showViewMore = false }) => {
  if (!data || data.length === 0) {
    return (
      <div className="notFound" style={{ padding: '73px 0 88px', textAlign: 'center' }}>
        <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="not found" style={{ display: 'inline-block', height: '76px', marginBottom: '16px' }} />
        <div>{emptyText}</div>
      </div>
    );
  }

  return (
    <div className="global-header">
      <List
        className="ant-list"
        dataSource={data}
        renderItem={(item, i) => {
          const itemCls = classNames('ant-item', {
            ['read']: item.read,
          }); // eslint-disable-next-line no-nested-ternary

          const leftIcon = item.avatar ? typeof item.avatar === 'string' ? <Avatar className="avatar" src={item.avatar} /> : <span className="iconElement">{item.avatar}</span> : null;
          return (
            <List.Item
              className={itemCls}
              key={item.key || i}
              onClick={() => {
                onClick?.(item);
              }}
            >
              <List.Item.Meta
                className="ant-meta"
                avatar={leftIcon}
                title={
                  <div className="ant-title">
                    {item.title}
                    <div className="ant-extra">{item.extra}</div>
                  </div>
                }
                description={
                  <div>
                    <div className="ant-description">{item.desc}</div>
                    <div className="ant-datetime">{item.datetime}</div>
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <div className="bottomBar">
        {showClear ? <div onClick={onClear}>{clearText}</div> : null}
        {showViewMore ? (
          <div
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {viewMoreText}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NoticeList;
