import SettingsView from './components/SettingsView';
import { connect } from 'react-redux';

import { getProfile, updateProfile, updatePassword, unloadProfilePage, updateAvatar, removeError } from '../../store/actions/profiles.actions';
import { getCurrentProfile, getError, getProcessing, getProcessed } from '../../store/selectors/profiles.selectors';

const mapDispatchToProps = {
  unloadProfilePage,
  updatePassword,
  updateProfile,
  updateAvatar,
  getProfile,
  removeError,
};

const mapStateToProps = (state) => {
  return {
    profile: getCurrentProfile(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
