import AuthenticationLayout from './AuthenticationLayout';
import { connect } from 'react-redux';
import { getIsSignedIn, getProcessing } from '../../store/selectors';

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
    isProcessing: getProcessing(state),
  };
};

export default connect(mapStateToProps)(AuthenticationLayout);
