import React from 'react';
import { GridContent } from '@ant-design/pro-layout';
import { Row, Col, Card } from 'antd';

import Heizkreis from '../../../../../components/Metr/heizkreis';
import EMS from '../../../../../components/Metr/ems';
import EMSPower from '../../../../../components/Metr/ems-power';
import Warmwasser from '../../../../../components/Metr/warmwasser';
import Speicherladung from '../../../../../components/Metr/speicherladung';

export default class DashforgeDemo extends React.Component {
  render() {
    return (
      <GridContent>
        <Row gutter={24}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} style={{ marginBottom: 24 }} title="Regelung - EMS">
              <EMS />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} style={{ marginBottom: 24 }} title="Power - EMS">
              <EMSPower />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} style={{ marginBottom: 24 }} title="Warmwasser">
              <Warmwasser />
            </Card>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} style={{ marginBottom: 24 }} title="Speicherladung">
              <Speicherladung />
            </Card>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} style={{ marginBottom: 24 }} title="Heizkreis">
              <Heizkreis />
            </Card>
          </Col>
        </Row>
      </GridContent>
    );
  }
}
