import axios from 'axios';
import superagent from 'superagent';
import { notification } from 'antd';

//import config from '../../config';

export const bimServerApi = axios.create({
  // TODO add v2 backend urls
  // baseURL: `${config.server.publicUrl}/api/v2`,
});

let token;

const codeMessage = {
  200: 'Der Server hat die angeforderten Daten erfolgreich zurückgegeben.',
  201: 'Neue oder geänderte Daten waren erfolgreich.',
  202: 'Ein Auftrag wurde im Hintergrund in die Warteschlange gestellt (asynchroner Task).',
  204: 'Das Löschen der Daten war erfolgreich.',
  400: 'Es gab einen Fehler in der ausgehenden Anfrage und der Server hat keine neue oder geänderte Datenoperation durchgeführt.',
  401: 'Der Benutzer hat keine Berechtigung (falsches Token, Benutzername, Passwort).',
  403: 'Der Benutzer ist autorisiert, aber der Zugriff ist deaktiviert.',
  404: 'Es wurde eine Anfrage für einen Datensatz gestellt, der nicht existiert, und der Server hat keine Aktion durchgeführt.',
  406: 'Das Format der Anfrage war nicht verfügbar.',
  410: 'Die angeforderte Ressource wurde dauerhaft gelöscht und wird nicht mehr verfügbar sein.',
  422: 'Beim Erstellen eines Objekts ist ein Validierungsfehler aufgetreten.',
  500: 'Auf dem Server ist ein Fehler aufgetreten, bitte überprüfen Sie den Server.',
  502: 'Gateway-Fehler.',
  503: 'Der Dienst ist nicht verfügbar, der Server ist vorübergehend überlastet oder wird gewartet.',
  504: 'Gateway-Zeitüberschreitung.',
};

export const errorHandler = (error) => {
  console.log(error);
  const { response } = error;
  if (response && response.status) {
    const { status } = response;
    if (status === 401 && (response.status === 'jwt expired' || response.statusText === 'jwt-expired')) {
      const errorText = codeMessage[response.status] || response.statusText;

      notification.error({
        message: `${status} : ${response.data.error.message || ''}`,
        description: errorText,
        duration: 10,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } else if (!response) {
    notification.error({
      description: 'Ihr Netzwerk ist anormal und Sie können keine Verbindung zum Server herstellen',
      message: 'Netzwerkanomalie',
    });
  }
  return response;
};

// Listen to all requests,
// Return response without any actions
// Erros are allways displayed and returned
bimServerApi.interceptors.response.use(
  (response) => response,
  (error) => {
    error.handleGlobally = errorHandler(error);
    return Promise.reject(error);
  },
);

bimServerApi.setAuthToken = (jwtToken) => {
  token = jwtToken;
  bimServerApi.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
};

bimServerApi.uploadFile = (url, file, opts = {}) => {
  return new Promise((resolve, reject) => {
    const req = superagent.post(url).set('Authorization', `Bearer ${token}`);
    req.on('progress', (e) => {
      if (opts.progress) {
        opts.progress(e.percent);
      }
    });

    req.attach(opts.tag || 'file', file);

    if (opts.data) {
      console.log(opts.data);
      for (var key in opts.data) {
        req.field(key, opts.data[key]);
      }
    }

    req.end((err, response) => {
      if (err) {
        return reject(err);
      }

      resolve(response);
    });
  });
};
