import _ from 'lodash';

const envConfig = {
  env: process.env.NODE_ENV,
  server: {
    publicUrl: window.location.origin,
  },
  matterport: {
    sdkKey: process.env.REACT_APP_MATTERPORT_SDK_KEY,
    sdkVersion: process.env.REACT_APP_MATTERPORT_SDK_VERSION,
  },
  mapbox: {
    token: process.env.REACT_APP_MAPBOX_TOKEN,
  },
};
var config = {};

if (envConfig.env === 'development') {
  const devConfig = require('./config.dev');
  config = devConfig.devConfig;
} else if (envConfig.env === 'production') {
  const prodConfig = require('./config.prod');
  config = prodConfig.prodConfig;
}

const routesConfig = require('./config.routes');

config = _.merge({}, config, envConfig, routesConfig);
export default config;
