import ViewerConfigurator from './Viewer.Configurator';
import { connect } from 'react-redux';

import { getModel, unloadViewerPage } from '../../../store/actions/models.actions';
import { setViewerEnv, setViewerProfile } from '../../../store/actions/viewer.actions';
import { getCurrentModel, getError, getProcessing, getProcessed } from '../../../store/selectors/models.selectors';

const mapDispatchToProps = {
  getModel,
  unloadViewerPage,
  setViewerEnv,
  setViewerProfile,
};
const mapStateToProps = (state) => {
  return {
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    dbModel: getCurrentModel(state),
    viewerEnv: state.viewer.env,
    profile: state.viewer.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerConfigurator);
