import React from 'react';
import { Input, Alert, Statistic, Card, Steps, Divider, Tag, Result, Button, Typography, notification, Modal } from 'antd';
import { PageContainer, PageLoading } from '@ant-design/pro-layout';
import { CloseCircleOutlined } from '@ant-design/icons';

import ServiceManager from '../../../services/SvcManager';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

import './ModelUploaderView.less';

const { Paragraph, Text } = Typography;
const { Step } = Steps;
const { confirm } = Modal;

class ModelUploaderView extends React.Component {
  formRef = React.createRef();
  constructor() {
    super();

    this.socketSvc = ServiceManager.getService('SocketSvc');
    this.modelSvc = ServiceManager.getService('ModelSvc');

    this.onForgeUploadError = this.onForgeUploadError.bind(this);
    this.onForgeUploadProgress = this.onForgeUploadProgress.bind(this);
    this.onForgeTranslateProgress = this.onForgeTranslateProgress.bind(this);
    this.onForgeTranslateError = this.onForgeTranslateError.bind(this);

    this.state = {
      rootFilename: null,
      uploadProgress: 0,
      translationProgress: 0,
      defaultFileList: [],
      onProcessError: undefined,
      onProcessSuccess: undefined,
      uploadError: false,
      translateError: false,
      modelId: null,
      file: undefined,
      options: undefined,
    };
  }

  componentDidMount() {
    if (this.props.project == null) {
      const projectId = this.props.match.params.projectId;
      this.props.getProject(projectId);
    } else {
      this.props.setActiveProject(this.props.project);
    }

    this.socketSvc.on('upload.error', this.onForgeUploadError);
    this.socketSvc.on('upload.progress', this.onForgeUploadProgress);
    this.socketSvc.on('svf.progress', this.onForgeTranslateProgress);
    this.socketSvc.on('svf.error', this.onForgeTranslateError);
  }
  componentWillUnmount() {
    this.props.unloadUploadPage();
    this.socketSvc.off('upload.error', this.onForgeUploadError);
    this.socketSvc.off('upload.progress', this.onForgeUploadProgress);
    this.socketSvc.off('svf.progress', this.onForgeTranslateProgress);
    this.socketSvc.off('svf.error', this.onForgeTranslateError);
  }
  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */
  get formLayout() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 19,
      },
    };
  }
  onUploadProgress(data) {
    // This is the upload to my server
    // This is model is uploaded to my server (50%) afte that
    // the model is uploaded to Forge (onForgeUuploadProgress) 50%+

    if (data.percent) {
      data.progress = data.percent / 2;
    }
    // Data Object contains
    //    progress
    //    chunkIndex
    //    filename: file.originalname,
    //    bucketKey,
    //    objectKey,
    //    uploadId,
    this.props.setUploadProgress(data);
  }
  onForgeUploadProgress(data) {
    if (data.progress) {
      data.progress = 50.0 + data.progress / 2;
    }
    if (data.progress >= 99) {
      this.props.setCurrentStep(2);
    }
    this.props.setUploadProgress(data);
  }
  onForgeUploadError(msg) {
    const { onProcessError } = this.state;
    const error = new Error(msg.error);

    this.setState({
      uploadError: true,
    });

    onProcessError({ error });
  }
  onForgeTranslateProgress(data) {
    // Data Object contains:
    //  filename
    //  progress
    //  jobId
    // modelId
    const progress = data.progress;
    data.progress = parseInt(progress.replace(/"/g, '').replace(/%/g, ''));
    if (data.progress >= 99) {
      this.props.setCurrentStep(3);
    }
    this.props.setTranslationProgress(data);
  }
  onForgeTranslateError(msg) {
    this.setState({
      translateError: true,
    });

    notification.error({
      message: `${msg.statuscode} : ${msg.statusMessage || ''}`,
      description: `${msg.statusBody}`,
      duration: 10,
    });
  }
  handleFileUpload = (e) => {
    this.setState({
      defaultFileList: e.fileList,
    });
  };
  onChange = (info) => {
    const { status } = info.file;
    this.setState({
      defaultFileList: info.fileList,
    });
    // if (status !== "uploading") {
    //   console.log(info.file, info.fileList);
    // }
    // if (status === "done") {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === "error") {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  };

  onRemove = () => {
    // reset states
    this.setState({
      rootFilename: null,
      uploadProgress: 0,
      translationProgress: 0,
      defaultFileList: [],
      onProcessError: undefined,
      onProcessSuccess: undefined,
      uploadError: false,
      translateError: false,
      modelId: null,
    });
  };

  // Triggerd when File is selected
  customRequest = async (option) => {
    const { onSuccess, onError, file, onProgress } = option;
    this.setState({
      onProcessError: onError,
      onProcessSuccess: onSuccess,
      uploadError: false,
      translateError: false,
      uploadProgress: 0,
      translationProgress: 0,
      modelId: null,
    });

    const composite = await this.isComposite(file);
    const uploadId = this.guid();
    const socketId = await this.socketSvc.getSocketId();
    const projectId = this.props.project.id;
    const data = Object.assign(
      {
        socketId,
        uploadId,
        projectId,
      },
      !!composite
        ? {
            rootFilename: composite,
          }
        : null,
    );
    const options = {
      progress: (percent) => {
        this.onUploadProgress({
          uploadId,
          percent,
          file,
        });
      },
      data,
    };
    // Save file and options to store
    this.props.setUploadData(file, options);
  };
  // Triggered when Contine Button is pressed
  onContinue = (formValues) => {
    const { uploadData } = this.props;
    const { file, options } = uploadData;
    if (options.data.name) {
      options.data.name = formValues['fileName'];
    }

    // Get Upload Data and add predifined Filename by user
    // Upload
    this.props.uploadFile(file, options);
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  guid(format = 'xxxxxxxxxx') {
    var d = new Date().getTime();

    var guid = format.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });

    return guid;
  }
  onRootFilenameChanged(e) {
    this.setState({
      rootFilename: e.target.value,
    });
  }
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  isComposite(file) {
    return new Promise((resolve) => {
      const filename = file.name;

      const rootFilename = filename.substring(0, filename.length - 4);

      if (filename.endsWith('.zip')) {
        const onOk = () => {
          return resolve(this.state.rootFilename || rootFilename);
        };
        this.setState({
          rootFilename: null,
        });

        confirm({
          title: 'Verpacktes Modell',
          content: (
            <>
              <div>Sie laden eine .zip Datei hoch. Geben Sie bitte den Namen der "Hauptdatei" im verpackten Zustand an (z.B. _Car Seats.iam).</div>
              <Input
                placeholder={`Root Filename: ${rootFilename}`}
                onChange={(e) => this.onRootFilenameChanged(e)}
                onPressEnter={(e) => this.onKeyDown(e)}
              />
            </>
          ),
          onOk() {
            onOk();
          },
        });
      } else {
        resolve(false);
      }
    });
  }

  get extraContent() {
    return;
    return (
      <div className="extraContent">
        <div className="statItem">
          <Statistic title="Aktive Modelle" loading={false} value="test" />
        </div>
        <div className="statItem">
          <Statistic title="Verfügbare Uploads" loading={false} value="test" />
        </div>
      </div>
    );
  }
  getTags = (project) => {
    var tag = [];
    tag.push(
      <Tag key="active" color="green">
        Online
      </Tag>,
    );
    return {
      tags: tag,
    };
  };

  render() {
    const { defaultFileList, uploadProgress, translationProgress, uploadError, translateError, modelId } = this.state;
    const { project, currentUser, error, isProcessing, currentStep } = this.props;

    if (project == null) {
      return <PageLoading />;
    }
    const stepComponent = [
      <Step1
        {...this.props}
        customRequest={this.customRequest}
        onRemove={this.onRemove}
        handleFileUpload={this.handleFileUpload}
        onContinue={this.onContinue}
      />,
      <Step2 {...this.props} />,
      <Step3 {...this.props} />,
      <Step4 {...this.props} />,
    ];

    return (
      <PageContainer
        className="pageHeader"
        ghost={false}
        header={this.getTags(project)}
        content={`Alle hier hochgeladen Dateien werden dem Project '${project.name}' zugeordnet`}
        extraContent={this.extraContent}
        title="Uploader"
        onBack={() => window.history.back()}
      >
        {error && (
          <>
            <Alert showIcon message="Fehler" description={this.props.error} type="error" />
            <Divider />
          </>
        )}
        <Card bordered={false}>
          <Steps current={this.props.currentStep} className="steps">
            <Step title="Datei auswählen" />
            <Step title="Hochladen" />
            <Step title="Verarbeiten" />
            <Step title="Anschauen" />
          </Steps>
          {stepComponent[this.props.currentStep]}
          {uploadError && (
            <Result
              status="error"
              title="Upload fehlgeschlagen"
              subTitle="Prüfen Sie die Fehlermeldung und kontaktieren Sie den Support. Löschen Sie vor dem erneuten Hochladen Ihren fehlgeschlagenen Upload."
              extra={[
                <Button type="primary" key="console" onClick={() => this.props.history.push('/contact')}>
                  Support kontaktieren
                </Button>,
              ]}
            >
              <div className="desc">
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Es sind eine oder mehrere der folgenden Fehler aufgetreten:
                  </Text>
                </Paragraph>
                <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" /> Sie sind nicht berechtigt, ein Modell hochzuladen.
                </Paragraph>
                <Paragraph>
                  <CloseCircleOutlined className="site-result-demo-error-icon" /> Sie haben die maximale Anzahl von Uploads für Ihr Konto erreicht.
                </Paragraph>
              </div>
            </Result>
          )}
          {translateError && (
            <Result
              status="error"
              title="Übersetzung fehlgeschlagen"
              subTitle="Übersetzung fehlgeschlagen, bitte kontaktieren Sie den Support."
              extra={[
                <Button type="primary" key="console" onClick={() => this.props.history.push('/contact')}>
                  Support kontaktieren
                </Button>,
              ]}
            />
          )}
        </Card>
      </PageContainer>
    );
  }
}

export default ModelUploaderView;
