import * as actionTypes from './types';

export const getModels = (query = '') => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_MODELS });
  return bimServerApi.get(`/api/models/${query}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_MODELS_SUCCESS, payload: response.data.models });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_MODELS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const getModel = (modelId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_MODEL });
  return bimServerApi.get(`/api/models/${modelId}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_MODEL_SUCCESS, payload: response.data.model });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_MODEL_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const registerModel = (formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.REGISTER_MODEL });
  return bimServerApi.post(`/api/models/`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.REGISTER_MODEL_SUCCESS, payload: response.data.model });
    },
    (err) => {
      dispatch({
        type: actionTypes.REGISTER_MODEL_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateModel = (modelId, formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_MODEL });
  return bimServerApi.put(`/api/models/${modelId}`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_MODEL_SUCCESS });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_MODEL_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const deleteModel = (modelId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.DELETE_MODEL });
  return bimServerApi.delete(`/api/models/${modelId}`).then(
    (response) => {
      dispatch({ type: actionTypes.DELETE_MODEL_SUCCESS });
    },
    (err) => {
      dispatch({
        type: actionTypes.DELETE_MODEL_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const setActiveModel = (model) => {
  return {
    type: actionTypes.SET_ACTIVE_MODEL,
    payload: model,
  };
};

export const unloadModelPage = () => {
  return {
    type: actionTypes.UNLOAD_MODEL_PAGE,
  };
};
export const unloadViewerPage = () => {
  return {
    type: actionTypes.UNLOAD_VIEWER_PAGE,
  };
};
