import ShowroomView from './components/ShowroomView';
import { connect } from 'react-redux';

import { getShowrooms, unloadShowroomPage, setActiveTab } from '../../store/actions/showroom.actions';
import { getProject, setActiveProject } from '../../store/actions/projects.actions';

import { getCurrentShowrooms, getError, getProcessing, getProcessed, getActiveTab } from '../../store/selectors/showroom.selectors';
import { getCurrentProject } from '../../store/selectors/projects.selectors';

const mapDispatchToProps = {
  getShowrooms,
  setActiveTab,
  unloadShowroomPage,
  getProject,
  setActiveProject,
};
const mapStateToProps = (state) => {
  return {
    showrooms: getCurrentShowrooms(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    activeTab: getActiveTab(state),
    project: getCurrentProject(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowroomView);
