import * as actionTypes from '../actions/types';

const INITIAL_STATE = {
  supportedFormats: [],
  processing: false,
  processed: false,
  error: null,
  currentStep: 0,
  uploadProgress: null,
  translationProgress: null,
  uploadData: null,
};

const uploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SUPPORTED_FORMATS:
    case actionTypes.GET_SUPPORTED_FORMATS_SUCCESS:
      return {
        ...state,
        supportedFormats: action.payload,
      };
    case actionTypes.GET_SUPPORTED_FORMATS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.UPLOAD_PROGRESS_CHANGED:
      return {
        ...state,
        uploadProgress: action.payload,
      };
    case actionTypes.TRANSLATION_PROGRESS_CHANGED:
      return {
        ...state,
        translationProgress: action.payload,
      };
    case actionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case actionTypes.SET_UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.payload,
      };
    case actionTypes.UNLOAD_UPLOAD_PAGE:
      return {
        ...state,
        currentStep: 0,
        error: null,
        processed: false,
        processing: false,
        supportedFormats: [],
        uploadProgress: 0,
        uploadData: null,
      };
    default:
      return state;
  }
};
export default uploadReducer;
