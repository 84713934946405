import MatterportView from './components/MatterportView';
import { connect } from 'react-redux';

import { getCurrentScan } from '../../store/selectors';
import { getCurrentProject } from '../../store/selectors/projects.selectors';
import { getProject, setActiveProject } from '../../store/actions/projects.actions';

const mapDispatchToProps = {
  getProject,
  setActiveProject,
};

const mapStateToProps = (state) => {
  return {
    scan: getCurrentScan(state),
    project: getCurrentProject(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatterportView);
