import DashboardView from './compontents/DashboardView';
import { connect } from 'react-redux';

import { getProjects, setActiveProject } from '../../store/actions/projects.actions';
import { getOrganisation } from '../../store/actions/organisations.actions';
import { getCurrentProjects, getError, getProcessing, getProcessed } from '../../store/selectors/projects.selectors';
import { getCurrentOrganisation, getCurrentOrganisationUsers } from '../../store/selectors/organisations.selectors';
import { getCurrentUser } from '../../store/selectors/auth.selectors';

const mapDispatchToProps = {
  getProjects,
  getOrganisation,
  setActiveProject,
};
const mapStateToProps = (state) => {
  return {
    projects: getCurrentProjects(state),
    organisation: getCurrentOrganisation(state),
    users: getCurrentOrganisationUsers(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    currentUser: getCurrentUser(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
