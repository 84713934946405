import React from 'react';

import ProjectsView from './Projects/ProjectsView';
import ProjectView from './Project/ProjectView';

export default class ProjectHomeView extends React.Component {
  componentWillUnmount() {
    this.props.unloadProjectPage();
  }
  // componentWillUnmount() {
  //   this.props.unloadProjectPage();
  // }

  render() {
    this.projectId = this.props.match.params.projectId;
    const view = this.projectId ? <ProjectView projectId={this.projectId} {...this.props} /> : <ProjectsView {...this.props} />;

    return <div className="project-view">{view}</div>;
  }
}
