import React from 'react';
import _ from 'lodash';

import { Modal, Form, Input, Checkbox, Select } from 'antd';
import { bimServerApi, errorHandler } from '../../../store/apis/bim.server.api';

const { Option } = Select;

export default class ModelRootModal extends React.Component {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      data: [],
      value: undefined,
    };
  }

  handleSearch = (value) => {
    if (value) {
      this.fetchProjects(value, (data) => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };
  handleSearchChange = (value) => {
    this.setState({ value });
  };
  fetchProjects = (value, callback) => {
    bimServerApi.get(`/api/projects/?name=${value}`).then(
      (response) => {
        callback(response.data.projects);
      },
      (err) => {
        errorHandler(err);
      },
    );
  };

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }

  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (fieldsValue) => {
    if (this.props.onSubmit) {
      delete fieldsValue['objectId'];
      Object.keys(fieldsValue).map((key) => {
        if (fieldsValue[key] === undefined) {
          delete fieldsValue[key];
        }
      });
      let model = {
        bucketKey: fieldsValue['bucketKey'],
        objectKey: fieldsValue['objectKey'],
        fileId: fieldsValue['fileId'],
        urn: fieldsValue['urn'],
      };
      Object.keys(model).map((key) => {
        if (model[key] === undefined) {
          delete model[key];
        }
      });
      delete fieldsValue['bucketKey'];
      delete fieldsValue['objectKey'];
      delete fieldsValue['fileId'];
      delete fieldsValue['urn'];
      let formValues;
      if (_.isEmpty(model)) {
        formValues = {
          ...fieldsValue,
        };
      } else {
        formValues = {
          ...fieldsValue,
          model,
        };
      }

      let layout = {
        type: fieldsValue['type'],
        flex: fieldsValue['flex'],
      };
      Object.keys(layout).map((key) => {
        if (layout[key] === undefined) {
          delete layout[key];
        }
      });
      delete formValues['type'];
      delete formValues['flex'];
      if (!_.isEmpty(layout)) {
        formValues = {
          ...formValues,
          layout,
        };
      }
      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */

  getModalContent() {
    const { current, deletion } = this.props;
    const { data } = this.state;
    let options;
    if (!_.isEmpty(data)) {
      options = data.map((d) => <Option key={d.id}>{d.name}</Option>);
    } else {
      options = [];
    }

    let disabled;
    if (deletion) {
      disabled = true;
    }

    var initialValues = {};
    if (current) {
      initialValues = {
        ['name']: current.name,
        ['env']: current.env,
        ['public']: current.public,
        ['bucketKey']: current.model.bucketKey,
        ['objectKey']: current.model.objectKey,
        ['fileId']: current.model.fileId,
        ['urn']: current.model.urn,
        ['type']: current.layout.type,
        ['flex']: current.layout.flex,
        ['owner']: current.owner,
        ['project']: current.project ? current.project.name : undefined,
        ['objectId']: current.id,
      };
    }
    return (
      <Form {...this.formLayout} onFinish={this.handleFinish} ref={this.formRef} initialValues={initialValues} style={{ padding: 10 }}>
        <Form.Item name="name" label="Name">
          <Input placeholder="Name" disabled={disabled} />
        </Form.Item>
        <Form.Item name="public" label="Public" valuePropName="checked">
          <Checkbox defaultChecked={initialValues['public']}>Public</Checkbox>
        </Form.Item>
        <Form.Item label="BucketKey" name="bucketKey">
          <Input placeholder="BucketKey" disabled={disabled} defaultValue="florolftest" />
        </Form.Item>
        <Form.Item label="ObjectKey" name="objectKey">
          <Input placeholder="Object Key" disabled={disabled} />
        </Form.Item>
        <Form.Item label="FileId" name="fileId">
          <Input placeholder="File ID" disabled={disabled} />
        </Form.Item>
        <Form.Item label="URN" name="urn">
          <Input placeholder="URN" disabled={disabled} />
        </Form.Item>
        <Form.Item label="Enviroment" name="env">
          <Select defaultValue={initialValues['env']} disabled={disabled}>
            <Option value="AutodeskProduction">AutodeskProduction</Option>
            <Option value="Local">Local</Option>
          </Select>
        </Form.Item>
        <Form.Item label="LayoutType" name="type">
          <Select disabled={disabled}>
            <Option value="flexLayoutRight">FlexLayoutRight</Option>
            <Option value="flexLayoutLeft">FlexLayoutLeft</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Flex" name="flex">
          <Input placeholder="Flex" disabled={disabled} />
        </Form.Item>
        <Form.Item
          name="project"
          label="Project ID"
          hasFeedback
          rules={[
            {
              required: false,
              message: 'Bitte geben Sie eine ProjektID an!',
            },
          ]}
        >
          <Select
            showSearch
            value={this.state.value}
            placeholder="Project"
            //style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            notFoundContent="keine passender Eintrag gefunden."
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item name="objectId" label="Object ID">
          <Input placeholder="Object Id " disabled={true} />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { deletion, visible, current, onDelete } = this.props;
    var modalFooter;
    if (!deletion) {
      modalFooter = current
        ? {
            okText: 'Update',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          }
        : {
            okText: 'Speichern',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          };
    } else {
      modalFooter = {
        okText: 'Löschen',
        okButtonProps: { danger: true },
        onOk: onDelete,
        onCancel: this.handleCancel,
      };
    }

    return (
      <Modal
        title={`Model ${current ? (deletion ? 'löschen' : 'bearbeiten') : 'hinzufügen'}`}
        className="standardListForm"
        width={640}
        bodyStyle={{ padding: '28px 0 0' }}
        destroyOnClose
        visible={visible}
        {...modalFooter}
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}
