import * as actionTypes from './types';

export const unloadUploadPage = () => {
  return {
    type: actionTypes.UNLOAD_UPLOAD_PAGE,
  };
};
export const setCurrentStep = (currentStep) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.SET_CURRENT_STEP, payload: currentStep });
};
export const setUploadProgress = (progress) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPLOAD_PROGRESS_CHANGED, payload: progress });
};
export const setTranslationProgress = (progress) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.TRANSLATION_PROGRESS_CHANGED, payload: progress });
};

export const setUploadData = (file, options) => (dispatch, getState, { bimServerApi }) => {
  const data = { file, options };
  dispatch({ type: actionTypes.SET_UPLOAD_DATA, payload: data });
};

export const uploadFile = (file, opts = {}) => (dispatch, getState, { bimServerApi }) => {
  const options = Object.assign(
    {},
    {
      tag: 'model',
    },
    opts,
  );
  return bimServerApi.uploadFile('/api/forge/upload', file, options).then(
    (response) => {
      console.log(response);
    },
    (err) => {
      console.log(err);
    },
  );
};

export const getSupportedFormats = () => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_SUPPORTED_FORMATS });
  return bimServerApi.get('/api/forge/formats').then(
    (response) => {
      dispatch({ type: actionTypes.GET_SUPPORTED_FORMATS_SUCCESS, payload: response.data.formats });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_SUPPORTED_FORMATS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
