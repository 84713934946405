import { createSelector } from 'reselect';

export const getUploadState = (state) => state.upload;

export const getFormats = createSelector(getUploadState, (upload) => upload.supportedFormats);
export const getError = createSelector(getUploadState, (upload) => upload.error);
export const getCurrentStep = createSelector(getUploadState, (upload) => upload.currentStep);
export const getUploadData = createSelector(getUploadState, (upload) => upload.uploadData);
export const getUploadProgress = createSelector(getUploadState, (upload) => upload.uploadProgress);
export const getTranslationProgress = createSelector(getUploadState, (upload) => upload.translationProgress);
