import React from 'react';
import { Button, Tag, Card, List, Dropdown, Menu, Input, Avatar, Alert, Divider, Typography, Modal, Space, Result } from 'antd';
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  PlusOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import Image from '../../../../../components/Image';
import ScanModal from '../../Modal/ScanModal';

import { connect } from 'react-redux';
import { getScans, unloadScanPage, updateScan } from '../../../../../store/actions/scans.actions';
import { getCurrentScans, getError, getProcessing, getProcessed } from '../../../../../store/selectors/scans.selectors';
import config from '../../../../../config';

const { confirm } = Modal;
const { Paragraph, Text } = Typography;

class ProjectScans extends React.Component {
  addBtn = React.createRef();

  constructor() {
    super();

    moment.locale('de', momentDe);

    this.state = {
      visible: false,
      current: undefined,
    };
  }

  /* ========================================================================== */
  /*                              Lifecyle Methods                              */
  /* ========================================================================== */
  componentDidMount() {
    this.props.getScans(`?project=${this.props.projectId}&sort=-createdAt`);
  }
  componentWillUnmount() {
    this.props.unloadScanPage();
  }

  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  handleSubmit = async (formValues) => {
    const { current } = this.state;
    this.setState({
      visible: false,
    });
    await this.props.updateScan(current.id, formValues);
    await this.props.getScans(`?project=${this.props.projectId}`);
  };
  showShareModal = (item) => {
    let title;
    let content;
    if (item.public) {
      title = 'Öffentlichen Link entfernen?';
      content = <p>Der Scan ist nicht mehr über den öffentlichen Link verfügbar.</p>;
    } else {
      title = 'Öffentlichen Link generieren?';
      content = (
        <>
          <p>Jeder mit diesem Link kann den Scan anschauen.</p>
          <Space />
          <Paragraph
            copyable={{
              text: `${config.server.publicUrl}/realviewer/${item.id}`,
              tooltips: ['Link kopieren.', 'Link kopiert!'],
            }}
          >
            <Text code>
              {config.server.publicUrl}/realviewer/{item.id}
            </Text>
          </Paragraph>
        </>
      );
    }
    const onOk = async () => {
      await this.props.updateScan(item.id, { public: !item.public });
      await this.props.getScans(`?project=${this.props.projectId}`);
    };

    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: content,
      okText: 'Bestätigen',
      cancelText: 'Abbrechen',
      onOk() {
        onOk();
      },
      onCancel() {},
    });
  };

  /* ========================================================================== */
  /*                                List Content                                */
  /* ========================================================================== */
  listContent = (item) => {
    if (item.password) {
      return (
        <div className="listContent">
          <div className="listContentItem">
            <span>
              <Tag color="red">Passwort geschützt</Tag>
            </span>
            <p>
              <Input.Password
                value={item.password}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                contentEditable={false}
              />
            </p>
          </div>
        </div>
      );
    }
  };

  /* ========================================================================== */
  /*                               Paginator Props                              */
  /* ========================================================================== */
  get paginationProps() {
    const { scans } = this.props;
    return {
      showSizeChanger: true,
      showQuickJumper: true,
      pageSize: 10,
      total: Array.isArray(scans) ? scans.length : 0,
    };
  }

  render() {
    const { project, error, isProcessing, currentUser, scans } = this.props;
    const { visible, current } = this.state;

    if (!project.default && currentUser.subscription === 'test') {
      return (
        <Result
          icon={<LockOutlined />}
          title="Produkt nicht verfügbar"
          subTitle="Führen Sie ein Upgrad Ihrer Subscription durch um dieses Produkt nutzen zu können."
          extra={
            <Link to="">
              <Button type="primary">Support</Button>
            </Link>
          }
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Hinweis:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined className="site-result-demo-error-icon" /> Für dieses Produkt benötigen Sie die Subscription <a>Basic</a> oder
              höher.
            </Paragraph>
          </div>
        </Result>
      );
    }

    const MoreBtn = ({ key, disabled, item }) => (
      <Dropdown
        disabled={this.props.disabledActions}
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => this.showModal(item)}>
              Bearbeiten
            </Menu.Item>
            <Menu.Item key="share" onClick={() => this.showShareModal(item)}>
              Teilen
            </Menu.Item>
            <Menu.Item key="delete" disabled={true} danger={true}>
              Löschen
            </Menu.Item>
          </Menu>
        }
      >
        <a>
          Mehr <DownOutlined />
        </a>
      </Dropdown>
    );

    return (
      <div className="standardList">
        <Card className="listCard" bordered={false} title="Scans" style={{ marginTop: 24 }}>
          <Button
            className="addNewModelButton"
            type="dashed"
            style={{ width: '100%', marginBottom: 8 }}
            // onClick={this.uploadNewModelClicked}
            //ref={this.addBtn}
            disabled={true}
          >
            <PlusOutlined />
            3D Scan anfordern...
          </Button>
          {error && (
            <>
              <Alert showIcon message="Fehler" description={this.props.error} type="error" />
              <Divider />
            </>
          )}
          <List
            size="large"
            rowKey="id"
            loading={isProcessing}
            pagination={this.paginationProps}
            dataSource={scans !== null ? scans : []}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Link to={`/projects/active/${this.props.projectId}/realviewer/${item.id}`}>Anschauen</Link>,
                  <MoreBtn disabled={this.props.disabledActions} item={item} />,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Image
                      src={item.thumbnailSrc ? item.thumbnailSrc : `data:image/png;base64,${item.cover}`}
                      shape="square"
                      size="large"
                      width={100}
                    />
                  }
                  title={
                    <>
                      <Link to={`/projects/active/${this.props.projectId}/realviewer/${item.id}`}>{item.name}</Link>
                      <Divider type="vertical" />
                      {item.public && (
                        <>
                          <Tag color="blue">Öffentlich</Tag>
                          <Divider type="vertical" />
                          <Paragraph
                            copyable={{
                              text: `${config.server.publicUrl}/realviewer/${item.id}`,
                              tooltips: ['Öffentlichen Link kopieren.', 'Link kopiert!'],
                            }}
                            style={{ display: 'inline-block' }}
                          />
                        </>
                      )}
                    </>
                  }
                  description={`Erstellt: ${moment(item.createdAt).format('DD.MM.YYYY HH:mm')}`}
                />
                {this.listContent(item)}
              </List.Item>
            )}
          />
        </Card>
        <ScanModal visible={visible} scan={current} onCancel={this.handleCancel} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getScans,
  unloadScanPage,
  updateScan,
};
const mapStateToProps = (state) => {
  return {
    scans: getCurrentScans(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectScans);
