import ProjectHomeView from './components/ProjectHomeView';
import { connect } from 'react-redux';

import { getProjects, getProject, registerProject, updateProject, deleteProject, updateCover, setActiveProject, setActiveTab, unloadProjectPage } from '../../store/actions/projects.actions';
import { getCurrentProjects, getCurrentProject, getError, getProcessing, getProcessed, getActiveTab } from '../../store/selectors/projects.selectors';
import { getCurrentUser } from '../../store/selectors/auth.selectors';

const mapDispatchToProps = {
  getProjects,
  getProject,
  registerProject,
  updateProject,
  deleteProject,
  updateCover,
  setActiveProject, 
  unloadProjectPage,
  setActiveTab,
};
const mapStateToProps = (state) => {
  return {
    projects: getCurrentProjects(state),
    project: getCurrentProject(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    currentUser: getCurrentUser(state),
    activeTab: getActiveTab(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHomeView);
