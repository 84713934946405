import React from 'react';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';

import { Typography, Button, Space, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import DropOption from '../../components/DropOption';
import ServiceManager from '../../services/SvcManager';
import Image from '../../components/Image';

import moment from 'moment';
import momentDe from 'moment/locale/de';

import { bimServerApi, errorHandler } from '../../store/apis/bim.server.api';

import ModelModal from './Modal/ModelModal';

const { Paragraph, Text } = Typography;
export default class ModelsView extends React.Component {
  tableRef = React.createRef();
  constructor() {
    super();

    moment.locale('de', momentDe);
    this.modelSvc = ServiceManager.getService('ModelSvc');
    this.state = {
      visible: false,
      deletion: false,
      current: undefined,
      selectedRows: [],
    };
  }

  /* ========================================================================== */
  /*                                    Modal                                   */
  /* ========================================================================== */

  showModal = () => {
    this.setState({
      visible: true,
      current: undefined,
    });
  };
  showEditModal = (item) => {
    this.setState({
      visible: true,
      current: item,
    });
  };
  showDeleteModal = (item) => {
    this.setState({
      visible: true,
      current: item,
      deletion: true,
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      deletion: false,
    });
  };

  handleSubmit = (formValues) => {
    const { current } = this.state;
    if (current) {
      this.updateModel(formValues);
    } else {
      this.registerModel(formValues);
    }
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDelete = () => {
    this.deleteModel(this.state.current);
    this.setState({
      visible: false,
      deletion: false,
    });
  };
  handleDeleteItems = (users) => {
    users.map((user) => {
      this.deleteModel(user);
    });
    this.tableRef.current?.reloadAndRest?.();
  };

  deleteModel = (current) => {
    bimServerApi.delete(`/api/models/${current.id}`).then(
      (response) => {
        message.success(`Löschen erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  registerModel = (formValues) => {
    bimServerApi.post('/api/models/', formValues).then(
      (response) => {
        message.success(`Registration erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };
  updateModel = (formValues) => {
    console.log(formValues);
    const { current } = this.state;
    bimServerApi.put(`/api/models/${current.id}`, formValues).then(
      () => {
        message.success(`Update erfolgreich...`);
        this.tableRef.current?.reloadAndRest?.();
      },
      (err) => {
        errorHandler(err);
        this.tableRef.current?.reloadAndRest?.();
      },
    );
  };

  /* ========================================================================== */
  /*                               Get Thumbanils                               */
  /* ========================================================================== */
  getThumbnailUrl(model) {
    const thumbnailUrl = this.modelSvc.getThumbnailUrl(model.urn, 400);
    return thumbnailUrl;
  }

  /* ========================================================================== */
  /*                           DropOption Handle Click                          */
  /* ========================================================================== */

  handleMenuClick = (record, e) => {
    switch (e.key) {
      case '1':
        //Edit
        this.showEditModal(record);
        break;
      case '2':
        //Delete
        this.showDeleteModal(record);
        break;
    }
  };

  get columns() {
    return [
      {
        title: 'Thumbnail',
        dataIndex: 'model',
        key: 'model',
        width: 100,

        search: false,
        render: (model) => <Image className="test" src={this.getThumbnailUrl(model)} width={50} height={50} shape="square" />,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        copyable: true,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return (
            <Button type="link" onClick={() => this.props.history.push(`models/viewer/${record.id}`)}>
              {_}
            </Button>
          );
        },
      },
      {
        title: 'Projekt',
        dataIndex: 'project',
        key: 'project',
        copyable: true,
        renderFormItem: (_, { defaultRender }) => {
          return defaultRender(_);
        },
        render: (_, record) => {
          return record.project ? `${record.project.name}` : `-`;
        },
      },
      // {
      //   title: 'Project Id',
      //   dataIndex: 'project',
      //   key: 'project',
      //   renderFormItem: (_, { defaultRender }) => {
      //     return defaultRender(_);
      //   },
      //   render: (_, record) => {
      //     return (
      //       <Paragraph
      //         copyable={{
      //           text: record.project ? `${record.project._id}` : `-`,
      //           tooltips: ['kopieren.', 'kopiert!'],
      //         }}
      //       >
      //         <Text code>{record.project ? `${record.project._id}` : `-`}</Text>
      //       </Paragraph>
      //     );
      //   },
      // },
      {
        title: 'Public',
        dataIndex: 'public',
        filters: true,
        onFilter: true,
        valueType: 'select',
        valueEnum: {
          true: {
            text: 'True',
            status: 'Success',
          },
          false: {
            text: 'False',
            status: 'Error',
          },
        },
      },
      {
        title: 'Erstellt',
        key: 'createdAt',
        dataIndex: 'createdAt',
        valueType: 'date',
        search: false,
        sorter: (a, b) => {
          const aa = moment(a.createdAt);
          const bb = moment(b.createdAt);
          return aa - bb;
        },
      },
      {
        title: 'Object Id',
        dataIndex: 'id',
        key: 'id',
        copyable: true,
        search: false,
      },
      {
        title: 'Action',
        valueType: 'option',
        fixed: 'right',
        render: (text, record, _, action) => [
          <DropOption
            onMenuClick={(e) => this.handleMenuClick(record, e)}
            menuOptions={[
              { key: '1', name: `Bearbeiten`, icon: <EditOutlined /> },
              {
                key: '2',
                name: `Löschen`,
                icon: <DeleteOutlined />,
                danger: true,
              },
            ]}
          />,
        ],
      },
    ];
  }

  render() {
    const { selectedRows, visible, current, deletion } = this.state;
    return (
      <PageContainer>
        <ProTable
          actionRef={this.tableRef}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          columns={this.columns}
          headerTitle={
            <Space>
              <span>Model Table</span>
            </Space>
          }
          rowSelection={{
            onChange: (_, selectedRows) => this.setState({ selectedRows: selectedRows }),
          }}
          toolBarRender={() => [
            <Button key="button" icon={<PlusOutlined />} type="primary" onClick={() => this.showModal()}>
              Add
            </Button>,
          ]}
          request={(params, sort, filter) => {
            delete params['current'];
            delete params['pageSize'];
            const queryString = Object.keys(params)
              .map((key) => key + '=' + params[key])
              .join('&');

            return new Promise(async (resolve, reject) => {
              bimServerApi.get(`/api/models/?${queryString}`).then(
                (response) => {
                  resolve({ data: response.data.models, success: true });
                },
                (err) => {
                  reject({ data: null, success: false });
                },
              );
            });
          }}
        />
        {selectedRows?.length > 0 && (
          <FooterToolbar
            extra={
              <div>
                Ausgewählt: <a style={{ fontWeight: 600 }}>{selectedRows.length}</a> Modelle
              </div>
            }
          >
            <Button
              type="primary"
              onClick={async () => {
                await this.handleDeleteItems(selectedRows);
                this.setState({ selectedRows: [] });
              }}
            >
              Löschen
            </Button>
          </FooterToolbar>
        )}
        <ModelModal
          deletion={deletion}
          current={current}
          visible={visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          onDelete={this.handleDelete}
        />
      </PageContainer>
    );
  }
}
