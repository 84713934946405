import React from 'react';
import { Form, Input, Button, Alert, message } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn, unloadAuthPage } from '../../store/actions/auth.actions';
import { getProcessing, getError } from '../../store/selectors/auth.selectors';

import './SignIn.less';

class SignIn extends React.Component {
  componentDidMount() {
    if (this.props.locationState) {
      message.success(this.props.locationState.message, [10]);
      this.props.history.replace();
    }
  }

  componentWillUnmount() {
    this.props.unloadAuthPage();
  }
  /* ========================================================================== */
  /*                                  On Finish                                 */
  /* ========================================================================== */

  onFinish = (formValues) => {
    this.props.signIn(formValues);
  };

  render() {
    return (
      <div className="signin-view">
        <Form onFinish={this.onFinish} name="login" className="login-form">
          <h3 style={{ color: 'white' }}>Login</h3>

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Dies ist keine gülte Email-Adresse!',
              },
              {
                required: true,
                message: 'Bitte geben Sie Ihre Email ein!',
              },
            ]}
          >
            <Input size="large" prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input size="large" prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Link className="login-form-forgot" to="/user/request-password-reset">
              Passwort vergessen?
            </Link>
            <Button loading={this.props.isProcessing} size="large" type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
            <Link className="login-form-register" to="/user/signup">
              Keinen Account? Jetzt registrieren!
            </Link>
          </Form.Item>
          {this.props.errorMessage &&
            (this.props.errorMessage !== 'E-Mail ist nicht verifiziert. Bitte verifizieren Sie zuerst Ihre E-Mail' ? (
              <Alert showIcon message="Fehler" description={this.props.errorMessage} type="error" />
            ) : (
              <Alert
                showIcon
                message="Fehler"
                description="E-Mail ist nicht verifiziert. Haben Sie keine Verifizierungs-E-Mail erhalten?"
                type="error"
                action={
                  <Button size="small" danger onClick={() => this.props.history.push('/user/request-verfification-email')}>
                    Hier klicken
                  </Button>
                }
              />
            ))}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isProcessing: getProcessing(state),
    errorMessage: getError(state),
    locationState: state.router.location.state,
  };
};

export default connect(mapStateToProps, {
  signIn,
  unloadAuthPage,
})(SignIn);
