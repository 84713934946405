import React from 'react';
import ProLayout from '@ant-design/pro-layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, Route } from 'react-router-dom';

import logo from '../../logo.svg';
import config from '../../config';

class PublicLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <HelmetProvider>
        <Helmet>
          <title>{config.app.title}</title>
          <meta name="description" content={config.app.desc} />
        </Helmet>
        <ProLayout logo={logo} collapsed={true}>
          {children}
        </ProLayout>
      </HelmetProvider>
    );
  }
}

export default class PublicLayoutRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <PublicLayout>
            <Component {...matchProps} />
          </PublicLayout>
        )}
      />
    );
  }
}
