import PublicMatterportView from './PublicMatterportViewer';
import { connect } from 'react-redux';
import { getCurrentScan } from '../../../store/selectors';

const mapStateToProps = (state) => {
  return {
    scan: getCurrentScan(state),
  };
};

export default connect(mapStateToProps)(PublicMatterportView);
