import { createSelector } from 'reselect';

export const getProjectsState = (state) => state.projects;

export const getProcessing = createSelector(getProjectsState, (projects) => projects.processing);
export const getProcessed = createSelector(getProjectsState, (projects) => projects.processed);
export const getError = createSelector(getProjectsState, (projects) => projects.error);
export const getCurrentProjects = createSelector(getProjectsState, (projects) => projects.projects);
export const getCurrentProject = createSelector(getProjectsState, (projects) => projects.project);
export const getActiveTab = createSelector(getProjectsState, (projects) => projects.activeTab);
