import PublicForgeViewer from './PublicForgeViewer';
import { connect } from 'react-redux';
import { getCurrentModel } from '../../../store/selectors';

const mapStateToProps = (state) => {
  return {
    model: getCurrentModel(state),
  };
};
export default connect(mapStateToProps)(PublicForgeViewer);
