import React from 'react';
import { Modal, Form, Input } from 'antd';

export default class ModelModal extends React.Component {
  formRef = React.createRef();
  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    if (this.props.onSubmit) {
      this.props.onSubmit(formValues);
    }
  };
  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  getModalContent() {
    const { model } = this.props;
    if (!model) {
      return;
    }
    return (
      <Form
        {...this.formLayout}
        ref={this.formRef}
        style={{ padding: 10 }}
        onFinish={this.handleFinish}
        initialValues={{
          ['name']: model.name,
        }}
      >
        <Form.Item name="name" label="Name">
          <Input placeholder="Name" />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { visible, model } = this.props;
    if (!model) {
      return <></>;
    }

    const modalFooter = {
      okText: 'Bearbeiten',
      onOk: this.handleSubmit,
      onCancel: this.handleCancel,
    };
    return (
      <>
        <Modal title={`Modell '${model.name}' bearbeiten`} className="standardListForm" width={640} bodyStyle={{ padding: '28px 0 0' }} destroyOnClose visible={visible} {...modalFooter}>
          {this.getModalContent()}
        </Modal>
      </>
    );
  }
}
