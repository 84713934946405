import React from 'react';
import { Card, List, Tag, Avatar, Statistic, Alert, Popconfirm, message, Divider } from 'antd';
import { PageContainer } from '@ant-design/pro-layout';
import moment from 'moment';
import momentDe from 'moment/locale/de';

export default class ProjectArchivedView extends React.Component {
  constructor() {
    super();

    moment.locale('de', momentDe);
  }

  componentDidMount() {
    this.props.getProjects('?archived=true');
  }

  /* ========================================================================== */
  /*                                   Confirm                                  */
  /* ========================================================================== */

  handleDeleteProject = async (item) => {
    await this.props.deleteProject(item.id);
    await this.props.getProjects('?archived=true');
  };

  handleActivateProject = async (item) => {
    const value = {
      archived: false,
    };
    await this.props.updateProject(item.id, value);
    await this.props.getProjects('?archived=true');
  };

  /* ========================================================================== */
  /*                             render avatar list                             */
  /* ========================================================================== */

  renderAvatarList = (item) => {
    if (item.members) {
      return item.members.map((member, i) => <Avatar key={`${member}-${i}`} src={this.props.auth.defaultAvatar.src} tips={member} />);
    } else {
      return <Avatar key={item._id} src={this.props.auth.defaultAvatar.src} />;
    }
  };

  /* ========================================================================== */
  /*                            render project items                            */
  /* ========================================================================== */
  renderProjectItems = (item) => {
    if (item && item.id) {
      return (
        <List.Item key={item.id}>
          <Card
            className="card"
            hoverable
            cover={<img alt="img" className="cardAvatar" src={`data:image/png;base64,${item.cover}`} />}
            actions={[
              <Popconfirm title="Projekt aktivieren?" onConfirm={() => this.handleActivateProject(item)}>
                <a>
                  {/* <InteractionOutlined key="activate" /> */}
                  Aktivieren
                </a>
              </Popconfirm>,
              <Popconfirm title="Projekt löschen?" onConfirm={() => this.handleDeleteProject(item)}>
                <a>
                  {/* <DeleteOutlined key="delete" /> */}
                  Löschen
                </a>
              </Popconfirm>,
            ]}
          >
            <Card.Meta title={item.name} description={<a>{item.desc}</a>} />
            <div className="cardItemContent">
              <span>{moment(item.createdAt).fromNow()}</span>
              <div className="cardTag">
                <Tag color="blue" key="tag">
                  Archiviert
                </Tag>
              </div>
            </div>
          </Card>
        </List.Item>
      );
    }
  };

  extraContent = (isProcessing, projects) => {
    return (
      <div className="extraContent">
        <div className="statItem">
          <Statistic title="Archivierte Projekte" loading={isProcessing} value={projects !== null ? projects.length : 0} />
        </div>
      </div>
    );
  };

  render() {
    const { projects, isProcessing, error } = this.props;
    return (
      <PageContainer extra={this.extraContent(isProcessing, projects)}>
        <div className="project-cardList">
          {error && (
            <>
              <Alert showIcon message="Fehler" description={this.props.error} type="error" />
              <Divider />
            </>
          )}
          <List
            rowKey="id"
            loading={isProcessing}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 5,
            }}
            dataSource={projects !== null ? [...projects] : []}
            renderItem={(item) => this.renderProjectItems(item)}
            style={{ minHeight: '700px' }}
          />
        </div>
      </PageContainer>
    );
  }
}
