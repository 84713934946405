import BaseSvc from './BaseSvc';

export default class ModelSvc extends BaseSvc {
  constructor(config) {
    super(config);

    this.api = config.api;
  }

  name() {
    return 'ModelSvc';
  }

  getThumbnailUrl(urn, size = 200) {
    const url = `/api/forge/thumbnail/${urn}?size=${size}`;
    return url;
  }
  upload(file, opts = {}) {
    const url = '/api/forge/upload';

    const options = Object.assign(
      {},
      {
        tag: 'model',
      },
      opts,
    );
    console.log(file);
    console.log(options);
    //return this.api.uploadFile(url, file, options);
  }
}
