import React from 'react';
import { Form, Input, Button, Alert, message } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signUp, unloadAuthPage } from '../../store/actions/auth.actions';
import { getRedirectToPath, getError } from '../../store/selectors/auth.selectors';
import ReCAPTCHA from 'react-google-recaptcha';

import './Signup.less';

class SignUp extends React.Component {
  recaptchaRef = React.createRef();
  formRef = React.createRef();

  componentDidMount() {
    if (this.props.location.search) {
      const search = this.props.location.search.split('=');
      this.token = search[1];
    }
  }

  componentWillUnmount() {
    this.props.unloadAuthPage();
  }

  /* ========================================================================== */
  /*                               Validate Password                             */
  /* ========================================================================== */

  checkPassword = (rule, value, callback) => {
    if (value.length < 6) {
      callback('Das Passwort muss mindestens 6 Stellen lang sein!');
    }
    callback();
  };

  /* ========================================================================== */
  /*                                  On Finish                                 */
  /* ========================================================================== */

  onFinish = (formValues) => {
    const recaptchaValue = this.recaptchaRef.current.getValue();
    if (recaptchaValue === '') {
      message.error(`ReCAPTCHA Bestätigung notwendig.`);
    } else {
      delete formValues['confirm'];
      delete formValues['recaptcha'];
      if (this.token !== undefined) {
        const verifyToken = this.token;
        formValues = {
          ...formValues,
          verifyToken,
        };
      }
      console.log(formValues);
      this.props.signUp(formValues);
    }
  };

  render() {
    if (this.props.redirectToPath != null) {
      this.props.history.push(this.props.redirectToPath, { message: 'Eine Bestätigungs-E-Mail wurde an Ihre E-Mail gesendet.' });
    }
    return (
      <div className="signup-view">
        <Form ref={this.formRef} name="register" className="register-form" onFinish={this.onFinish}>
          <h3 style={{ color: 'white' }}>Registrieren</h3>
          <Form.Item
            name="firstName"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihren Vornamen ein!',
                whitespace: true,
              },
            ]}
          >
            <Input size="large" prefix={<UserOutlined />} placeholder="Vorname" />
          </Form.Item>
          <Form.Item
            name="lastName"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihren Nachname ein!',
                whitespace: true,
              },
            ]}
          >
            <Input size="large" prefix={<UserOutlined />} placeholder="Nachname" />
          </Form.Item>
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              {
                type: 'email',
                message: 'Dies ist keine gülte Email-Adresse!',
              },
              {
                required: true,
                message: 'Bitte geben Sie Ihre Email ein!',
              },
            ]}
          >
            <Input size="large" prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte Ihr Passwort eingeben!',
              },
              {
                validator: this.checkPassword,
              },
            ]}
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Passwort" />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte wiederholen Sie ihr Passwort!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject('Die Passwörter stimmen nicht überein!');
                },
              }),
            ]}
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Passwort wiederholen" />
          </Form.Item>
          <Form.Item name="recaptcha">
            <ReCAPTCHA sitekey="6LdxkNIZAAAAAMc3kS1kR8RaZAcREIgQPEo6oG99" ref={this.recaptchaRef} />
          </Form.Item>
          <Form.Item>
            <Button size="large" className="submit" type="primary" htmlType="submit">
              Registrieren
            </Button>
            <Link className="login" to="/user/signin">
              Sie haben bereits ein Konto?
            </Link>
          </Form.Item>
          {this.props.errorMessage && <Alert showIcon message="Fehler" description={this.props.errorMessage} type="error" />}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    redirectToPath: getRedirectToPath(state),
    errorMessage: getError(state),
  };
};

export default connect(mapStateToProps, { signUp, unloadAuthPage })(SignUp);
