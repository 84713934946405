import React from 'react';

import { PageContainer } from '@ant-design/pro-layout';
import { Card, Divider, List, Tag, Alert, Button, Typography } from 'antd';
import { StarOutlined, LikeOutlined, MessageOutlined, ScanOutlined, DingtalkOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Link as RouterLink } from 'react-router-dom';

import Image from '../../../components/Image';
import config from '../../../config';

import ArticleListContent from './ArticleListContent';

import './ShowroomView.less';

const { Text, Link } = Typography;

export default class ShowroomView extends React.Component {
  constructor() {
    super();
    this.state = {
      pageSize: 5,
      list: [],
    };
  }

  componentDidMount() {
    const { activeTab } = this.props;
    this.props.getShowrooms(`?category=${activeTab}`);
  }
  componentWillUnmount() {
    this.props.unloadShowroomPage();
  }

  getDescription = () => {
    return (
      <div className="pageHeaderContent" style={{ position: 'relative' }}>
        <Text>
          Hier finden Sie eine Sammlung an Beispielen wie BIMforge über den Standard hinaus eingesetzt werden kann. Für weitere Informationen
          kontaktieren Sie den <Link href="mailto:support@bimension.de?subject=Anfrage%20über%20BIMforge"> Support </Link>.
        </Text>
      </div>
    );
  };
  getTags = (project) => {
    var tag = [];
    tag.push(
      <Tag key="active" color="blue">
        Aktiv
      </Tag>,
    );

    return { tags: tag };
  };
  onTabChange = (key) => {
    this.props.getShowrooms(`?category=${key}`);
    this.props.setActiveTab(key);
  };

  get tabList() {
    return [
      {
        key: 'Task-Forge',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Task-Forge</p>
            <Divider type="vertical" />
            <ScanOutlined />
          </div>
        ),
      },
      {
        key: 'Twin-Forge',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Twin-Forge</p>
            <Divider type="vertical" />
            <DingtalkOutlined />
          </div>
        ),
      },
      {
        key: 'Dash-Forge',
        tab: (
          <div>
            <p style={{ display: 'inline-block' }}>Dash-Forge</p>
            <Divider type="vertical" />
            <DatabaseOutlined />
          </div>
        ),
      },
    ];
  }

  onItemClicked = async (item) => {
    await this.props.getProject(item.projectId);
    await this.props.setActiveProject(this.props.project);
  };

  render() {
    const { isProcessing, showrooms, error, activeTab } = this.props;

    const IconText = ({ type, text }) => {
      switch (type) {
        case 'star-o':
          return (
            <span>
              <StarOutlined style={{ marginRight: 8 }} />
              {text}
            </span>
          );
        case 'like-o':
          return (
            <span>
              <LikeOutlined style={{ marginRight: 8 }} />
              {text}
            </span>
          );
        case 'message':
          return (
            <span>
              <MessageOutlined style={{ marginRight: 8 }} />
              {text}
            </span>
          );
        default:
          return null;
      }
    };

    return (
      <PageContainer
        content={this.getDescription()}
        header={this.getTags()}
        tabList={this.tabList}
        tabActiveKey={activeTab}
        onTabChange={(key) => this.onTabChange(key)}
      >
        {error && (
          <>
            <Alert showIcon message="Fehler" description={this.props.error} type="error" />
            <Divider />
          </>
        )}
        <Card style={{ marginTop: 24 }} bordered={false} bodyStyle={{ padding: '8px 32px 32px 32px' }}>
          <List
            size="large"
            rowKey="id"
            itemLayout="vertical"
            loading={isProcessing}
            dataSource={showrooms !== null ? [...showrooms] : []}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <IconText key="star" type="star-o" text={item.star} />,
                  <RouterLink
                    to={
                      activeTab === 'Task-Forge'
                        ? `/projects/active/${item.projectId}/realviewer/${item.modelId}`
                        : `/projects/active/${item.projectId}/viewer/${item.modelId}`
                    }
                  >
                    <Button type="primary" onClick={() => this.onItemClicked(item)}>
                      Ausprobieren
                    </Button>
                  </RouterLink>,
                ]}
                extra={<Image src={`${item.src}`} style={{ maxWidth: 440 }} preview={true} />}
              >
                <List.Item.Meta
                  title={
                    <RouterLink
                      to={
                        activeTab === 'Task-Forge'
                          ? `/projects/active/${item.projectId}/realviewer/${item.modelId}`
                          : `/projects/active/${item.projectId}/viewer/${item.modelId}`
                      }
                      onClick={() => this.onItemClicked(item)}
                    >
                      <a className="listItemMetaTitle" href={item.href}>
                        {item.title}
                      </a>
                    </RouterLink>
                  }
                  description={item.tags.map((tag) => {
                    var color;
                    if (tag === 'Produktion') {
                      color = 'green';
                    } else if (tag === 'Test') {
                      color = 'yellow';
                    } else if (tag === 'Entwicklung') {
                      color = 'red';
                    }

                    return (
                      <span>
                        <Tag color={color}>{tag}</Tag>
                      </span>
                    );
                  })}
                />
                <ArticleListContent data={item} />
              </List.Item>
            )}
          />
        </Card>
      </PageContainer>
    );
  }
}
