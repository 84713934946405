import * as actionTypes from './types';

export const fetchNotices = () => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.FETCH_NOTICES });
  return bimServerApi.get('/api/notices/').then(
    (response) => {
      dispatch({ type: actionTypes.FETCH_NOTICES_SUCCESS, payload: response.data.notices });
    },
    (err) => {
      dispatch({ type: actionTypes.FETCH_NOTICES_FAIL, payload: err.response.data.error.message });
    },
  );
};

export const changeNoticeReadState = (state, noticeId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_NOTICE });
  return bimServerApi.put(`/api/notices/${noticeId}`, state).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_NOTICE_SUCCESS, payload: response.data.notice });
    },
    (err) => {
      dispatch({ type: actionTypes.UPDATE_NOTICE_FAIL, payload: err.response.data.error.message });
    },
  );
};

export const deleteNotices = () => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.DELETE_NOTICES });
  return bimServerApi.delete('/api/notices/').then(
    (response) => {
      dispatch({ type: actionTypes.DELETE_NOTICES_SUCCESS, payload: response.data.message });
    },
    (err) => {
      dispatch({ type: actionTypes.DELETE_NOTICES_FAIL, payload: err.response.data.error.message });
    },
  );
};
