import React from 'react';
import { PageContainer, RouteContext, GridContent } from '@ant-design/pro-layout';
import { Button, Row, Col, Descriptions, Card, Alert, Divider, Statistic, Avatar, Tag, List, Empty } from 'antd';
import { HomeOutlined, ContactsOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import momentDe from 'moment/locale/de';

import Image from '../../../components/Image';
import config from '../../../config';
import './DashobardView.less';
const { Meta } = Card;

export default class DashboardView extends React.Component {
  constructor() {
    super();
    moment.locale('de', momentDe);
  }

  componentDidMount() {
    this.props.getProjects('?archived=false&default=false&limit=2&sort=-createdAt');
    this.props.getOrganisation();
  }

  renderRecentProjects = (item) => {
    if (item && item.id && item.visible) {
      return (
        <List.Item key={item.id}>
          <Link
            to={`/projects/active/${item.id}`}
            onClick={() => {
              this.props.setActiveProject(item);
            }}
          >
            <Card className="card" hoverable cover={<img alt="img" className="cardAvatar" src={`data:image/png;base64,${item.cover}`} />}>
              <Card.Meta title={item.name} description={<a>{item.desc}</a>} />
              <div className="cardItemContent">
                <span>{moment(item.createdAt).fromNow()}</span>
                {!item.default ? (
                  <div className="avatarList">{/* <Avatar.Group size="small">{this.renderAvatarList(item)}</Avatar.Group> */}</div>
                ) : (
                  <div className="cardTag">
                    <Tag color="red" key="tag">
                      Beispielprojekt
                    </Tag>
                  </div>
                )}
              </div>
            </Card>
          </Link>
        </List.Item>
      );
    }
  };

  getSubscriptionTitle(subscription) {
    switch (subscription) {
      case 'test':
        return 'Testaccount';
      case 'basic':
        return 'Standard';
      case 'professional':
        return 'Professional';
      case 'premium':
        return 'Premium';
    }
  }
  getRoleTitle(role) {
    switch (role) {
      case 'user':
        return 'User';
      case 'admin':
        return 'Admin';
      case 'root':
        return 'Root';
    }
  }
  renderUserInfo = (currentOrganisation) => {
    return (
      <div className="detail">
        <p>
          <ContactsOutlined
            style={{
              marginRight: 8,
            }}
          />
          {currentOrganisation.google ? (
            <a href={currentOrganisation.google} target="_blank">
              {currentOrganisation.address ? currentOrganisation.address : 'Nicht festgelegt'}
            </a>
          ) : currentOrganisation.address ? (
            currentOrganisation.address
          ) : (
            'Nicht festgelegt'
          )}
        </p>
        <p>
          <HomeOutlined
            style={{
              marginRight: 8,
            }}
          />
          {currentOrganisation.website ? (
            <a href={currentOrganisation.website} target="_blank">
              {currentOrganisation.website ? currentOrganisation.website : 'Nicht festgelegt'}
            </a>
          ) : currentOrganisation.website ? (
            currentOrganisation.website
          ) : (
            'Nicht festgelegt'
          )}
        </p>
      </div>
    );
  };

  pageHeaderContent = (currentUser) => {
    return (
      <RouteContext.Consumer>
        {({ isMobile }) => {
          return (
            <div className="pageHeaderContent">
              <div className="avatar">
                <Avatar size="large" src={`data:image/jpeg;base64,${currentUser.avatar}`} />
              </div>
              <div className="content">
                <div className="contentTitle">
                  Hallo {currentUser.firstName} {currentUser.lastName}
                </div>
                <Descriptions className="headerList" size="small" column={isMobile ? 1 : 2}>
                  <Descriptions.Item label="Email">{currentUser.email}</Descriptions.Item>
                  <Descriptions.Item label="Beigetreten">{moment(currentUser.createdAt).fromNow()}</Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          );
        }}
      </RouteContext.Consumer>
    );
  };
  extraContent = (currentUser) => {
    return (
      <div className="extraContent">
        <div className="statItem">
          <Statistic title="Account Subscription" value={this.getSubscriptionTitle(currentUser.subscription)} />
        </div>
        <div className="statItem">
          <Statistic title="Account Role" value={this.getRoleTitle(currentUser.role)} />
        </div>
      </div>
    );
  };

  render() {
    const { currentUser, projects, error, isProcessing, organisation, users } = this.props;
    const customCss = `
          .ant-btn {
            font-family: fantasy;
          }
          .custom-title {
            color: $primary-color;
          }
          .custom-title:hover {
            color: $primary-hover-color;
            cursor: pointer;
          }
          #custom-id {
            color: $primary-shadow-color;
          }
          `;
    return (
      <div className="dashboard">
        <PageContainer
          className="pageHeader"
          ghost={false}
          content={this.pageHeaderContent(currentUser)}
          extraContent={this.extraContent(currentUser)}
        >
          {error && (
            <>
              <Alert showIcon message="Fehler" description={this.props.error} type="error" />
              <Divider />
            </>
          )}
          <GridContent>
            <Row gutter={24}>
              <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                <Card
                  bordered={false}
                  style={{ marginBottom: 24 }}
                  title="Organisation"
                  extra={[<Link to="/settings/organisation">Organisation</Link>]}
                >
                  {organisation ? (
                    <>
                      <div className="avatarHolder">
                        <img alt="" src={`data:image/png;base64,${organisation.logo}`} />
                        <div className="name">{organisation.name}</div>
                        <div>{organisation.slogan}</div>
                      </div>
                      {this.renderUserInfo(organisation)}
                      <Divider dashed />

                      {/* <TagList tags={currentUser.tags || []} /> */}
                      <Divider style={{ marginTop: 16 }} dashed />
                      <div className="team">
                        <div className="teamTitle">Team</div>
                        <Row gutter={36}>
                          {users &&
                            users.map((item) => (
                              <Col key={item.id} lg={24} xl={12}>
                                <Avatar size="small" src={`data:image/png;base64,${item.avatar}`} /> {item.firstName} {item.lastName}
                              </Col>
                            ))}
                        </Row>
                      </div>
                    </>
                  ) : (
                    <Empty
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 60,
                      }}
                      description={<span>Keiner Organisation zugehörig.</span>}
                    >
                      <Link to="/settings/organisation">
                        <Button type="primary">Jetzt erstellen</Button>
                      </Link>
                    </Empty>
                  )}
                </Card>
              </Col>
              <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                <Card
                  className="project-cardList"
                  style={{ marginBottom: 24 }}
                  title="Zuletzt erstellte Projekte"
                  bordered={false}
                  extra={[<Link to="/projects/active">Projekte</Link>]}
                  bodyStyle={{ padding: 0 }}
                  loading={isProcessing}
                >
                  <List
                    rowKey="id"
                    loading={isProcessing}
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 1,
                      xl: 1,
                      xxl: 2,
                    }}
                    dataSource={projects !== null ? projects : []}
                    renderItem={(item) => this.renderRecentProjects(item)}
                    style={{ padding: 24 }}
                  />
                </Card>
              </Col>
              <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                <Card
                  style={{ marginBottom: 24 }}
                  bordered={false}
                  title="AR-Forge (In Kürze verfügbar)"
                  cover={<Image src={`${config.azure.storageContainer}/images/app.svg`} height={180} preview={false} />}
                  actions={[
                    //<a href="http://google.com" target="_blank">
                    <Image src={`${config.azure.storageContainer}/images/ios_store.png`} style={{ maxWidth: 140 }} preview={false} />,
                    //</a>,
                    //<a>
                    <Image src={`${config.azure.storageContainer}/images/android_store.png`} style={{ maxWidth: 140 }} preview={false} />,
                    //</a>,
                  ]}
                >
                  <Meta
                    title="Hol dir die AR-Forge App"
                    description="Schau dir all deine Modell auch mobil oder über die App an. Mit der Augmented Reality Technologie, also die erweiterte Realität, bietet die App zusätzliche Möglichkeiten der Visualisierung. Hier wird das unmittelbare Bild der Realität vor Ort am Bau mit dem 3D Modell in Echtgröße und an der richtigen Position überlagert. Für iOS und Android bald verfügbar."
                  ></Meta>
                </Card>
                <Card bodyStyle={{ paddingTop: 12, paddingBottom: 12 }} bordered={false} title="Social Media" loading={false}>
                  <div className="members">
                    <Row gutter={48}>
                      {config.socialMedia.list.map((item) => (
                        <Col span={12} key={`members-item-${item.name}`}>
                          <Avatar src={item.src} size="small" />
                          <a className="memberTitle" href={item.href} target="_blank">
                            {item.name}
                          </a>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </GridContent>
        </PageContainer>
      </div>
    );
  }
}
