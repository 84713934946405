import * as actionTypes from './types';

export const getScans = (query = '') => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_SCANS });
  return bimServerApi.get(`/api/scans/${query}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_SCANS_SUCCESS, payload: response.data.scans });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_SCANS_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const getScan = (scanId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_SCAN });
  return bimServerApi.get(`/api/scans/${scanId}`).then(
    (response) => {
      dispatch({ type: actionTypes.GET_SCAN_SUCCESS, payload: response.data.scan });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_SCAN_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const registerScan = (formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.REGISTER_SCAN });
  return bimServerApi.post(`/api/scans/`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.REGISTER_SCAN_SUCCESS, payload: response.data.scan });
    },
    (err) => {
      dispatch({
        type: actionTypes.REGISTER_SCAN_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateScan = (scanId, formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_SCAN });
  return bimServerApi.put(`/api/scans/${scanId}`, formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_SCAN_SUCCESS, payload: response.data.scan });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_SCAN_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const deleteScan = (scanId) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.DELETE_SCAN });
  return bimServerApi.delete(`/api/scans/${scanId}`).then(
    (response) => {
      dispatch({ type: actionTypes.DELETE_SCAN_SUCCESS });
    },
    (err) => {
      dispatch({
        type: actionTypes.DELETE_SCAN_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const setActiveScan = (scan) => {
  return {
    type: actionTypes.SET_ACTIVE_SCAN,
    payload: scan,
  };
};

export const unloadScanPage = () => {
  return {
    type: actionTypes.UNLOAD_SCAN_PAGE,
  };
};

export const unloadRealViewerPage = () => {
  return {
    type: actionTypes.UNLOAD_REALVIEWER_PAGE,
  };
};
