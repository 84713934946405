import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { tryLocalSignIn } from '../store/actions';
import { getIsSignedIn } from '../store/selectors';
/**         **/
/** Layouts **/
/**         **/
import AuthenticationLayoutRoute from '../layout/AuthenticationLayout';
import CoreLayoutRoute from '../layout/CoreLayout';
import BasicLayoutRoute from '../layout/BasicLayout';
import PublicLayout from '../layout/PublicLayout';

/**            **/
/** Components **/
/**            **/

/** Auth **/
import SignIn from './AuthenticationRoutes/SignIn';
import SignUp from './AuthenticationRoutes/SignUp';
import VerfiyEmail from './AuthenticationRoutes/VerfiyEmail';
import RequestVerfificationEmail from './AuthenticationRoutes/RequestVerificationEmail';
import RequestPasswordReset from './AuthenticationRoutes/RequestPasswordReset';
import ResetPassword from './AuthenticationRoutes/ResetPassword';
/** Core  **/
import DashboardView from './Dashboard';
import SettingsView from './Settings';
import ProjectsView from './Projects';
import ArchivedProjectsView from './ArchivedProjects';
import OrganisationView from './Organisation';
import ModelUploaderView from './ModelUploader';
/** Showroom **/
import ShowroomView from './Showroom';

/** Viewer  **/
import ForgeViewer from './ForgeViewer';
import MatterportViewer from './MatterportViewer';

/** NoMatch Routes **/
import NoMatch from './NoMatchRoutes';

/** Public Routes **/
import PublicForgeViewer from './PublicViewer/Forge';
import PublicMatterportViewer from './PublicViewer/Matterport';

/**  Root  **/
import UsersRootView from './RootRoutes/UsersView';
import OrganisationsView from './RootRoutes/OrganisationView';
import ProjectsRootView from './RootRoutes/ProjectsView';
import ModelsRootView from './RootRoutes/ModelsView';
import ScansRootView from './RootRoutes/ScansView';
import NoticesRootView from './RootRoutes/NoticesView';
import TokenRootView from './RootRoutes/TokensView';
import AnchorLocationRootView from './RootRoutes/AnchorLocationView';
import AnchorDataRootView from './RootRoutes/AnchorDataView';
import MongoView from './RootRoutes/MongoView';

class Routes extends React.Component {
  componentDidMount() {
    this.props.tryLocalSignIn();
  }
  render() {
    return (
      <Switch>
        <AuthenticationLayoutRoute exact path="/user/signin" component={SignIn} />
        <AuthenticationLayoutRoute exact path="/user/signup" component={SignUp} />
        <AuthenticationLayoutRoute path="/user/verify-email/:token" component={VerfiyEmail} />
        <AuthenticationLayoutRoute path="/user/reset-password/:token" component={ResetPassword} />
        <AuthenticationLayoutRoute exact path="/user/request-verfification-email" component={RequestVerfificationEmail} />
        <AuthenticationLayoutRoute exact path="/user/request-password-reset" component={RequestPasswordReset} />

        <CoreLayoutRoute exact path="/home" component={DashboardView} />
        <CoreLayoutRoute exact path="/projects/active" component={ProjectsView} />
        <CoreLayoutRoute exact path="/projects/active/:projectId" component={ProjectsView} />
        <CoreLayoutRoute excat path="/projects/active/:projectId/viewer/:modelId" component={ForgeViewer} />
        <CoreLayoutRoute excat path="/projects/active/:projectId/realviewer/:scanId" component={MatterportViewer} />
        <CoreLayoutRoute excat path="/projects/active/:projectId/modeluploader" component={ModelUploaderView} />
        <CoreLayoutRoute exact path="/projects/archived" component={ArchivedProjectsView} />
        <CoreLayoutRoute exact path="/settings/account" component={SettingsView} />
        <CoreLayoutRoute exact path="/settings/organisation" component={OrganisationView} />
        <CoreLayoutRoute exact path="/showroom" component={ShowroomView} />
        <CoreLayoutRoute exact path="/showroom" component={ShowroomView} />

        <CoreLayoutRoute exact path="/root/users" component={UsersRootView} />
        <CoreLayoutRoute exact path="/root/organisations" component={OrganisationsView} />
        <CoreLayoutRoute exact path="/root/projects" component={ProjectsRootView} />
        <CoreLayoutRoute exact path="/root/models" component={ModelsRootView} />
        <CoreLayoutRoute exact path="/root/models/viewer/:modelId" component={ForgeViewer} />
        <CoreLayoutRoute exact path="/root/scans" component={ScansRootView} />
        <CoreLayoutRoute excat path="/root/scans/realviewer/:scanId" component={MatterportViewer} />
        <CoreLayoutRoute exact path="/root/notices" component={NoticesRootView} />
        <CoreLayoutRoute exact path="/root/tokens" component={TokenRootView} />
        <CoreLayoutRoute exact path="/root/anchor/location" component={AnchorLocationRootView} />
        <CoreLayoutRoute exact path="/root/anchor/data" component={AnchorDataRootView} />
        <CoreLayoutRoute exact path="/root/db" component={MongoView} />

        <PublicLayout exact path="/viewer/:modelId" component={PublicForgeViewer} />
        <PublicLayout exact path="/realviewer/:scanId" component={PublicMatterportViewer} />
        {this.props.isSignedIn ? <CoreLayoutRoute path="/" component={NoMatch} /> : <BasicLayoutRoute path="/" component={NoMatch} />}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
  };
};

export default connect(mapStateToProps, { tryLocalSignIn })(Routes);
