import ForgeViewer from './components/ForgeViewer';
import { connect } from 'react-redux';

import { getCurrentModel } from '../../store/selectors';
import { getCurrentProject } from '../../store/selectors/projects.selectors';
import { getProject, setActiveProject } from '../../store/actions/projects.actions';

const mapDispatchToProps = {
  getProject,
  setActiveProject,
};

const mapStateToProps = (state) => {
  return {
    model: getCurrentModel(state),
    project: getCurrentProject(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgeViewer);
