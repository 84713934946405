import React from 'react';

import { Form, Button, Result } from 'antd';
import { Link } from 'react-router-dom';

import './index.less';

export default class Step4 extends React.Component {
  get formLayout() {
    return {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 19,
      },
    };
  }
  render() {
    return (
      <Form {...this.formLayout} layout="horizontal" className="stepForm">
        <Result
          status="success"
          title="Verarbeitung abgeschlossen"
          subTitle="Ihr Modell ist jetzt verfügbar."
          className="result"
          extra={
            <Link to={`/projects/active/${this.props.project.id}/viewer/${this.props.translationProgress.modelId}`}>
              <Button type="primary">Anschauen</Button>
            </Link>
          }
        ></Result>
      </Form>
    );
  }
}
