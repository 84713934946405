import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  profile: null,
  processing: false,
  processed: false,
  error: null,
};

const profilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        processing: false,
        profile: action.payload,
      };
    case actionTypes.GET_PROFILE_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_PROFILE:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        processing: false,
        profile: action.payload,
      };
    case actionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_AVATAR:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        processing: false,
        profile: action.payload,
      };
    case actionTypes.UPDATE_AVATAR_FAIL:
      return {
        ...state,
        processed: false,
        processing: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        processing: true,
        error: null,
      };
    case actionTypes.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        processing: false,
        processed: true,
      };
    case actionTypes.UNLOAD_PROFILE_PAGE:
      return {
        ...state,
        error: null,
        processing: false,
        processed: false,
      };
    case actionTypes.REMOVE_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};

export default profilesReducer;
