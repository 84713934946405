import React, { lazy, Suspense } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import easing from 'easing-js';
import merge from 'lodash/merge';
import { Button, Tooltip } from 'antd';
import { ReactLoader, Loader } from '../../../components/Loader';
import Stopwatch from '../../../components/Stopwatch';
import MatterportViewer from '../Matterport.Viewer';
import BaseComponent from '../../../components/BaseComponent';

export default class MatterportConfigurator extends BaseComponent {
  constructor() {
    super();
    this.state = {
      dataExtension: null,
      viewerPanels: [],
      viewerFlex: 1.0,
      resizing: false,
      controls: [],
      viewerCreated: false,
      sdk: null,
      iframe: null,
      nodes: [],
    };

    this.appContainerRef = React.createRef(this);
  }

  async componentDidMount() {
    this.loader = new Loader(this.loaderContainer);
    this.loader.show(true);
    this.props.getScan(this.props.scanId);

    window.addEventListener('resize', this.onStopResize);
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    this.props.unloadRealViewerPage();
    window.removeEventListener('resize', this.onStopResize);
    window.removeEventListener('resize', this.onResize);

    // Destroys all registed nodes
    const { sdk, nodes } = this.state;
    if (sdk && nodes.length > 0) {
      const nodesToStop = nodes;
      for (const node of nodesToStop) {
        node.stop();
      }
    }
  }

  async onViewerCreated(sdk, iframe) {
    try {
      if (this.props.onViewerCreated) {
        this.props.onViewerCreated(sdk, this.loader);
      }
      this.assignState({
        viewerCreated: true,
        sdk: sdk,
        iframe: iframe,
      });
      await this.setupDynamicExtensions(sdk, iframe);
    } catch (err) {}
  }

  setReflexSplitterButton = (style, props) => {
    return new Promise(async (resolve) => {
      await this.assignState({
        buttonExtStyle: style,
        buttonProps: props,
      });
      resolve();
    });
  };

  showTitle = (showTitle) => {
    return new Promise(async (resolve) => {
      await this.assignState({
        showTitle: showTitle,
      });
      resolve();
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  setupDynamicExtensions(sdk, iframe) {
    const defaultOptions = {
      appContainer: this.appContainerRef,
      dbModel: this.props.dbModel,
      loader: this.loader,
      apiUrl: '/api',
    };

    const createDefaultOptions = (id) => {
      const fullDefaultOptions = Object.assign({}, defaultOptions, {
        react: {
          // formatMessage: this.context.intl.formatMessage,

          pushRenderExtension: this.pushRenderExtension,

          popRenderExtension: this.popRenderExtension,

          forceUpdate: () => {
            return new Promise((resolve) => {
              this.forceUpdate(() => {
                resolve();
              });
            });
          },
          getComponent: () => {
            return this;
          },
          setReflexSplitterButton: this.setReflexSplitterButton,
          showTitle: this.showTitle,
          getState: () => {
            return this.state[id] || {};
          },
          setState: (state, doMerge) => {
            return new Promise((resolve) => {
              const extState = this.state[id] || {};

              const newExtState = {
                [id]: doMerge ? merge({}, extState, state) : Object.assign({}, extState, state),
              };
              this.assignState(newExtState).then(() => {
                resolve(newExtState);
              });
            });
          },
          setSceneNodes: (nodes) => {
            return new Promise((resolve) => {
              this.assignState(nodes).then(() => {
                resolve(nodes);
              });
            });
          },
          props: this.props,
        },
      });

      return fullDefaultOptions;
    };

    sdk.loadDynamicExtension = (id, options = {}) => {
      options.sdk = sdk;
      options.iframe = iframe;
      const fullOptions = merge({}, createDefaultOptions(id), {}, options);

      return this.loadDynamicExtension(sdk, iframe, { id }, fullOptions);
    };

    const dynamicExtensions = this.props.dbModel.dynamicExtensions || [];
    console.log(dynamicExtensions);
    const extensionTasks = dynamicExtensions.map((extension) => {
      // console.log(extension.options);
      return sdk.loadDynamicExtension(extension.id, extension.options || {});
    });
    console.log('LOADING EXTEIONS');
    return Promise.all(extensionTasks);
  }

  loadDynamicExtension(sdk, iframe, extension, options) {
    return new Promise(async (resolve, reject) => {
      import('../Matterport.Extensions.Dynamic/' + extension.id + '/index').then(
        (ext) => {
          const extState = {
            [extension.id]: {},
          };
          console.log(ext);
          console.log(sdk);
          console.log(iframe);
          console.log(extension);
          console.log(options);
          const component = new ext.default(options);
          this.assignState({
            extState,
          }).then(() => {
            resolve(component.initComponents(sdk, iframe));
          });
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  pushRenderExtension = (extension) => {
    return new Promise(async (resolve) => {
      const layout = this.props.dbModel.layout;

      this.viewerFlex = layout ? 1.0 - (layout.flex || 0.3) : 1.0;

      await this.assignState({
        paneExtStyle: { display: 'block' },
      });
      await this.runAnimation(1.0, this.viewerFlex, 1.0);

      setTimeout(() => {
        this.assignState({
          renderExtension: extension,
        }).then(() => {
          resolve();
        });
      }, 250);
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////

  popRenderExtension = () => {
    return new Promise((resolve) => {
      this.assignState({
        renderExtension: null,
      }).then(() => {
        resolve();
      });

      setTimeout(async () => {
        await this.runAnimation(this.viewerFlex, 1.0, 1.0);

        await this.assignState({
          paneExtStyle: { display: 'none' },
        });

        resolve();
      }, 250);
    });
  };

  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  runAnimation(start, end, animPeriod) {
    const easingFn = (t) => {
      //b: begging value, c: change in value, d: duration
      return easing.easeInOutExpo(t, 0, 1.0, animPeriod * 0.9);
    };

    const update = (eased) => {
      const viewerFlex = (1.0 - eased) * start + eased * end;
      return new Promise((resolve) => {
        this.assignState({
          viewerFlex,
        }).then(() => resolve());
      });
    };

    return this.animate(animPeriod, easingFn, update);
  }
  /////////////////////////////////////////////////////////
  //
  //
  /////////////////////////////////////////////////////////
  animate(period, easing, update) {
    return new Promise((resolve) => {
      const stopwatch = new Stopwatch();

      let elapsed = 0;

      const stepFn = () => {
        const dt = stopwatch.getElapsedMs() * 0.001;

        elapsed += dt;

        if (elapsed < period) {
          const eased = easing(elapsed / period);

          update(eased).then(() => {
            window.requestAnimationFrame(stepFn);
          });
        } else {
          update(1.0);

          resolve();
        }
      };

      stepFn();
    });
  }

  onViewerStartResize = (e) => {
    this.setState({
      resizing: true,
    });
  };

  onViewerStopResize = (e) => {
    this.viewerFlex = e.component.props.flex;

    if (this.state.renderExtension) {
      if (this.state.renderExtension.onStopResize) {
        this.state.renderExtension.onStopResize();
      }
    }

    this.setState({
      resizing: false,
    });
  };

  onStopResize = (e) => {
    if (this.state.renderExtension) {
      if (this.state.renderExtension.onStopResize) {
        this.state.renderExtension.onStopResize();
      }
    }
  };

  onResize = (event) => {
    if (this.state.renderExtension) {
      if (this.state.renderExtension.onResize) {
        this.state.renderExtension.onResize();
      }
    }
  };

  renderLoader() {
    return <div className="matterport-configurator-loader" ref={(div) => (this.loaderContainer = div)} />;
  }
  renderModel(dbModel) {
    const { resizing } = this.state;

    const viewerStyle = {
      pointerEvents: resizing ? 'none' : 'all',
    };
    return (
      <MatterportViewer
        dbModel={dbModel}
        // title="sdk-iframe"
        // id="sdk-iframe"
        // className="responsive-iframe"
        onViewerCreated={(sdk, iframe) => {
          this.onViewerCreated(sdk, iframe);
        }}
        onError={this.props.onError}
        panels={this.state.viewerPanels}
        style={viewerStyle}
        showTitle={this.state.showTitle}
        scans={this.state.renderExtension && this.state.renderExtension.scans ? this.state.renderExtension.scans : []}
      />
    );
  }

  renderExtension() {
    const { renderExtension } = this.state;

    const renderOptions = {
      showTitle: true,
      docked: true,
    };
    const content = renderExtension ? this.state.renderExtension.render(renderOptions) : <div />;

    return (
      <div className="data-pane">
        <ReactLoader show={!renderExtension} />
        {content}
      </div>
    );
  }
  render() {
    const { viewerFlex, paneExtStyle, viewerCreated, buttonExtStyle, buttonProps, showTitle } = this.state;
    const { dbModel } = this.props;
    if (!dbModel) {
      return this.renderLoader();
    }

    const layout = dbModel.layout;

    switch (layout ? layout.type : 'none') {
      case 'flexLayoutLeft':
        return (
          <ReflexContainer className="configurator" key="configurator" orientation="vertical">
            <ReflexElement style={paneExtStyle}>{this.renderExtension()}</ReflexElement>
            <ReflexSplitter onStopResize={() => this.forceUpdate()} style={paneExtStyle} />
            <ReflexElement
              onStartResize={this.onViewerStartResize}
              onStopResize={this.onViewerStopResize}
              propagateDimensions={true}
              onResize={this.onResize}
              flex={viewerFlex}
            >
              {this.renderModel(dbModel)}
            </ReflexElement>
          </ReflexContainer>
        );

      case 'flexLayoutRight':
        return (
          <ReflexContainer className="configurator" key="configurator" orientation="vertical">
            <ReflexElement
              onStartResize={this.onViewerStartResize}
              onStopResize={this.onViewerStopResize}
              propagateDimensions={true}
              onResize={this.onResize}
              flex={viewerFlex}
            >
              {this.renderModel(dbModel)}
            </ReflexElement>
            <ReflexSplitter onStopResize={() => this.forceUpdate()} style={paneExtStyle}>
              <Tooltip title="Kameras synchronisieren">
                <Button
                  className="camera-sync-btn"
                  type={buttonProps ? buttonProps.type : 'default'}
                  shape={buttonProps ? buttonProps.shape : 'circle'}
                  size={buttonProps ? buttonProps.size : 'large'}
                  icon={buttonProps ? buttonProps.icon : null}
                  style={buttonExtStyle}
                  onClick={() => (buttonProps ? buttonProps.onClick() : null)}
                />
              </Tooltip>
            </ReflexSplitter>
            <ReflexElement style={paneExtStyle}>{this.renderExtension()}</ReflexElement>
          </ReflexContainer>
        );

      case 'none':
      default:
        return this.renderModel(dbModel);
    }
  }
}
