import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Result } from 'antd';

export default class NoMatch extends React.Component {
  render() {
    const { pathname, isSignedIn } = this.props;
    if (pathname === '/403') {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sie haben keinen Zugriff auf diese Seite."
          extra={
            <Link to="/">
              <Button type="primary">Home</Button>
            </Link>
          }
        />
      );
    } else if (pathname !== '/') {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Die von Ihnen besuchte Seite existiert leider nicht."
          extra={
            <Link to="/">
              <Button type="primary">Home</Button>
            </Link>
          }
        />
      );
    } else {
      return isSignedIn ? <Redirect to="/home" /> : <Redirect to="/user/signin" />;
    }
  }
}
