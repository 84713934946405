import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import authReducer from './auth.reducer';
import noticesReducer from './notices.reducer';
import profilesReducer from './profiles.reducer';
import projectsReducer from './projects.reducer';
import modelsReducer from './models.reducer';
import scansReducer from './scans.reducer';
import organisationReducer from './organisations.reducer';
import viewerReducer from './viewer.reducer';
import uploadReducer from './upload.reducer';
import showroomReducer from './showroom.reducer';

const createRootReducer = (history) => {
  const reducers = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    notices: noticesReducer,
    profile: profilesReducer,
    projects: projectsReducer,
    models: modelsReducer,
    scans: scansReducer,
    organisations: organisationReducer,
    viewer: viewerReducer,
    upload: uploadReducer,
    showrooms: showroomReducer,
  });
  return reducers;
};

export default createRootReducer;
