import MatterportConfigurator from './Matterport.Configurator';

import { connect } from 'react-redux';
import { getScan, unloadRealViewerPage } from '../../../store/actions/scans.actions';
import { getCurrentScan, getError, getProcessing, getProcessed } from '../../../store/selectors/scans.selectors';

const mapDispatchToProps = {
  getScan,
  unloadRealViewerPage,
};
const mapStateToProps = (state) => {
  return {
    error: getError(state),
    isProcessing: getProcessing(state),
    isProcessed: getProcessed(state),
    dbModel: getCurrentScan(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatterportConfigurator);
