const defaultConfig = {
  app: {
    name: 'client',
    title: 'BIMforge',
    desc: 'Digital Transformation',
    copyright: 'BIMension GmbH',
  },
  server: {
    publicUrl: 'This will be overriden by environment variable',
  },
  layout: {
    settings: {
      //navTheme: 'dark',
      //headerTheme: 'light',
      layout: 'side',
      contentWidth: 'Fluid',
      fixedHeader: false,
      //locales: true,
      //fixSiderbar: true,
      menu: {
        locale: false,
        defaultOpenAll: true,
        autoClose: false,
      },
      headerHeight: 48,
      //primaryColor: 'daybreak',
      splitMenus: false,
    },
  },
  azure: {
    storageContainer: 'https://bimensionstorage.blob.core.windows.net/bim-forge-public',
  },
  viewer: {
    js: 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js',
    css: 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css',
  },
  matterport: {
    sdkVersion: 'This will be overriden by environment variable',
    sdkKey: 'This will be overriden by environment variable',
    iframe: 'sdk-iframe',
    script: 'https://static.matterport.com/showcase-sdk/latest.js',
  },
  mapbox: {
    token: 'This will be override by enviroment variable',
    js: 'https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.js',
    css: 'https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css',
  },
  socialMedia: {
    list: [
      {
        name: 'LinkedIn',
        src: `https://bimensionstorage.blob.core.windows.net/bim-forge-public/images/icons8-linkedin.png`,
        href: 'https://www.linkedin.com/company/bimension/',
      },
      {
        name: 'Xing',
        src: `https://bimensionstorage.blob.core.windows.net/bim-forge-public/images/icons8-xing.png`,
        href: 'https://www.xing.com/pages/bimension-gmbh',
      },
      {
        name: 'Youtube',
        src: `https://bimensionstorage.blob.core.windows.net/bim-forge-public/images/icons8-youtube.png`,
        href: 'https://www.youtube.com/channel/UCX7W3yUKCPASzTWKoIMEqSg',
      },
      {
        name: 'Facebook',
        src: `https://bimensionstorage.blob.core.windows.net/bim-forge-public/images/icons8-facebook.png`,
        href: 'https://www.facebook.com/BIMension-GmbH-105355951346746',
      },
      {
        name: 'Instagram',
        src: `https://bimensionstorage.blob.core.windows.net/bim-forge-public/images/icons8-instagram.png`,
        href: 'https://www.instagram.com/bimension_gmbh/',
      },
    ],
  },
};

export default defaultConfig;
