import React from 'react';
import { Line } from '@ant-design/plots';

import { bimServerApi } from '../../store/apis/bim.server.api';

export default class EMS extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
  }

  getData = async () => {
    const res = await bimServerApi.get(`/api/metr/stadt-bau-erlenbach/1903`);
    console.log(res.data.query_result);
    this.setState({
      data: res.data.query_result.data.rows,
    });
  };

  componentDidMount() {
    this.getData();
  }
  render() {
    const { data } = this.state;

    const COLOR_PLATE_10 = ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A', '#6DC8EC', '#9270CA', '#FF9D4D', '#269A99', '#FF99C3'];
    const config = {
      data,
      xField: 'timestamp',
      yField: 'adjusted_value',
      seriesField: 'measurement_name',
      color: COLOR_PLATE_10,
      xAxis: {
        type: 'time',
      },
      legend: {
        position: 'top',
      },
      smooth: true,
      // @TODO 后续会换一种动画方式
      animation: {
        appear: {
          animation: 'path-in',
          duration: 5000,
        },
      },
    };

    return <Line {...config} />;
  }
}
