import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import reduxThunk from 'redux-thunk';

import createRootReducer from './reducers';
import { bimServerApi } from './apis/bim.server.api';

import ServiceManager from '../services/SvcManager';
import EventSvc from '../services/EventSvc';
import SocketSvc from '../services/SocketSvc';
import ModelSvc from '../services/ModelSvc';

import config from '../config';

export const history = createBrowserHistory();

const configureStore = (initialState) => {
  const middleware = [reduxThunk.withExtraArgument({ bimServerApi }), routerMiddleware(history)];
  const composeEnhancer = composeWithDevTools({});

  const store = createStore(createRootReducer(history), initialState, composeEnhancer(applyMiddleware(...middleware)));

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(createRootReducer(history)));
  }

  return store;
};

/* ========================================================================== */
/*                              Service Register                              */
/* ========================================================================== */
const eventSvc = new EventSvc();
const socketSvc = new SocketSvc(config.server);
const modelSvc = new ModelSvc({ api: bimServerApi });

ServiceManager.registerService(eventSvc);
ServiceManager.registerService(socketSvc);
ServiceManager.registerService(modelSvc);

export default configureStore;
