import React from 'react';

import { PageContainer } from '@ant-design/pro-layout';
import { Skeleton } from 'antd';

import MatterportConfigurator from '../../../viewer.components/Matterport/Matterport.Configurator';

import './PublicMatterportViewer.less';

export default class PublicMatterportView extends React.Component {
  onViewerCreated = (sdk, loader) => {
    this.sdk = sdk;
    this.loader = loader;
    this.loader.show(false);
  };

  render() {
    this.scanId = this.props.match.params.scanId;

    return (
      <div className="public-matterport-view">
        <PageContainer
          onBack={() => window.history.back()}
          title="BIMforge Viewer"
          subTitle={this.props.scan ? `> ${this.props.scan.name}` : <Skeleton />}
        >
          <MatterportConfigurator onViewerCreated={this.onViewerCreated} scanId={this.scanId} showLoader={true} />
        </PageContainer>
      </div>
    );
  }
}
