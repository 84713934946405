import React from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { PageLoading } from '@ant-design/pro-layout';
import { Button, Input, Upload, Form, message, Modal, Avatar, Alert, Radio, Tag, Divider, Space } from 'antd';
import ImgCrop from 'antd-img-crop';

import './BasicSettings.less';

export default class BasicSettings extends React.Component {
  view = undefined;
  formRef = React.createRef();
  constructor() {
    super();

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
    };
  }
  componentWillUnmount() {
    this.props.unloadProfilePage();
  }

  /* ========================================================================== */
  /*                               Validate Brithday                            */
  /* ========================================================================== */

  validateBirthday = (rule, value, callback) => {
    const values = value.split('.');
    const cbText = 'Bitte achten Sie auf das richtige Format: DD.MM.YYYY';
    if (!values[0] || values[0].length < 2) {
      callback(cbText);
    }
    if (!values[1] || values[1].length < 2) {
      callback(cbText);
    }
    if (!values[2] || values[2].length < 4) {
      callback(cbText);
    }
    callback();
  };

  /* ========================================================================== */
  /*                               Avatar uploader                             */
  /* ========================================================================== */

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      this.setState({
        error: true,
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      this.setState({
        error: true,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  customRequest = async (option) => {
    const { file, onSuccess, onError } = option;
    await this.props.updateAvatar(file);
    if (!this.props.error) {
      onSuccess();
      message.success('Änderungen werden übernommen...');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      onError();
    }
  };

  handleChange = ({ fileList }) => {
    if (fileList === []) {
      console.log('remove');
    }
    this.setState({ fileList });
  };
  handlePreviewCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /* ========================================================================== */
  /*                                get View Dom                                */
  /* ========================================================================== */

  getViewDom = (ref) => {
    this.view = ref;
  };

  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    delete formValues['email'];
    this.props.updateProfile(formValues);
  };

  render() {
    const { profile, error, isProcessing } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    if (isProcessing || profile == null) {
      return <PageLoading />;
    }
    const current = profile;
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    return (
      <div className="baseView" ref={this.getViewDom}>
        <div className="left">
          {error && <Alert showIcon message="Fehler" description={this.props.error} type="error" />}
          <Form
            layout="vertical"
            onFinish={this.handleFinish}
            ref={this.formRef}
            initialValues={{
              ['firstName']: current ? current.firstName : '',
              ['lastName']: current ? current.lastName : '',
              ['email']: current ? current.email : '',
              ['birthday']: current ? current.birthday : '',
            }}
          >
            <Form.Item
              name="firstName"
              label="Vorname"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihren Vornamen ein!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Vorname" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Nachname"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihren Nachname ein!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Nachname" />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input disabled placeholder="Email" />

              {/* <Tooltip title="Kontaktieren Sie den Support für diese Funktion.">
                <Link className="change-email" style={{ float: 'right' }}>
                  Email ändern?
                </Link>
              </Tooltip> */}
            </Form.Item>
            <Form.Item
              name="birthday"
              label="Geburtsdatum"
              rules={[
                {
                  message: 'Bitte geben Sie Ihre Geburtsdatum an!',
                },
                {
                  validator: this.validateBirthday,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="DD.MM.YYYY" />
            </Form.Item>
            {/*
            <Form.Item
              name="organisation"
              label="Organisation"
              hasFeedback
              rules={[
                {
                  required: false,
                  message: 'Bitte geben Sie Ihre Organisation an!',
                },
              ]}
            >
              <Input placeholder="Organisation" />
            </Form.Item> */}
            <Button htmlType="submit" type="primary">
              Profil updaten
            </Button>
          </Form>
        </div>
        <div className="settings-right">
          <div className="avatar_title">Profilbild</div>
          <div className="avatar">
            <Avatar src={`data:image/png;base64,${current.avatar}`} size={144} />
          </div>
          <ImgCrop grid shape="round" cropperProps={{ cropShape: 'round' }}>
            <Upload
              fileList={fileList}
              listType="picture"
              customRequest={this.customRequest}
              beforeUpload={this.beforeUpload}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              onRemove={() => this.props.removeError()}
            >
              <div className="button_view">
                <Button>
                  <UploadOutlined />
                  Avatar ändern
                </Button>
              </div>
            </Upload>
          </ImgCrop>
          <Divider type="horizontal" />
          <Space direction="horizontal">
            <h3>Status:</h3>
            <Tag color="green">{current.status}</Tag>
          </Space>
          <Divider type="horizontal" />
          <Space direction="horizontal">
            <h3>Rolle: </h3> <Tag color="orange">{current.role}</Tag>
          </Space>
          <Divider type="horizontal" />
          <h3>Subscription</h3>
          <Radio.Group disabled value={current.subscription}>
            <Radio style={radioStyle} value={'test'}>
              Test
            </Radio>
            <Radio style={radioStyle} value={'basic'}>
              Basic
            </Radio>
            <Radio style={radioStyle} value={'professional'}>
              Professional
            </Radio>
            <Radio style={radioStyle} value={'premium'}>
              Premium
            </Radio>
          </Radio.Group>
        </div>
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handlePreviewCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
