import * as actionTypes from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
  scans: null,
  scan: null,
  processing: false,
  processed: false,
  error: null,
};

const scansReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SCANS:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_SCANS_SUCCESS:
      return {
        ...state,
        processing: false,
        scans: action.payload,
      };
    case actionTypes.GET_SCANS_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.GET_SCAN:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.GET_SCAN_SUCCESS:
      return {
        ...state,
        processing: false,
        scan: action.payload,
      };
    case actionTypes.GET_SCAN_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_SCAN:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.UPDATE_SCAN_SUCCESS:
      return {
        ...state,
        processing: false,
        scan: action.payload,
      };
    case actionTypes.UPDATE_SCAN_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.REGISTER_SCAN:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.REGISTER_SCAN_SUCCESS:
      return {
        ...state,
        processing: false,
        scans: [...state.scans, { ...action.payload }],
      };
    case actionTypes.REGISTER_SCAN_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.DELETE_SCAN:
      return {
        ...state,
        processing: true,
      };
    case actionTypes.DELETE_SCAN_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case actionTypes.DELETE_SCAN_FAIL:
      return {
        ...state,
        processing: false,
        processed: false,
        error: action.payload,
      };
    case actionTypes.SET_ACTIVE_SCAN:
      return {
        ...state,
        scan: action.payload,
      };
    case actionTypes.UNLOAD_SCAN_PAGE:
      return {
        ...state,
        error: null,
        processing: false,
        processed: false,
      };
    case actionTypes.UNLOAD_REALVIEWER_PAGE:
      return {
        ...state,
        scan: null,
      };
    default:
      return state;
  }
};

export default scansReducer;
