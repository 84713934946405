import React from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { verifyEmail, unloadAuthPage } from '../../store/actions/auth.actions';
import { getError, getRedirectToPath } from '../../store/selectors/auth.selectors';

import './VerfiyEmail.less';

class VerifyEmail extends React.Component {
  /* ========================================================================== */
  /*                                  Life Cyle                                 */
  /* ========================================================================== */

  componentDidMount() {
    this.verifyToken = this.props.match.params.token;
  }

  componentWillUnmount() {
    this.props.unloadAuthPage();
  }

  /* ========================================================================== */
  /*                               Validate Password                             */
  /* ========================================================================== */

  checkPassword = (rule, value, callback) => {
    if (value.length < 6) {
      callback('Das Passwort muss mindestens 6 Stellen lang sein!');
    }
    callback();
  };

  /* ========================================================================== */
  /*                                  On Finish                                 */
  /* ========================================================================== */

  onFinish(formValues) {
    this.props.verifyEmail(formValues, this.verifyToken);
  }

  render() {
    if (this.props.redirectToPath != null) {
      console.log(this.props.redirectToPath);
      this.props.history.push(this.props.redirectToPath, { message: 'Konto erfolgreich aktiviert.' });
    }
    return (
      <div className="verify-email">
        <Form onFinish={(formValues) => this.onFinish(formValues)} name="verifyEmail" className="verify-email-form">
          <h3 style={{ color: 'white' }}>Email verifizieren:</h3>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte Ihr Passwort eingeben!',
              },
              {
                validator: this.checkPassword,
              },
            ]}
          >
            <Input.Password size="large" prefix={<LockOutlined />} placeholder="Passwort" />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit" className="verify-email-form-button">
              Verifizieren
            </Button>
            <Link className="verify-email-form-login" to="/user/signin">
              Anmelden!
            </Link>
            <Link className="verify-email-form-forgot" to="/user/request-password-reset">
              Passwort vergessen?
            </Link>
          </Form.Item>
          {this.props.errorMessage && <Alert showIcon message="Fehler" description={this.props.errorMessage} type="error" />}
        </Form>
      </div>
    );
  }
}

const maptStateToProps = (state) => {
  return {
    errorMessage: getError(state),
    redirectToPath: getRedirectToPath(state),
  };
};
export default connect(maptStateToProps, { verifyEmail, unloadAuthPage })(VerifyEmail);
