import NoMatch from './NoMatch';
import { connect } from 'react-redux';

import { getIsSignedIn } from '../../store/selectors';

const mapStateToProps = (state) => {
  return {
    isSignedIn: getIsSignedIn(state),
    pathname: state.router.location.pathname,
  };
};
export default connect(mapStateToProps)(NoMatch);
