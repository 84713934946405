import React from 'react';
import { HomeOutlined, ContactsOutlined, UsergroupAddOutlined, CloudServerOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Divider, Row, Alert, Button, Progress, Statistic, Tooltip } from 'antd';
import { PageContainer, PageLoading, GridContent, RouteContext } from '@ant-design/pro-layout';
import ProCard from '@ant-design/pro-card';

import Team from './Tabs/Team';

import OrganisationModal from './Modal/OrganisationModal';

import './OrganisationView.less';

export default class OrganisationView extends React.Component {
  constructor() {
    super();
    this.state = {
      tabKey: 'team',
      visible: false,
    };
  }

  componentDidMount() {
    this.props.getOrganisation();
  }
  componentWillUnmount() {
    this.props.unloadOrganisationPage();
  }
  /* ========================================================================== */
  /*                                 Organisation   Modal                       */
  /* ========================================================================== */

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  handleSubmit = (formValues) => {
    this.props.updateOrganisation(this.props.organisation.id, formValues);
    this.setState({
      visible: false,
    });
  };

  renderUserInfo = (currentOrganisation) => {
    return (
      <div className="detail">
        <p>
          <ContactsOutlined
            style={{
              marginRight: 8,
            }}
          />
          {currentOrganisation.google ? (
            <a href={currentOrganisation.google} target="_blank">
              {currentOrganisation.address ? currentOrganisation.address : 'Nicht festgelegt'}
            </a>
          ) : currentOrganisation.address ? (
            currentOrganisation.address
          ) : (
            'Nicht festgelegt'
          )}
        </p>
        <p>
          <HomeOutlined
            style={{
              marginRight: 8,
            }}
          />
          {currentOrganisation.website ? (
            <a href={currentOrganisation.website} target="_blank">
              {currentOrganisation.website ? currentOrganisation.website : 'Nicht festgelegt'}
            </a>
          ) : currentOrganisation.website ? (
            currentOrganisation.website
          ) : (
            'Nicht festgelegt'
          )}
        </p>
      </div>
    );
  };

  onTabChange = (key) => {
    // If you need to sync state to url
    // const { match } = this.props;
    // router.push(`${match.url}/${key}`);
    this.setState({
      tabKey: key,
    });
  };
  renderChildrenByTabKey = (tabKey) => {
    // if (tabKey === 'projects') {
    //   return <Projects />;
    // }
    if (tabKey === 'team') {
      return <Team {...this.props} />;
    }

    return null;
  };
  operationTabList = (activeUsers) => {
    return [
      {
        key: 'team',
        tab: (
          <span>
            Team<span style={{ fontSize: 14 }}>({Array.isArray(activeUsers) ? activeUsers.length : 1})</span>
          </span>
        ),
      },
      {
        key: 'notice',
        tab: (
          <span>
            Neuigkeiten <span style={{ fontSize: 14 }}></span>
          </span>
        ),
      },
    ];
  };

  render() {
    const { organisation, error, isProcessing, isProcessed, activeUsers, activeModels, activeProjects, activeScans } = this.props;
    const { tabKey, visible } = this.state;
    if (isProcessing) {
      return <PageLoading />;
    }
    return (
      <div className="orga-view">
        <PageContainer>
          {error &&
            (error !== 'Nutzer ist nicht registriert.' ? (
              <Alert showIcon message="Fehler" description={error} type="error" />
            ) : (
              <Alert
                showIcon
                message="Fehler"
                description="Nutzer ist nicht registriert. Nur registrierte Nutzer können zu einer Organisation eingeladen werden. Registrierungseinladung versenden?"
                type="error"
                action={
                  <Button size="small" danger onClick={() => this.props.inviteUserToBimforge()}>
                    Einladen
                  </Button>
                }
              />
            ))}
          {organisation && (
            <RouteContext.Consumer>
              {({ isMobile }) => {
                return (
                  <GridContent>
                    <Row gutter={24} style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 18 }}>
                      <ProCard.Group
                        title="Organisation Info"
                        direction={isMobile ? 'column' : 'row'}
                        extra={[
                          <Tooltip title="Kontaktieren Sie den Support.">
                            <Button disabled>Upgrade</Button>
                          </Tooltip>,
                        ]}
                        bordered={false}
                      >
                        <ProCard loading={activeUsers == null ? true : false}>
                          <Statistic
                            title="Aktive Nutzer"
                            value={parseInt(activeUsers ? activeUsers.length : 0)}
                            suffix={'/ ' + organisation.allowedUsers}
                          />
                        </ProCard>
                        <Divider type={isMobile ? 'horizontal' : 'vertical'} style={{ height: '90%' }} />
                        <ProCard loading={activeProjects == null ? true : false}>
                          <Statistic
                            title="Aktive Projekte"
                            value={activeProjects ? activeProjects : 0}
                            suffix={'/ ' + organisation.allowedProjects}
                          />
                        </ProCard>
                        <Divider type={isMobile ? 'horizontal' : 'vertical'} style={{ height: '90%' }} />
                        <ProCard loading={activeModels == null ? true : false}>
                          <Statistic title="Aktive Modelle / Dokumente" value={activeModels ? activeModels : 0} />
                        </ProCard>
                        <Divider type={isMobile ? 'horizontal' : 'vertical'} style={{ height: '90%' }} />
                        <ProCard loading={activeScans == null ? true : false}>
                          <Statistic title="Aktive Scans" value={activeScans ? activeScans : 0} />
                        </ProCard>
                      </ProCard.Group>
                    </Row>
                    <Row gutter={24}>
                      <Col lg={7} md={24}>
                        <Card
                          bordered={false}
                          style={{ marginBottom: 24 }}
                          loading={isProcessing}
                          extra={[
                            <Button disabled={this.props.currentUser.role === 'user' ? true : false} onClick={() => this.showModal()}>
                              Bearbeiten
                            </Button>,
                          ]}
                        >
                          {!isProcessing && (
                            <div>
                              <div className="avatarHolder">
                                <img alt="" src={`data:image/png;base64,${organisation.logo}`} />
                                <div className="name">{organisation.name}</div>
                                <div>{organisation.slogan}</div>
                              </div>
                              {this.renderUserInfo(organisation)}
                              <Divider dashed />

                              {/* <TagList tags={currentUser.tags || []} /> */}
                              <Divider style={{ marginTop: 16 }} dashed />
                              <div className="team">
                                <div className="teamTitle">Team</div>
                                <Row gutter={48}>
                                  {activeUsers &&
                                    activeUsers.map((item) => (
                                      <Col key={item.id} lg={24} xl={12}>
                                        <Avatar size="small" src={`data:image/png;base64,${item.avatar}`} /> {item.firstName} {item.lastName}
                                      </Col>
                                    ))}
                                </Row>
                              </div>
                            </div>
                          )}
                        </Card>
                      </Col>
                      <Col lg={17} md={24}>
                        {/* <Card className="subCard" bordered={false} style={{ marginBottom: 24 }} loading={activeUsers ? false : true}>
                          <Card bordered={false} title="Organisation Info" extra={[<Button disabled>Upgrade</Button>]}>
                            <div className="subContent">
                              <Row>
                                <Col span={18} push={6}>
                                  <div className="percent">
                                    <Progress
                                      percent={parseInt(activeUsers ? (activeUsers.length / organisation.allowedUsers) * 100 : 0)}
                                      strokeColor="green"
                                      format={() => {
                                        return `${activeUsers.length} von ${organisation.allowedUsers}`;
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col span={6} pull={18}>
                                  <div className="text">
                                    <UsergroupAddOutlined size="large" />
                                    <p style={{ margin: '0 10px 0 10px' }}>Aktive Nutzer:</p>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={18} push={6}>
                                  <div className="percent">
                                    <Progress
                                      percent={parseInt(projects ? (projects.length / organisation.allowedProjects) * 100 : 0)}
                                      strokeColor="green"
                                      format={() => {
                                        return `${projects ? projects.length : 0} von ${organisation.allowedProjects}`;
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col span={6} pull={18}>
                                  <div className="text">
                                    <CloudServerOutlined size="large" />
                                    <p style={{ margin: '0 10px 0 10px' }}>Aktive Projekte:</p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Card> */}
                        <Card
                          className="tabsCard"
                          bordered={false}
                          tabList={this.operationTabList(activeUsers)}
                          activeTabKey={tabKey}
                          onTabChange={this.onTabChange}
                        >
                          {this.renderChildrenByTabKey(tabKey)}
                        </Card>
                      </Col>
                    </Row>
                    <OrganisationModal
                      visible={visible}
                      onCancel={this.handleCancel}
                      onSubmit={this.handleSubmit}
                      organisation={organisation}
                      {...this.props}
                    />
                  </GridContent>
                );
              }}
            </RouteContext.Consumer>
          )}
        </PageContainer>
      </div>
    );
  }
}
