import React from 'react';
import { BarsOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Button, Menu } from 'antd';

export default class DropOptions extends React.Component {
  render() {
    const { onMenuClick, menuOptions, buttonStyle, dropdownProps } = this.props;
    const menu = menuOptions.map((item) => (
      <Menu.Item key={item.key} icon={item.icon} danger={item.danger} disabled={item.disabled}>
        {item.name}
      </Menu.Item>
    ));
    return (
      <Dropdown overlay={<Menu onClick={onMenuClick}>{menu}</Menu>} {...dropdownProps}>
        <Button style={{ border: 'none', ...buttonStyle }}>
          <BarsOutlined style={{ marginRight: 2 }} />
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  }
}
