import CoreLayout from './CoreLayout';
import { connect } from 'react-redux';

import { getCurrentUser, getIsSignedIn, getProcessing, getProcessed } from '../../store/selectors';
import { getCurrentProject } from '../../store/selectors/projects.selectors';
import { getProject } from '../../store/actions/projects.actions';
import { setAttemptedPath } from '../../store/actions';

const mapDispatchToProps = {
  setAttemptedPath,
  getProject,
};
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  isSignedIn: getIsSignedIn(state),
  isProcessing: getProcessing(state),
  isProcessed: getProcessed(state),
  pathname: state.router.location.pathname,
  project: getCurrentProject(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout);
