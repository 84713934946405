import React from 'react';
import PropTypes from 'prop-types';
import './Viewer.less';

export default class Viewer extends React.Component {
  static propTypes = {
    panels: PropTypes.array,
  };

  static defaultProps = {
    panels: [],
    style: {},
  };

  constructor() {
    super();

    this.height = 0;
    this.width = 0;
  }

  /////////////////////////////////////////////////////////
  // Component has been mounted so this container div is now created
  // in the DOM and viewer can be instantiated
  //
  /////////////////////////////////////////////////////////
  async componentDidMount() {
    try {
      if (this.props.dbModel) {
        if (!this.props.viewerEnv) {
          var viewerEnv;
          viewerEnv = await this.initialize({
            env: 'AutodeskProduction2' || this.props.dbModel.env,
            //api: undefined,
            language: 'de',
            logLevel: 1,
            //useConsolidation: true,
            //consolidationMemoryLimit: 100 * 1024 * 1024, // 150MB - Optional, defaults to 100MB
            // memory: {
            //   limit: 2048,
            //   debug: {
            //     force: true,
            //   },
            // },
          });
          this.props.setViewerEnv(viewerEnv);
        }
      }
      var config3d = {
        extensions: [
          'Autodesk.MemoryLimited',
          'Autodesk.Viewing.MemoryLimitedDebug',
          'Autodesk.VisualClusters',
          'Autodesk.ProfileUi',
          'Autodesk.NPR',
          'Autodesk.DocumentBrowser',
          //'Autodesk.ExtensionsPanel',
          //'Autodesk.DOF',
          'Autodesk.BIM360.Minimap',
          //process.env.NODE_ENV === 'development' ? 'Autodesk.Debug' : null,
          //'Autodesk.Viewing.MarkupsGui',
          //'Autodesk.BIM360.Markups',
        ],
        ifcLevelsEnabled: true,
        useConsolidation: true,
        consolidationMemoryLimit: 150 * 1024 * 1024, // 150MB - Optional, defaults to 100MB
        memory: {
          limit: 2048,
          // debug: {
          //   force: process.env.NODE_ENV === 'development' ? true : false,
          // },
        },
        // loaderExtensions: ['Autodesk.PDF', 'Autodesk.Scalaris', 'Autodesk.DWF', 'Autodesk.DWF', 'Autodesk.ReCap', 'Autodesk.glTF', 'Autodesk.glTF'],
        // theme: 'light-theme',
        // sharedPropertyDbPath: '',
        // canvasConfig: {
        //     "disableSpinner": false,
        //     "disableMouseWheel": false,
        //     "disableTwoFingerSwipe": false
        // },
        // startOnInitialize: false, //default is true,
        // experimental: [''],
        // navToolsConfig:
        // profileSettings: [],
        // disableBimWalkInfoIcon: true
        defaultUnit: 'm',
      };
      this.viewer = new window.Autodesk.Viewing.GuiViewer3D(this.viewerContainer, config3d);
      this.panelsContainer = document.createElement('div');
      if (this.viewer.container === undefined) {
        return;
      }

      this.viewer.container.appendChild(this.panelsContainer);

      if (this.props.onViewerCreated) {
        this.props.onViewerCreated(this.viewer);
      }
    } catch (err) {
      console.log(err);
      if (this.props.onError) {
        this.props.onError(err);
      }
    }
  }

  initialize(options) {
    return new Promise((resolve, reject) => {
      window.Autodesk.Viewing.Initializer(
        options,
        () => {
          resolve();
        },
        (error) => {
          reject(error);
        },
      );
    });
  }

  componentDidUpdate() {
    if (this.viewer && this.viewer.impl) {
      if (this.viewerContainer.offsetHeight !== this.height || this.viewerContainer.offsetWidth !== this.width) {
        this.height = this.viewerContainer.offsetHeight;
        this.width = this.viewerContainer.offsetWidth;

        this.viewer.resize();
      }
    }

    this.props.panels.map((panel) => {
      return panel.emit('update');
    });
  }

  /////////////////////////////////////////////////////////
  // Component will unmount so we can destroy the viewer to avoid
  // memory leaks
  //
  /////////////////////////////////////////////////////////
  componentWillUnmount() {
    if (this.viewer) {
      if (this.viewer.impl.selector) {
        console.log('Viewer unmounting...');
        this.viewer.tearDown();
        this.viewer.finish();
        this.viewer = null;
        console.log('Viewer destroyed.');
      }
    }
  }

  /////////////////////////////////////////////////////////
  // Render component, resize the viewer if exists
  //
  /////////////////////////////////////////////////////////
  render() {
    const panels = this.props.panels.map((panel) => {
      return panel.render();
    });
    return (
      <div className="viewer-app-container">
        <div ref={(div) => (this.viewerContainer = div)} className="viewer-container" style={this.props.style} />

        <div className="viewer-panels-container">{panels}</div>
      </div>
    );
  }
}
