import React from 'react';
import _ from 'lodash';

import { Modal, Form, Input, Upload, message, Checkbox, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { bimServerApi, errorHandler } from '../../../store/apis/bim.server.api';

const { Option } = Select;

export default class ProjectsModal extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      error: false,
      data: [],
      value: undefined,
    };
  }

  handleSearch = (value) => {
    if (value) {
      this.fetchOrganisations(value, (data) => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };
  handleSearchChange = (value) => {
    console.log(value);
    this.setState({ value });
  };
  fetchOrganisations = (value, callback) => {
    bimServerApi.get(`/api/organisations/all?name=${value}`).then(
      (response) => {
        callback(response.data.organisations);
      },
      (err) => {
        errorHandler(err);
      },
    );
  };

  /* ========================================================================== */
  /*                                 upload cover                                 */
  /* ========================================================================== */

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      this.setState({
        error: true,
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      this.setState({
        error: true,
      });
    }
    return isJpgOrPng && isLt2M;
  };

  customRequest = async (option) => {
    const { file, onSuccess, onError } = option;
    const { current } = this.props;
    let formData = new FormData();
    formData.append('cover', file);
    return bimServerApi.post(`/api/projects/${current.id}`, formData, {}).then(
      (response) => {
        onSuccess();
      },
      (err) => {
        onError();
        errorHandler(err);
      },
    );
  };

  handleChange = ({ fileList }) => {
    if (fileList == []) {
      console.log('remove');
    }
    this.setState({ fileList });
  };
  handlePreviewCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /* ========================================================================== */
  /*                                 Form Layout                                */
  /* ========================================================================== */

  get formLayout() {
    return {
      labelCol: { span: 7 },
      wrapperCol: { span: 13 },
    };
  }
  /* ========================================================================== */
  /*                                 Form Handle                                */
  /* ========================================================================== */

  handleFinish = (formValues) => {
    if (this.props.onSubmit) {
      console.log(formValues);
      delete formValues['objectId'];
      delete formValues['upload'];
      Object.keys(formValues).map((key) => {
        if (formValues[key] === undefined || formValues[key] === null) {
          delete formValues[key];
        }
      });
      // let organisation;
      // if (formValues['organisation'][0]) {
      //   organisation = formValues['organisation'][0].map((v) => {
      //     return v._id;
      //   });
      // } else {
      //   organisation = formValues['organisation'].map((v) => {
      //     return v._id;
      //   });
      // }
      // // console.log(organisation);
      // delete formValues['organisation'];
      // formValues = {
      //   ...formValues,
      //   organisation,
      // };
      console.log(formValues);
      this.props.onSubmit(formValues);
    }
  };

  handleSubmit = () => {
    if (!this.formRef) return;
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.setState({
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      error: false,
    });
  };

  normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  /* ========================================================================== */
  /*                                Modal Content                               */
  /* ========================================================================== */
  getModalContent() {
    const { current, deletion, archive } = this.props;
    const { previewVisible, previewImage, fileList, previewTitle, data } = this.state;
    let options;
    if (!_.isEmpty(data)) {
      options = data.map((d) => <Option key={d.id}>{d.name}</Option>);
    } else {
      options = [];
    }
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    var disabled;
    if (deletion || archive) {
      disabled = true;
    }
    var hasCover = false;
    var initialValues = {};
    var selectInitOptions = [];
    if (current) {
      if (current.cover) {
        hasCover = true;
      }
      initialValues = {
        ['name']: current.name,
        ['desc']: current.desc,
        ['company']: current.company,
        ['address']: current.address,
        ['google']: current.google,
        ['website']: current.website,
        ['archived']: current.archived,
        ['default']: current.default,
        ['organisation']: [current.organisation],
        ['objectId']: current.id,
      };

      initialValues['organisation'][0].forEach((orga) => {
        selectInitOptions.push(orga.name);
      });
    }
    return (
      <Form {...this.formLayout} ref={this.formRef} style={{ padding: 10 }} onFinish={this.handleFinish} initialValues={initialValues}>
        {current && (
          <Form.Item name="upload" label="Cover">
            <Upload
              name="cover"
              className="cover-uploader"
              listType="picture-card"
              fileList={fileList}
              customRequest={this.customRequest}
              beforeUpload={this.beforeUpload}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
              style={{ with: 128, height: 128 }}
              disabled={disabled}
            >
              {fileList.length == 1 ? null : hasCover ? (
                <img alt="example" style={{ width: '100%' }} src={`data:image/jpeg;base64,${current.cover}`} />
              ) : (
                uploadButton
              )}
            </Upload>
            {/* </ImgCrop> */}
          </Form.Item>
        )}
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handlePreviewCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Form.Item
          name="name"
          label="Projektname"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie einen Projektname an.',
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Projektname" disabled={disabled} />
        </Form.Item>
        <Form.Item name="desc" label="Bescheibung">
          <Input.TextArea rows={2} placeholder="Projektbeschreibung" maxLength={300} disabled={disabled} />
        </Form.Item>
        <Form.Item name="company" label="Firma">
          <Input placeholder="Firmenname" disabled={disabled} />
        </Form.Item>
        <Form.Item name="address" label="Adresse">
          <Input placeholder="Straße Nr. ,PLZ Stadt" disabled={disabled} />
        </Form.Item>
        <Form.Item name="google" label="Google Maps Link">
          <Input placeholder="https://goo.gl/maps/suffix" disabled={disabled} />
        </Form.Item>
        <Form.Item name="website" label="Webseite">
          <Input placeholder="https://firmenseite.de" disabled={disabled} />
        </Form.Item>
        <Form.Item name="archived" label="Archived" valuePropName="checked">
          <Checkbox defaultChecked={initialValues['archived']}>Archiviert</Checkbox>
        </Form.Item>
        <Form.Item name="default" label="Default" valuePropName="checked">
          <Checkbox defaultChecked={initialValues['default']}>Default</Checkbox>
        </Form.Item>
        <Form.Item
          name="organisation"
          label="Organisation ID"
          hasFeedback
          valuePropName="name"
          rules={[
            {
              required: false,
              message: 'Bitte geben Sie Ihre Organisation an!',
            },
          ]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Organisation"
            value={this.state.value}
            defaultValue={selectInitOptions}
            //style={this.props.style}
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            notFoundContent="keine passender Eintrag gefunden."
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item name="objectId" label="Object ID">
          <Input placeholder="Object Id " disabled={true} />
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { visible, deletion, current, onDelete, onArchive, archive, error } = this.props;
    var modalFooter;
    modalFooter = current
      ? deletion
        ? {
            okText: 'Löschen',
            okButtonProps: { danger: true },
            onOk: onDelete,
            onCancel: this.handleCancel,
          }
        : archive
        ? {
            okText: 'Archivieren',
            onOk: onArchive,
            onCancel: this.handleCancel,
            okButtonProps: { style: { backgroundColor: '#757575' } },
          }
        : {
            okText: 'Update',
            onOk: this.handleSubmit,
            onCancel: this.handleCancel,
          }
      : {
          okText: 'Erstellen',
          onOk: this.handleSubmit,
          onCancel: this.handleCancel,
        };
    return (
      <>
        <Modal
          title={`Projekt ${current ? (deletion ? 'löschen' : archive ? 'archivieren' : 'bearbeiten') : 'erstellen'}`}
          className="standardListForm"
          width={640}
          bodyStyle={{ padding: '28px 0 0' }}
          destroyOnClose
          visible={visible}
          {...modalFooter}
        >
          {this.getModalContent()}
        </Modal>
      </>
    );
  }
}
