/* ========================================================================== */
/*                                  Auth                                      */
/* ========================================================================== */

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const TRY_LOCAL_SIGN_IN = 'TRY_LOCAL_SIGN_IN';
export const TRY_LOCAL_SIGN_IN_SUCCESS = 'TRY_LOCAL_SIGN_IN_SUCCESS ';
export const TRY_LOCAL_SIGN_IN_FAIL = 'TRY_LOCAL_SIGN_IN_FAIL';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

export const REQUEST_VERIFICATION_EMAIL = 'REQUEST_VERIFICATION_EMAIL';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS = 'REQUEST_VERIFICATION_EMAIL_SUCCESS';
export const REQUEST_VERIFICATION_EMAIL_FAIL = 'REQUEST_VERIFICATION_EMAIL_FAIL';

export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAIL = '[REQUEST_PASSWORD_RESET_FAIL';

export const RESET_PASSWORD = 'RESET_PASSWORD ';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const SET_ATTEMPTED_PATH = 'SET_ATTEMPTED_PATH';

export const UNLOAD_AUTH_PAGE = 'UNLOAD_AUTH_PAGE';

/* ========================================================================== */
/*                                  Notices                                   */
/* ========================================================================== */

export const FETCH_NOTICES = 'FETCH_NOTICES';
export const FETCH_NOTICES_SUCCESS = 'FETCH_NOTICES_SUCCESS';
export const FETCH_NOTICES_FAIL = 'FETCH_NOTICES_FAIL';

export const UPDATE_NOTICE = 'UPDATE_NOTICE';
export const UPDATE_NOTICE_SUCCESS = 'UPDATE_NOTICE_SUCCESS';
export const UPDATE_NOTICE_FAIL = 'UPDATE_NOTICES_FAIL';

export const DELETE_NOTICES = 'DELETE_NOTICES';
export const DELETE_NOTICES_SUCCESS = 'DELETE_NOTICES_SUCCESS';
export const DELETE_NOTICES_FAIL = 'DELETE_NOTICES_FAIL';

/* ========================================================================== */
/*                                  Profile                                   */
/* ========================================================================== */
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS';
export const UPDATE_AVATAR_FAIL = 'UPDATE_AVATAR_FAIL';

export const REMOVE_ERROR = 'REMOVE_ERROR';

export const UNLOAD_PROFILE_PAGE = 'UNLOAD_PROFILE_PAGE';

/* ========================================================================== */
/*                                  Projects                                  */
/* ========================================================================== */

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const REGISTER_PROJECT = 'REGISTER_PROJECT';
export const REGISTER_PROJECT_SUCCESS = 'REGISTER_PROJECT_SUCCESS';
export const REGISTER_PROJECT_FAIL = 'REGISTER_PROJECT_FAIL';

export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';

export const UPDATE_COVER = 'UPDATE_COVER ';
export const UPDATE_COVER_SUCCESS = 'UPDATE_COVER_SUCCESS';
export const UPDATE_COVER_FAIL = 'UPDATE_COVER_FAIL';

export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const UNLOAD_PROJECT_PAGE = 'UNLOAD_PROJECT_PAGE';

/* ========================================================================== */
/*                                   Models                                   */
/* ========================================================================== */

export const GET_MODELS = 'GET_MODELS';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const GET_MODELS_FAIL = 'GET_MODELS_FAIL';

export const GET_MODEL = 'GET_MODEL';
export const GET_MODEL_SUCCESS = 'GET_MODEL_SUCCESS';
export const GET_MODEL_FAIL = 'GET_MODEL_FAIL';

export const UPDATE_MODEL = 'UPDATE_MODEL';
export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS';
export const UPDATE_MODEL_FAIL = 'UPDATE_MODEL_FAIL';

export const REGISTER_MODEL = 'REGISTER_MODEL ';
export const REGISTER_MODEL_SUCCESS = 'REGISTER_MODEL_SUCCESS';
export const REGISTER_MODEL_FAIL = 'REGISTER_MODEL_FAIL';

export const DELETE_MODEL = 'DELETE_MODEL';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_FAIL = 'DELETE_MODEL_FAIL';

export const SET_ACTIVE_MODEL = 'SET_ACTIVE_MODEL';
export const UNLOAD_MODEL_PAGE = 'UNLOAD_MODEL_PAGE';
export const UNLOAD_VIEWER_PAGE = 'UNLOAD_VIEWER_PAGE';

/* ========================================================================== */
/*                                    Scans                                   */
/* ========================================================================== */
export const GET_SCANS = 'GET_SCANS';
export const GET_SCANS_SUCCESS = 'GET_SCANS_SUCCESS';
export const GET_SCANS_FAIL = 'GET_SCANS_FAIL';

export const GET_SCAN = 'GET_SCAN';
export const GET_SCAN_SUCCESS = 'GET_SCAN_SUCCESS';
export const GET_SCAN_FAIL = 'GET_SCAN_FAIL';

export const UPDATE_SCAN = 'UPDATE_SCAN';
export const UPDATE_SCAN_SUCCESS = 'UPDATE_SCAN_SUCCESS';
export const UPDATE_SCAN_FAIL = 'UPDATE_SCAN_FAIL';

export const REGISTER_SCAN = 'REGISTER_SCAN ';
export const REGISTER_SCAN_SUCCESS = 'REGISTER_SCAN_SUCCESS';
export const REGISTER_SCAN_FAIL = 'REGISTER_SCAN_FAIL';

export const DELETE_SCAN = 'DELETE_SCAN';
export const DELETE_SCAN_SUCCESS = 'DELETE_SCAN_SUCCESS';
export const DELETE_SCAN_FAIL = 'DELETE_SCAN_FAIL';

export const SET_ACTIVE_SCAN = 'SET_ACTIVE_SCAN';
export const UNLOAD_SCAN_PAGE = 'UNLOAD_SCAN_PAGE';
export const UNLOAD_REALVIEWER_PAGE = 'UNLOAD_REALVIEWER_PAGE';

/* ========================================================================== */
/*                                    Organisation                            */
/* ========================================================================== */
export const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
export const GET_ORGANISATIONS_SUCCESS = 'GET_ORGANISATIONS_SUCCESS';
export const GET_ORGANISATIONS_FAIL = 'GET_ORGANISATIONS_FAIL';

export const GET_ORGANISATION = 'GET_ORGANISATION';
export const GET_ORGANISATION_SUCCESS = 'GET_ORGANISATION_SUCCESS';
export const GET_ORGANISATION_FAIL = 'GET_ORGANISATION_FAIL';

export const GET_ORGANISATION_USERS = 'GET_ORGANISATION_USERS';
export const GET_ORGANISATION_USERS_SUCCESS = 'GET_ORGANISATION_USERS_SUCCESS';
export const GET_ORGANISATION_USERS_FAIL = 'GET_ORGANISATION_USERS_FAIL';

export const GET_ORGANISATION_PROJECTS = 'GET_ORGANISATION_PROJECTS';
export const GET_ORGANISATION_PROJECTS_SUCCESS = 'GET_ORGANISATION_PROJECTS_SUCCESS';
export const GET_ORGANISATION_PROJECTS_FAIL = 'GET_ORGANISATION_PROJECTS_FAIL';

export const INVITE_TO_ORGANISATION = 'INVITE_TO_ORGANISATION';
export const INVITE_TO_ORGANISATION_SUCCESS = 'INVITE_TO_ORGANISATION_SUCCESS';
export const INVITE_TO_ORGANISATION_FAIL = 'INVITE_TO_ORGANISATION_FAIL';

export const REMOVE_FROM_ORGANISATION = 'REMOVE_FROM_ORGANISATION';
export const REMOVE_FROM_ORGANISATION_SUCCESS = 'REMOVE_FROM_ORGANISATION_SUCCESS';
export const REMOVE_FROM_ORGANISATION_FAIL = 'REMOVE_FROM_ORGANISATION_FAIL';

export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const UPDATE_ORGANISATION_SUCCESS = 'UPDATE_ORGANISATION_SUCCESS';
export const UPDATE_ORGANISATION_FAIL = 'UPDATE_ORGANISATION_FAIL';

export const REGISTER_ORGANISATION = 'REGISTER_ORGANISATION ';
export const REGISTER_ORGANISATION_SUCCESS = 'REGISTER_ORGANISATION_SUCCESS';
export const REGISTER_ORGANISATION_FAIL = 'REGISTER_ORGANISATION_FAIL';

export const DELETE_ORGANISATION = 'DELETE_ORGANISATION';
export const DELETE_ORGANISATION_SUCCESS = 'DELETE_ORGANISATION_SUCCESS';
export const DELETE_ORGANISATION_FAIL = 'DELETE_ORGANISATION_FAIL';

export const UPDATE_LOGO = 'UPDATE_LOGO';
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS';
export const UPDATE_LOGO_FAIL = 'UPDATE_LOGO_FAIL';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';

export const GET_ORGANISATION_STATS = 'GET_ORGANISATION_STATS';
export const GET_ORGANISATION_STATS_SUCCESS = 'GET_ORGANISATION_STATS_SUCCESS';
export const GET_ORGANISATION_STATS_FAIL = 'GET_ORGANISATION_STATS_FAIL';

export const UNLOAD_ORGANISATION_PAGE = 'UNLOAD_ORGANISATION_PAGE';

/* ========================================================================== */
/*                                   Viewer                                   */
/* ========================================================================== */
export const SET_VIEWER_ENV = 'SET_VIEWER_ENV';
export const SET_VIEWER_PROFILE = 'SET_VIEWER_PROFILE';

/* ========================================================================== */
/*                                   Upload                                   */
/* ========================================================================== */

export const UNLOAD_UPLOAD_PAGE = 'UNLOAD_UPLOAD_PAGE';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const UPLOAD_PROGRESS_CHANGED = 'UPLOAD_PROGRESS_CHANGED';
export const SET_UPLOAD_DATA = 'SET_UPLOAD_DATA';
export const TRANSLATION_PROGRESS_CHANGED = 'TRANSLATION_PROGRESS_CHANGED';

export const GET_SUPPORTED_FORMATS = 'GET_SUPPORTED_FORMATS';
export const GET_SUPPORTED_FORMATS_SUCCESS = 'GET_SUPPORTED_FORMATS_SUCCESS';
export const GET_SUPPORTED_FORMATS_FAIL = 'GET_SUPPORTED_FORMATS_FAIL';

/* ========================================================================== */
/*                                    Showroom                                */
/* ========================================================================== */

export const GET_SHOWROOMS = 'GET_SHOWROOMS';
export const GET_SHOWROOMS_SUCCESS = 'GET_SHOWROOMS_SUCCESS';
export const GET_SHOWROOMS_FAIL = 'GET_SHOWROOMS_FAIL';

export const GET_SHOWROOM = 'GET_SHOWROOM';
export const GET_SHOWROOM_SUCCESS = 'GET_SHOWROOM_SUCCESS';
export const GET_SHOWROOM_FAIL = 'GET_SHOWROOM_FAIL';

export const UPDATE_SHOWROOM = 'UPDATE_SHOWROOM';
export const UPDATE_SHOWROOM_SUCCESS = 'UPDATE_SHOWROOM_SUCCESS';
export const UPDATE_SHOWROOM_FAIL = 'UPDATE_SHOWROOM_FAIL';

export const REGISTER_SHOWROOM = 'REGISTER_SHOWROOM ';
export const REGISTER_SHOWROOM_SUCCESS = 'REGISTER_SHOWROOM_SUCCESS';
export const REGISTER_SHOWROOM_FAIL = 'REGISTER_SHOWROOM_FAIL';

export const DELETE_SHOWROOM = 'DELETE_SHOWROOM';
export const DELETE_SHOWROOM_SUCCESS = 'DELETE_SHOWROOM_SUCCESS';
export const DELETE_SHOWROOM_FAIL = 'DELETE_SHOWROOM_FAIL';

export const SET_ACTIVE_SHOWROOM_TAB = 'SET_ACTIVE_SHOWROOM_TAB';
export const UNLOAD_SHOWROOM_PAGE = 'UNLOAD_SHOWROOM_PAGE';

export const GET_DATA = 'GET_DATA';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const GET_DATA_FAIL = 'GET_DATA_FAIL';
