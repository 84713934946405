import ModelUploader from './components/ModelUploaderView';
import { connect } from 'react-redux';

import { getProject, setActiveProject } from '../../store/actions/projects.actions';
import {
  setCurrentStep,
  getSupportedFormats,
  unloadUploadPage,
  uploadFile,
  setUploadData,
  setUploadProgress,
  setTranslationProgress,
} from '../../store/actions/upload.actions';

import { getCurrentProject, getProcessing, getError } from '../../store/selectors/projects.selectors';
import { getCurrentUser } from '../../store/selectors/auth.selectors';
import { getFormats, getCurrentStep, getUploadData, getUploadProgress, getTranslationProgress } from '../../store/selectors/upload.selectors';

const mapDispatchToProps = {
  getProject,
  setActiveProject,
  getSupportedFormats,
  setCurrentStep,
  unloadUploadPage,
  uploadFile,
  setUploadData,
  setUploadProgress,
  setTranslationProgress,
};

const mapStateToProps = (state) => {
  return {
    project: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    error: getError(state),
    isProcessing: getProcessing(state),
    supportedFormats: getFormats(state),
    currentStep: getCurrentStep(state),
    uploadData: getUploadData(state),
    uploadProgress: getUploadProgress(state),
    translationProgress: getTranslationProgress(state),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModelUploader);
