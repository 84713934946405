import { createSelector } from 'reselect';

export const getShowroomState = (state) => state.showrooms;

export const getProcessing = createSelector(getShowroomState, (showrooms) => showrooms.processing);
export const getProcessed = createSelector(getShowroomState, (showrooms) => showrooms.processed);
export const getError = createSelector(getShowroomState, (showrooms) => showrooms.error);
export const getCurrentShowrooms = createSelector(getShowroomState, (showrooms) => showrooms.showrooms);
export const getCurrentShowroom = createSelector(getShowroomState, (showrooms) => showrooms.showroom);
export const getActiveTab = createSelector(getShowroomState, (showrooms) => showrooms.activeTab);
