import * as actionTypes from './types';

export const getProfile = () => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.GET_PROFILE });
  return bimServerApi.get('/api/profiles/').then(
    (response) => {
      dispatch({ type: actionTypes.GET_PROFILE_SUCCESS, payload: response.data.profile });
    },
    (err) => {
      dispatch({
        type: actionTypes.GET_PROFILE_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const updateProfile = (formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_PROFILE });
  return bimServerApi.put('/api/profiles/', formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_PROFILE_SUCCESS, payload: response.data.profile });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_PROFILE_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};
export const updatePassword = (formValues) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_PASSWORD });
  return bimServerApi.put('/api/profiles/password', formValues).then(
    (response) => {
      dispatch({ type: actionTypes.UPDATE_PASSWORD_SUCCESS });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_PASSWORD_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const updateAvatar = (file) => (dispatch, getState, { bimServerApi }) => {
  dispatch({ type: actionTypes.UPDATE_AVATAR });
  let formData = new FormData();
  formData.append('avatar', file);
  return bimServerApi.post('/api/profiles/avatar', formData, {}).then(
    (response) => {
      setAuthInfo(response.data.profile);
      dispatch({ type: actionTypes.UPDATE_AVATAR_SUCCESS, payload: response.data.profile });
    },
    (err) => {
      dispatch({
        type: actionTypes.UPDATE_AVATAR_FAIL,
        payload: err.response.data.error.message,
      });
    },
  );
};

export const removeError = () => {
  return {
    type: actionTypes.REMOVE_ERROR,
  };
};

export const unloadProfilePage = () => {
  return {
    type: actionTypes.UNLOAD_PROFILE_PAGE,
  };
};

const setAuthInfo = (user) => {
  const authInfo = JSON.parse(localStorage.getItem('authInfo'));
  authInfo.user = user;
  localStorage.setItem('authInfo', JSON.stringify(authInfo));
};
