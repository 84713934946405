import React from 'react';

import { LockOutlined } from '@ant-design/icons';
import { PageContainer, RouteContext, GridContent } from '@ant-design/pro-layout';
import { Button, Row, Col, Descriptions, Card, Alert, Divider, Statistic, Avatar, Tag, Result, Empty } from 'antd';

import DashforgeDemo from './DashforgeDemo';

export default class Dashforge extends React.Component {
  render() {
    console.log(this.props);
    if (this.props.project.metr) {
      return <DashforgeDemo />;
    }

    return (
      <Result
        icon={<LockOutlined />}
        title="Produkt nicht verfügbar"
        subTitle="Dieses Produkt befindet sich in der Beta und ist aktuell nur für bestimmte Personen verfügbar."
      />
    );
  }
}
